.tableWatchList .ant-table-tbody>tr>td {
    padding: 0 0 !important;
}

/* .tableSupplyChain .ant-table-thead>tr>th {
    padding: 0 0 !important;
} */

.tableWatchList th.ant-table-cell {
    font-weight: 600 !important;
    font-size: 13px !important;
    font-family: inter;
    color: #3d4353 !important;
    padding-left: 0px !important;
}

.tableWatchList .ant-table-cell-row-hover {
    background-color: rgba(247, 248, 250, 1) !important;
}

.redBG {
    background-color: rgba(239, 241, 246, 1) !important;
}