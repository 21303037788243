.new-supply-chain-ps .ant-select-selector {
  border-radius: 8px 0px 0px 8px;
}

.new-supply-chain-ps .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  text-align: left !important;
  color: var(--grays-300, #80838b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.uplode-the-supply {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uplode-the-supply h6 {
  color: var(--primary-primary-500, #0195ce);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}

.indexing p {
  padding-top: 0px;
}

.uploade-chain .ant-modal-content {
  padding: 0px !important;
}

.uploade-chain .ant-modal-title {
  padding: 16px 24px !important;
  border-bottom: 1px solid #e4e6ed;
}

span.anticon.anticon-close.ant-modal-close-icon svg {
  padding: 2px !important;
}

.data-uploade-ps {
  padding: 12px 24px;
}

.uploade-your-chain p {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.uploade-your-chain span {
  color: var(--primary-primary-500, #0195ce);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.uploade-your .ant-modal .ant-modal-header {
  margin-bottom: 0px !important;
}

.comp-name-chain label {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 5px;
}

.uploade-your-chain {
  padding-bottom: 16px;
}

.sub-mit-upload input {
  border-radius: 4px;
  border: 1px solid var(--grays-50, #e4e6ed);
  height: 40px;
  padding: 0px 12px;
  width: 100%;
  outline: none;
  line-height: 42px;
}

.sub-mit-upload button {
  border-radius: 4px;
  background: var(--primary-primary-500, #0195ce);
  padding: 10px 16px;
  outline: none;
  border: none;
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.sub-mit-upload {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: unset;
}

.sub-mit-upload button span img {
  width: 19px;
  margin-right: 5px;
}

.text-input-ps {
  width: 100%;
  display: contents;
}

.enter-the-comp-name {
  display: flex;
  width: 75%;
  gap: 4px;
  margin-top: 10px;
}

.enter-the-comp-name p {
  color: var(--grays-500, #4e515c);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.uploade-chain .ant-modal-footer {
  padding: 11px 24px 24px !important;
  margin-top: 0px;
}

.clear-search-line h6 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.clear-search-line p {
  color: var(--grays-300, #80838b);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.clear-search-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-history-sec-ps {
  margin: 40px 0px;
}

.apple-inc-box {
  border-radius: 8px;
  border: 1px solid var(--grays-75-a-200, #d2d7e5);
  background: var(--White, #fff);
  padding: 16px;
  cursor: pointer;
}

.icon-apple {
  display: flex;
  gap: 11px;
}

.icon-apple h6 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.icon-apple p {
  color: var(--grays-500, #4e515c);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.apple-log-image {
  display: flex;
  justify-content: space-between;
}

.comp-historyps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
}

.apple-map-supply {
  margin-top: 5px;
}

.risk-scrore {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.risk-scrore h6 {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.risk-scrore p {
  color: var(--red-600, #b70000);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.risk-reso p {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.number-of-risk p {
  color: var(--red-600, #b70000);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.totle-risk {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
}

.ant-modal .ant-modal-close:hover {
  background-color: #fff !important;
}

.indexing {
  padding: 15px 10px 0px 10px;
}

.uploade-chain .ant-modal-close {
  right: 24px !important;
}

.new-supply-chain-ps .ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  inset-inline-start: 22px;
  inset-inline-end: 11px;
  bottom: 0;
}

.new-supply-chain-ps .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px 0px 22px;
}

.green-number-text p {
  color: var(--green-green-500, #1a9c3e);
}

.orange-number-text p {
  color: var(--orange-orange-500, #f28c28);
}

.downloadSample {
  display: flex;
  align-items: center;
  color: var(--primary-primary-500, #0195ce);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.downloadSample a:hover {
  color: var(--primary-primary-500, #0195ce) !important
}

.spinLoader {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}

.text-input-ps .ant-select-single {
  font-size: 14px;
  height: 40px;
  width: 75%;

}

.tableSupplyChain .ant-table-thead>tr>th {
  padding: 0 0 !important;
}

.tableSupplyChain .ant-table-tbody>tr>td {
  padding: 0 0 !important;
}

.tableSupplyChain th.ant-table-cell {
  font-weight: 600 !important;
  font-size: 13px !important;
  font-family: inter;
  color: #3d4353 !important;
}

.autocomplete-dropdown-no-cursor .ant-select-dropdown-menu-item {
  pointer-events: none;
  /* Disable pointer events for the dropdown menu items */
}

.tableSupplyChain .ant-table-cell-row-hover {
  background-color: rgba(247, 248, 250, 1) !important;
}

.redBG {
  background-color: rgba(239, 241, 246, 1) !important;
}

.autocomplete-dropdown-no-cursor .ant-select-item.ant-select-item-option.ant-select-item-option-active:hover {
  background-color: #c1cece87 !important;
}

.autocomplete-dropdown-no-cursor.ant-select-dropdown.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white !important;
  /* Hover item color */
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: white !important;
}

.autocomplete-dropdown-no-cursor .ant-select-item-option-selected {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background-color: white !important;
}




/* Custom styles for the dropdown */
.autocomplete-dropdown-no-cursor .ant-select-dropdown .ant-select-item-option-selected {
  background-color: white !important;
  /* Background color for selected item */
  color: black !important;
  /* Text color for selected item */
}

.ant-select-dropdown .ant-select-item-option:hover {
  color: black !important;
}