.supply-chain-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section.supply-chain-top-ps {
  margin-top: 12px;
  margin-bottom: 36px;
}

.main-top-apple h1 {
  color: var(--grays-800, #07132f);

  /* Mobile/H 1 */
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
}

.bottom-space-supply-ps {
  margin-bottom: 40px;
}

.placeholder-drop {
  padding: 8px 10px;
  color: #4e515c;
  font-size: 12px;
  width: 206px;
}

.all-grid-sys {
  max-width: 100%;
}

.supp-grid-sys {
  padding-top: 4px;
}

.all-grid-sys {
  margin-bottom: 40px;
}

.supply-chain-left-side-ps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.risk-per-comp {
  margin-bottom: 40px;
}

.supply-chain-risk-grph-ps {
  border-radius: 12px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
  padding: 16px 18px;
}

.supply-chain-risk-grph-ps h5 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.today-per-ps p {
  color: var(--grays-500, #4e515c);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.today-per-ps h6 {
  color: var(--red-red-500, #d00000);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.today-per-ps {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 0px;
}

.today-per-ps span {
  color: var(--red-red-500, #d00000);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  background: var(--red-25-a-100, #fceded);
  padding: 3px;
}

.bott-incidents-ps p {
  color: var(--grays-500, #4e515c);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.orange-ps-map h6 {
  color: var(--orange-orange-500, #f28c28);
}

.green-ps-map h6 {
  color: var(--green-green-500, #1a9c3e);
}

.green-ps-map span {
  color: var(--green-green-500, #1a9c3e);
  background: var(--green-25-a-100, #e8f5ec);
}

.risk-per-company-right-ps {
  border-radius: 8px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
  padding: 16px 24px 51px;
}

.ellipse-graph-ps img {
  width: 86%;
  margin: auto;
  display: flex;
  justify-content: center;
}

.risk-per-company-right-ps h5 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.incidents-number-and-severity h5 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 16px;
}

.lincidents-line-chart {
  display: flex;
  gap: 24px;
}

.line-chart-box-ps {
  border-radius: 6px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: #fff;
  padding: 16px;
  width: 50%;
  height: 372px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.collection-incidents h6 {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.collection-incidents p {
  color: var(--grays-300, #80838b);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-top: 10px;
}

.total-num-incidents-ps {
  display: flex;
  gap: 14px;
  /* margin-bottom: 60px; */
}

.line-chart-supply {
  display: flex;
  justify-content: center;
}

.line-chart-supply img {
  width: 100%;
}

.donut-chart-supply {
  display: flex;
  justify-content: center;
  margin-top: 13px;
}

.high-risk-suppliers-ps {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.first-suppli {
  padding: 16px 20px;
  border-radius: 8px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
}

.incidents-suppliers p {
  color: var(--grays-300, #80838b);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.incidents-suppliers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.supply-filter .ant-select-single {
  font-size: 14px;
  height: 40px;
  width: 100%;
}
.number-of-incidents-ps {
  margin-bottom: 40px;
}

.apply-btn button {
  background: #0195CE;
  padding: 6px 34px;
}

.grid-container-accor {
  column-gap: 65px;
}

.top-checkbox input.categories-box {
  border-radius: 6px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
  font-size: 12px;
  padding: 10px 9px;
  width: 100%;
  outline: none;
  cursor: pointer;
}

.filters-sec {
  margin-bottom: 25px;
}

section.filt-bg {
  margin-bottom: 40px;
}

.select-categories-modal {
  width: 100% !important;
  max-width: 944px;
}

.select-categories-modal .ant-modal-header {
  margin-bottom: 0px !important;
}

.search-actegories-ps input {
  width: 100%;
  position: relative;
  outline: none;
  border: none;
  padding: 11px 53px;
}

.search-actegories-ps {
  position: relative;
  border-bottom: 1px solid #e4e6ed;
}

.search-actegories-psimg {
  position: absolute;
  top: 12px;
  left: 23px;
}

.esg-factors-ps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  border-bottom: 1px solid #e4e6ed;
}

.active-item p {
  color: var(--grays-600, #3d4353) !important;
  font-family: Inter;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px;
}

.active-item {
  border-top: 1px solid var(--grays-50, #e4e6ed);
  border-bottom: 1px solid var(--grays-50, #e4e6ed);
}

.esg-factors-ps label.ant-checkbox-wrapper {
  margin-right: 14px !important;
}

.right-search-section-ps {
  width: 100%;
  /* border-right: 1px solid #E4E6ED; */
}

.esg-factors-ps p {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.env-heading-tab-ps h6 {
  color: var(--grays-400, #676a73);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.environment-factor {
  display: flex;
  margin-top: 40px;
}

.environment-side-ps {
  width: 100%;
}
.env-heading-tab-ps {
  border-top: 1px solid var(--grays-50, #e4e6ed);
  border-bottom: 1px solid var(--grays-50, #e4e6ed);
  border-left: 1px solid var(--grays-50, #e4e6ed);
  background: var(--Screen-background, #f7f8fa);
  padding: 10px 24px 10px 24px;
}

.envi-name-ps p {
  color: var(--grays-900, #0d0d0e);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.envi-name-ps {
  padding: 7px 24px;
}

.envi-name-ps label.ant-checkbox-wrapper {
  margin-right: 13px;
}

.righ-side-scrolling {
  min-height: 100%;
  overflow: auto;
  position: relative;
}
.righ-side-scrolling::-webkit-scrollbar {
  display: none;
}
.select-categories-modal .ant-modal-footer {
  border-top: 1px solid #e4e6ed;
  padding: 20px 24px 20px !important;
}

.select-categories-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0195ce;
  border-color: #0195ce !important;
}

.select-categories-modal .ant-checkbox .ant-checkbox-inner {
  border: 1px solid #9a9ca2;
  border-radius: 3px;
}

.select-categories-modal
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #0195ce;
  border-color: transparent;
}

.border-line-ps {
  position: relative;
}

.border-line-ps:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 36px;
  top: 10px;
  background: #d2d7e5;
  right: -34px;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: #e4e6ed;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #e4e6ed !important;
  box-shadow: none !important;
}

.supply-chain-pie-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 400px;
  position: relative;
}
.supply-chain-pie-chart .pieChart_portfolio {
  height: 400px !important    ;
}

.apple-map-supply {
  margin-top: 5px;
  width: 100%;
}

.red-esg-risk p {
  border-radius: 2px;
  text-align: center;
  padding: 4px 8px;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.coll-table td.ant-table-cell:nth-child(7) {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  width: 102px;
}

.risk-change-ten-per span {
  color: var(--red-red-500, #d00000);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  background: var(--red-25-a-100, #fceded);
  padding: 4px 4px 4px 0px;
}

.new-cloum-box-ps span.ant-select-selection-item {
  color: var(--grays-500, #4e515c);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.new-cloum-box-ps {
  padding: 0px 10px;
  height: 40px;
  position: relative;
}

.downl-btn img {
  width: 36px;
}

.new-cloum-box-ps img {
  position: absolute;
  left: 25px;
  z-index: 7;
  top: 9px;
}

.coll-table
  ul.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-2i2tap {
  display: none;
}

.page-nation .ant-select .ant-select-arrow {
  top: 27%;
  height: 12px;
  margin-left: 17px;
  margin-top: 0px;
  font-size: 12px;
  inset-inline-end: 6px;
}

.page-nation
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 100%;
  height: 29px !important;
  padding: 0 11px;
  border-radius: 4px !important;
}

.sup-data-Um {
  cursor: pointer;
}
span.sup-data-Um img {
  display: none;
}

.risk-change-ten-per {
  text-align: right;
}

.riskchange {
  text-align: right !important;
}

.index-tabel-ps {
  color: var(--grays-200, #9a9ca2);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.page-nation span.anticon.anticon-down {
  border: 0px solid #ced2e0 !important;
  border-radius: none !important;
}

.page-nation span.ant-select-selection-item {
  color: var(--grays-600, #3d4353) !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
}

.supply-chain-search-ps input {
  width: 289px;
}

.coll-table
  .ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.red-esg-risk {
  width: 100%;
  display: flex;
  max-width: 52px;
  text-align: right;
  float: right;
}

.autocomplete-input-search .ant-input-group > .ant-input:first-child {
  border-radius: 6px !important;
  border: 1px solid var(--grays-50, #e4e6ed) !important;
  background-color: #0000000d;
  width: 289px;
  line-height: 26px !important;
  padding-left: 29px;
}

.autocomplete-input-search
  .ant-input-group
  > .ant-input:first-child::placeholder {
  color: var(--grays-400, #676a73);

  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
}

.serchmedia-icon {
  background-image: url("../../Assets/Images/search-table.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-position: 3% 58%;
}

.autocomplete-input-search span.ant-input-group-addon {
  display: none !important;
}

.ant-select-single.ant-select-lg .ant-select-selector {
  display: block !important;
}
.env-factor-div {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 527px;
  border-right: 1px solid var(--Grays-50, #E4E6ED);

}

.env-factor-divNew {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 527px;
  overflow: auto;
}

/* table icon */

.chart-network-table-icons button {
  border-radius: 100px;
  border: 1px solid var(--Grays-75-A200, #D2D7E5);
  color: var(--Grays-600, #3D4353);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 4px 16px 4px 12px;
  background: #fff;
}


