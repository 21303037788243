.social-media-container {
    max-width: 1120px;
    margin: auto;
    width: 100%;
}

.social-media-top-space {
    padding: 12px 0px;
}

.bread-crumbs-links p {
    color: #9A9CA2;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.bread-crumbs-links span:nth-child(3) {
    color: #2D303A !important;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.bread-crumbs-links p span {
    color: #B6BCC7;
    font-size: 14px;
    padding: 0px 5px;
}

.social-media-search input {
    border-radius: 6px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: rgba(247, 248, 250, 0.85);
    outline: none;
    padding: 8px 12px 8px 35px;


}

.social-media-search input::placeholder {
    color: var(--grays-400, #676A73);
        font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 184.615% */
}

.media-breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.social-media-main-container {
    padding-top: 24px;
}

.social-apple-faces {
    border-radius: 0px 8px 8px 0px;
    border-left: 2px solid var(--red-red-500, #D00000);
    background: var(--Screen-background, #F7F8FA);
    padding: 16px 24px;
}

.apple-face-text h5 {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.over-environmental {
    display: flex;
    gap: 24px;
    align-items: center;

}

.severity {
    display: flex;
    gap: 20px;
    padding-top: 7px;
    position: relative;


}

.severity p {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: relative;

}

.severity p span {
    color: var(--grays-500, #4E515C);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.social-media-tabs {
    padding-top: 30px;
    /* margin-right: 30px; */

}

.sentiment-media {
    margin-top: 40px;
    border-radius: 12px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    padding: 16px;
}

.summery-ps {
    display: flex;
    gap: 16px;
}

.sentiment-heading h6 {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.sentiment-heading p {
    color: var(--grays-300, #80838B);
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.sentiment-heading {
    display: flex;
    justify-content: space-between;
align-items: center;
}

.sentiment-summary {
    width: 100%;
}

p.over-text-ps {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-top: 8px;

}

.over-content p {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.over-content p span {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.over-content {
    padding-top: 16px;
}


.reaction-box h6 {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.reaction-box {
    margin-top: 24px;
    border-radius: 4px;
    background: var(--Screen-background, #F7F8FA);
    padding: 24px 24px 62px 24px;
}

.post-reaction p {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 12px;

}

.separated-by p {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: 24px;
}

.overall-rea {
    display: flex;
    gap: 115px;
    padding-top: 24px;

}

.post-reaction span {
    border-radius: 4px;
    background: var(--primary-primary-500, #0195CE);
    padding: 4px 26px;
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.separated-by span {
    border-radius: 4px;
    background: var(--green-400, #48B065);
    color: var(--White, #FFF);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 4px 15px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
    width: 48px;
}

.separated-by span:nth-child(3) {
    background: #DC4040 !important;
}



.separated-by span:nth-child(4) {
    background: var(--grays-100, #B6BCC7);
}

.tweet-heading p {
    color: var(--grays-500, #4E515C);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 12px;

}

.tweet-heading span {
    color: var(--grays-800, #07132F);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.media-box {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin-top: 6px;

}

.numbers-of-tweet {
    display: flex;
    gap: 50px;
    justify-content: center;

}

.tweet-reply {
    border-radius: 6px;
    border: 1px solid var(--grays-75-a-200, #CED2E0);
    padding: 40px 0px 16px 0px;
    text-align: center;
    position: relative;
}

img.twitter-icons-ps {
    position: absolute;
    top: -28px;
    right: 128px;
}

.pie-chart-ps {
    margin-top: 16px;
}
.pie-chart-ps > div{
    justify-content: center;
    display: flex;

}

.reactions-ps {
    border-bottom: 1px solid var(--grays-25-a-100, #EFF1F6);
    background: var(--Screen-background, #F7F8FA);
    padding: 16px 24px 12px 24px;
    border-radius: 10px 10px 0 0;

}

.top-keywords {
    border-radius: 12px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    width: 100%;

}


.reactions-ps p {
    color: var(--grays-800, #07132F);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}



.top-main-keywords-ps {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.top-keyword-map {
    text-align: center;
}

.social-media-tab-heading h5 {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.social-media-tab-heading {
    margin-top: 24px;
}


.over-view-tabs-section .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 0px !important;
}

.over-view-tabs-section .ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, .ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    flex-direction: column !important;
    width: 100% !important;
}


.over-view-tabs-section .ant-tabs .ant-tabs-tab {
    color: var(--grays-300, #80838B);

    /* Web/Subtitle 2 */
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.over-view-tabs-section .ant-tabs .ant-tabs-tab {
    padding: 1px 0 !important;
}
.over-view-tabs-section .ant-tabs .ant-tabs-tab-btn {
    width: 100% !important;
    padding: 12px 13px;

}

.over-view-tabs-section .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    border-radius: 4px;
    background: var(--Screen-background, #F7F8FA) !important;
    padding: 12px 13px;
    position: relative;
}

.over-view-tabs-section .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
 display: none !important;
}


.over-view-tabs-section {
    margin-top: 24px;
}

.over-view-tabs-section .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:after {
    content: "";
    position: absolute;
    width: 3px;
    height: 42px;
    top: 0px;
    left: 0px;
    background: #0195CE;
}

.social-media-content {
    margin-left: 34px;
}
.top-keyword-map img {
    width: 100%;
}

.summary-x p {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.summary-x {
    margin: 24px 0px;
}

.msot-relevant h6 {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.climate-change h6 {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding: 8px 0px;

}

.climate-change p {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.climate-change h6 span {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #05476F;
    border-radius: 50%;
    margin-right: 8px;
}

.climate-change h6 {
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.all-comments h6 {
    border-radius: 4px 1000px 4px 4px;
    background: var(--green-50, #D1EBD8);
    padding: 8px 0px 8px 16px;
    color: var(--green-900, #0A3E19);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 24px; 
}

.positive-comm {
    margin: 24px 0px 0px;
}

.all-comments p {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 16px 0px;
}

.nagative-comm h6{
    background: var(--red-50, #F8D9D9) !important; 
    color: var(--red-900, #5E0000);
}

.neutral-comm h6{
    background: var(--grays-50, #E4E6ED) !important;
    color: var(--grays-700, #2D303A);

}


.replies-sections {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 40px;
    border-bottom: 1px solid #E4E6ED;
}

.tweets-replies {
    background: #f7f8fa;
    /* padding: 8px 40px; */
    margin: 24px 0px;
border-radius: 8px;
}

.replies-sections h5 {
    color: var(--grays-800, #07132F);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.user-twitter-name h6 {
    color: var(--grays-800, #07132F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.user-twitter-name h6 span {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.user-twitter-name p {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 8px;
}

.view-comm-ps {
    display: flex;
    gap: 16px;
}

.view-comm-ps p {
    color: var(--grays-800, #07132F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding-top: 16px;
}

.view-comm-ps p span {
    color: var(--grays-200, #9A9CA2);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.john-tweets {
    padding: 24px 24px 16px 16px;
    border-radius: 8px 8px 0 0;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    margin-top: 24px;
}

.full-tweet-dis {
    display: flex;
    gap: 14px;
}

.view-replies-btn-ps p {
    color: var(--primary-primary-500, #0195CE);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.all-rep-neg-pos h6 {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.all-rep-neg-pos p {
    color: var(--grays-200, #9A9CA2);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.number-of-replies {
    display: flex;
    justify-content: space-between;
    border-radius: 0px 0px 8px 8px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    padding: 8px 16px;
}

.all-rep-neg-pos {
    display: flex;
    gap: 5px;
}


.all-rep-neg-pos p span {
    border-radius: 4px;
    background: var(--green-25-a-100, #E8F5EC);
    padding: 4px 21px;
    color: var(--green-600, #168535);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.all-rep-neg-pos h6 span {
    border-radius: 4px;
    background: var(--primary-25-a-100, #E5F7FF);
    padding: 4px 21px;
    color: var(--primary-800, #045B86);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
}


.all-rep-neg-pos p img {
    padding: 0px 7px;
}

p.neg-box-pg span {
    background: var(--red-25-a-100, #FCEDED);
    color: var(--red-600, #B70000);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


p.neut-box span {
    background: var(--grays-50, #E4E6ED);
    color: var(--grays-400, #676A73);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.apple-image-top img {
    width: 100%;
}

.insiderpaper-collection-ps {
    margin-top: 8px;
}

.apple-image-top img {
    width: 100%;
    border-radius: 8px 8px 0 0;
}

.france-pro-ps {
    border-radius: 0px 0px 8px 8px;
    border-right: 1px solid var(--grays-50, #E4E6ED);
    border-bottom: 1px solid var(--grays-50, #E4E6ED);
    border-left: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    padding: 12px 16px;

}

.france-pro-ps p {
    color: var(--grays-500, #4E515C);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 0px;

}

.france-pro-ps h6 {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.rebecca-name h6 {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.rebecca-name p {
    color: var(--grays-500, #4E515C);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-top: 0px;

}

.main-rebecca-sec {
    padding: 0px;
}

.rebecca-outer-space {
    padding: 16px 24px;
}

p.box-disp-ps {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

p.box-disp-ps span {
    color: var(--grays-400, #676A73);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.google-office-ps img {
    width: 100%;
}

.position-box-ps {
    border-radius: 0px 0px 0px 0px;
}

.like-btn-sec-ps p {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


.like-btn-sec-ps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;

}

.like-btn-sec-ps p span img {
    margin-right: 5px;
}

.over-view-tabs-section .ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before {
  display: none !important;
}

.social-media-search {
    position: relative;
}

.social-media-search img {
    position: absolute;
    top: 13px;
    left: 9px;
}

.severity p:nth-child(1):before {
    content: "";
    position: absolute;
    width: 1px;
    height: 15px;
    background:  #CED2E0 ;
    top: 1px;
    right: -11px;
}

.john-replies-tweets{
    padding: 0px 40px 24px;

}


.on-scroll-media::-webkit-scrollbar {
    display: none;

}

.on-scroll-media {
    height: calc(100vh - 100px);
    min-height: 100%;
    overflow: auto;
    position: relative;
}
.overall-rection {
    background: transparent;
    border-bottom: none;
}

.overall-rection p{
    color: var(--grays-700, #2D303A);
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 24px; 
}

.mediacontainerWidth{
    width: 100% !important;
}

.hide-replies {
    background: var(--White, #FFF);
    padding: 40px 24px 24px 56px;
}

.remove_padding {
    padding: 0px 24px 24px 56px!important;
}

.rachel-replies {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rachel-replies p {
    border-radius: 100px;
    background: var(--green-50, #D1EBD8);
    color: var(--green-700, #126D2B);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 4px 12px;
}


.rachel-replies-text p {
    border-radius: 0px 2px 2px 0px;
    border-left: 1px solid var(--green-green-500, #1A9C3E);
    background: var(--green-25-a-100, #E8F5EC);
    padding: 4px 8px;
    margin-top: 8px;
}


.zeev-negative-rep p {
    color: var(--red-700, #920000);
    background: var(--red-50, #F8D9D9);
}

.negative-background p {
    background: var(--red-25-a-100, #FCEDED);
    border-left: 1px solid var(--red-red-500, #D00000);
}

.neutral-background img {
    width: 100%;
}

.meccanica-neutral-rep p {
    color: var(--grays-700, #2D303A);
    background: var(--grays-50, #E4E6ED);
}

.neutral-background p {
    border-left: 1px solid var(--grays-300, #80838B);
    background: var(--grays-25-a-100, #EFF1F6);
}

.neutral-background img {
    width: 100% !important;
    border-radius: 8px;
    margin-top: 8px;
}

.post-and-comments-rep {
    padding: 40px 24px 24px 16px;
    background: var(--White, #FFF);
}

 .zeev-user-name {
    border-radius: 8px;
    background: var(--green-25-a-100, #E8F5EC);
    padding: 16px;
} 

.senior-solutions p {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.senior-solutions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ralph-user-name {
    background: var(--red-25-a-100, #FCEDED);
    border-radius: 8px;
    padding: 16px;
}


.bethany-user-name {
    background: var(--grays-25-a-100, #EFF1F6);
    padding: 16px;
    border-radius: 8px;

}

.donut-map-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;

}

.top-keyword-map-donut {
    text-align: center;
    width: 188px;
}

.overall-color {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 27px;
    margin-bottom: 9px;

}

.overall-color img {
    padding-bottom: 2px;
}

.overall-color p{
    display: flex;
    gap: 4px;
    align-items: center;

}

.overall-color p {
    color: var(--grays-600, #3D4353);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.overall-color h6 {
    color: var(--grays-900, #0D0D0E);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.user-bar-chart-ps {
    border-radius: 8px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    padding: 16px 24px 0px;
    margin-top: 24px;
}

.influential-user-ps h5 {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}
.user-name-id-ps p {
    color: var(--grays-800, #07132F);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


.user-name-id-ps p span {
    color: var(--grays-400, #676A73);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.elon-musk-ps {
    display: flex;
    align-items: center;
    gap: 15px;
}

.most-followers-ps {
    display: flex;
    gap: 70px;
}

.twitter-user-sectoion-ps {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.num-of-followers-ps p {
    color: var(--primary-900, #05476F);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.followers-title-ps {
    width: 50%;
}

p.follow-text-on-top {
    text-align: right;
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

p.like-text-on-top {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


.user-name-id-ps span {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}


.top-keyword-map div {
    width: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

.time-line-background-color {
    background: var(--Screen-background, #F7F8FA);
}

.user-twitter-name {
    width: 100%;
}

.raddit-pro img {
    border-radius: 50%;
}

.upvotes p span {
    color: var(--red-400, #DC4040);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.amazone-text-desp p {
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.amazone-office-image img {
    border-radius: 6px;
    margin-top: 6px;

}

.amazone-text-box {
    display: flex;
    align-items: flex-start;
    margin-top: 16px;
    gap: 16px;
}


.amazone-text-desp span {
    color: var(--primary-primary-500, #0195CE);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.amazone-upvotes-ps span {
    color: var(--grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-right: 13px;
}

.raddit-pro:before {
    content: "";
    width: 1px;
    height: 61%;
    background: #E4E6ED;
    position: absolute;
    top: 40px;
    left: 15px;
}

.twitter-user-profile-image.raddit-pro {
    position: relative;
}

.bar-chart-left-side ul {
    list-style: none;
    text-align: left;
    padding-left: 0px;
    margin-bottom: 0px;
}
.bar-chart-left-side{
    width: 30%;
}
.bar-chart-ps{
    width: 70%;
}

.bar-chart-section-ps {
    display: flex;
    align-items: center;
    padding: 0 22px;

}
.bar-chart-left-side ul li {
    padding-bottom: 8px;
    color: var(--grays-600, #3D4353);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.amazone-upvotes-ps {
    padding: 8px 0px;
}

.donut-container .apexcharts-datalabels-group{
    transform: translate(0, 8px) !important;
}

.amazone-text-desp{
    min-width: 65%;
}

.no-data{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    color: var(--Grays-300, #80838B);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.mt-24{
    margin-top: 24px;
}

.sentiment-media1 {
    margin-top: 24px;
    border-radius: 12px;
    border: 1px solid var(--grays-50, #E4E6ED);
    background: var(--White, #FFF);
    padding: 16px;
}

.view-replies-btn-ps img{
    margin-left: 8px;
    width: 16px;
    height: 16px;
}


.mostInfluentialUser-no-data{
   
    display: flex;
    align-items: center;
    justify-content: center;
    width: 212%;
    height: 90%;
    color: var(--grays-700, #2D303A);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

}

.red_color_upvotes {
    color: #DC4040!important;
}


.apple-image img {
 background-color: transparent;
 object-fit: contain;
 /* box-shadow: 0px 4px 16px 0px #afbedc; */
}