.card {
  width: 100%;
  max-width: 312px;
  min-width: 280px;
  border-radius: 16px;
  padding: 16px 24px;

}

.gridCard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  list-style: none
}

.card:hover {
  box-shadow: 0px 4px 16px 0px #afbedc;
}

.header {
  color: inherit;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  justify-content: space-between;
  margin-bottom: 14px;
}

.headerText {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.subComponent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 22px;
  border-bottom-width: 1px;
  border-bottom-style: double;
  border-bottom-color: #e4e6ed;
}

.subComponentNoPadding {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 7px;
  border-bottom-width: 1px;
  border-bottom-style: double;
  border-bottom-color: #e4e6ed;
}

.subComponentText {
  color: #07132f;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
}

.btn {
  background: #0195ce;
  border-radius: 4px;
  border: none;
  outline: none;

  /* padding: 8px 15px; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  height: 36px;
  width: 173px;
}

.btnMedium {
  background: #0195ce;
  border-radius: 4px;
  border: none;
  outline: none;

  /* padding: 8px 15px; */
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  height: 36px;
  width: 129px;
}

.smallBtn {
  width: 92px;
  height: 36px;
  border-radius: 4px;
  background: #0195ce;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  border: none;
}

.extraSmallBtn {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background: #0195ce;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #ffffff;
  border: none;
}

.smallBtn:disabled {
  background: #8dcfe9;
  cursor: no-drop;
}

.menuIcon {
  height: 28px;
  width: 28px;
  transform: rotate(90deg);
  object-fit: contain;
}

.menuIconWhite {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.editIcon {
  height: 16px;
  width: 16px;
  object-fit: contain;
  margin-right: 10px;
}

.label {
  color: inherit;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  justify-content: space-between;
  align-items: end;
}

.label span {
  font-size: 15px;
  font-weight: 600;
}

.text {
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
}

.createdAt {
  color: #4e515c;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.portfolioText {
  color: #2191fb;
}

.EsgRisk {
  color: #f28c28;
}

.chart {
  height: 100px;
  width: 100%;
}

.circle {
  height: 18px;
  width: 18px;
  border-radius: 18px;
}

.editDeleteText {
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
}

.deleteText {
  /* border-top: 1px solid rgba(228, 230, 237, 1); */
  padding: 15px 0 5px 0;
  color: #d00000;
  align-items: center;
}

.editText {
  color: #07132f;
  /* margin: 25px 0 10px 0; */
  padding: 15px 0 15px 0;
  border-bottom: 1px solid rgba(228, 230, 237, 1);
}

.popover.ant-popover-title {
  padding: 12px 26px !important;
}

.bannerContainer {
  height: 232px;
  width: 100%;
  z-index: 1;
  position: relative;
}

.bannerImage {
  height: 100%;
  width: 100%;
  background-image: url("../../Assets/icon/worldImage.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0px;
  z-index: 1;
}

.bannerMainContainer {
  position: relative;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  z-index: 2;
}

.maxContainer {
  max-width: 1536px;
  /* margin-left: 108px; */
  padding: 20px 0px;
  width: 1536px;
  margin: auto;

}

.maxContainerCstmHeight {
  max-width: 1536px;
  /* margin-left: 108px; */
  padding: 20px 0px;
  width: 1536px;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  /* max-width: 1320px; */
  flex-wrap: wrap;
  margin: auto;
  padding: 0px 108px;

}

.containerWidth {
  max-width: 752px;
  display: flex;
  flex-wrap: wrap;
}

.greyGrid {
  padding: 32px 24px 16px 24px;
  border-radius: 8px;
  gap: 8px;
  background-color: #f7f8fa;
}

.subtitle1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.subtitle4 {
  color: var(--Color-Content-dark-gray, rgba(103, 106, 115, 1));
  /* Web/Subtitle 4 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  /* 171.429% */
}

.brdrPercentage {

  padding: 6px 16px 6px 16px;
  gap: 4px;
  border: 0px 0px 0px 1px;
  opacity: 0px;
  border-left: 1px solid var(--Color-Border-primary, rgba(216, 218, 224, 1))
}

.subtitle4Error {
  color: #B70000;
  /* Web/Subtitle 4 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 171.429% */
}

.subtitle2 {
  color: #0D0D0E;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 160% */
}

.body {
  color: var(--grays-800, #07132F);
  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.buttonM {
  color: #0195CE;
  text-align: right;
  /* Web/Button M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.viewMoreContainer {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #B9E0F0;
  background: #E5F7FF;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
}

.subtitle2Copy {
  color: #2D303A;
  text-align: center;
  /* Web/Subtitle 2 */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 160% */
}

.H3 {
  color: #2D303A;
  text-align: center;
  /* Web/H 3 */
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 114.286% */
}

.H1 {
  color: #05476F;
  text-align: center;
  /* Web/H 1 */
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 133.333% */
}

.inputLabel {
  color: #07132f;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.removebtn {
  height: 36px;
  padding: 5px 16px 5px 16px;
  border-radius: 4px;
  color: #80838b;
  background-color: #ffffff;
  border: none;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.secondryBtnM {
  display: flex;
  height: 36px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #b9e0f0;
  background: #e5f7ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #0195ce;
}

.webBody {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.uplodePer {
  border-radius: 4px;
  background: #fceded;
  padding: 0px 4px 0px 0px;
  height: 24px;
}

.todayText {
  display: flex;
  align-items: center;
  gap: 12px;
}

.uplodePer span {
  color: #d00000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.uplodePer span img {
  padding-right: 5px;
  padding-bottom: 2px;
}

.todayText p {
  color: #05476f;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.todayText label {
  color: #d00000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.riskText p {
  color: inherit;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.portText h4 {
  color: #05476f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.holding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holding p {
  color: #05476f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.riskScorePs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.holding label {
  color: #05476f;
  text-align: right;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.value p {
  color: #0195ce;
  /* Web/Body - selected */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.infoLabel {
  display: flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  /* background: #D00000; */
  margin-left: 12px;
}

.leftDataContainer {
  display: flex;
  align-items: center;
}

.customBackgroundColor {
  background: #FCEDED;
  width: 100%;
  border-radius: 8px;
  gap: 8px;
  padding: 16px;
}

.customBackgroundColor2 {
  background: #FDF1E4;
  width: 100%;
  border-radius: 8px;
  gap: 8px;
  padding: 16px;
}

.customBackgroundColor3 {
  background: #FFF7DF;
  width: 100%;
  border-radius: 8px;
  gap: 8px;
  padding: 16px;
}

.customBackgroundColor4 {
  background: #E8F5EC;
  width: 100%;
  border-radius: 8px;
  gap: 8px;
  padding: 16px;
}

.leftDataContainer ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 34px !important;
}

.BodySelected {
  color: #FFF;
  /* Web/Body - selected */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 184.615% */
}

.customColorAlert {
  color: #5E0000 !important
}

.customColorAlert2 {
  color: #794614 !important
}

.customColorAlert3 {
  color: #794614 !important
}

.customColorAlert4 {
  color: #0A3E19 !important
}

.customProgressColor1 {
  color: #B70000 !important
}

.customProgressColor2 {
  color: #676A73 !important
}

.value2 p {
  color: #07132F;
  /* Web/Body - selected */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.redDot {
  width: 20px;
  height: 20px;
  background: linear-gradient(45deg, rgba(208, 0, 0, 0.80) 0%, rgba(232, 128, 128, 0.80) 100%);
  border-radius: 50%;
}

.value label {
  color: #f28c28;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.input_label {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.percentage_container {
  display: flex;
}

.CaptionXL {
  font-size: 12px;
  line-height: 16px;
  color: #4E515C;
}

.ButtonS {
  color: #80838B;
  /* Web/Button S */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 123.077% */
}

.downloadSample {
  color: #80838B;
  font-size: 12px;
  font-weight: 400;
  font-family: "inter";
  display: flex;
  align-items: center;
}

.inputFormPortfolio {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  padding: 24px 0 16px 0;
}

.input_field {
  width: 60%;

}

.nextBtn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.inputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 32px 0 4px 0;
}

.inputContainerNew {
  max-width: 752px !important;
  display: grid;
  grid-template-columns: 0.2fr 2fr 0.3fr 0.3fr 1fr;
  gap: 16px;
  padding: 0px;
  border-style: solid;
  border-color: var(--Color-Border-primary, rgba(216, 218, 224, 1));
  gap: 0px;
  border: 1px 0px 1px 0px !important;
  opacity: 0px;
  background: inherit;

}

.inputContainer2New {
  display: grid;
  align-items: center;
  gap: 4px;
  max-width: 752px !important;
  grid-template-columns: 0.2fr 1.8fr 1fr 1fr 0.89fr;
  background: inherit;
  border: 1px solid var(--Color-Border-primary, rgba(216, 218, 224, 1));
  border: 1px 0px 1px 0px !important;
}

.inputContainer2New:focus {
  background: red;
}

.style_inputContainer2New__HGGwt:hover input.ant-input.ant-input-lg.css-dev-only-do-not-override-2i2tap {
  background: red;
}

.inputContainer2New:hover {
  background: var(--Color-Surface-surface-2, rgba(247, 248, 250, 1));
}

.inputContainer2N {
  display: grid;
  align-items: center;
  gap: 4px;
  max-width: 752px !important;
  grid-template-columns: 1fr;
  background: var(--Color-Surface-surface-1, rgba(255, 255, 255, 1));
  border: 1px solid var(--Color-Border-primary, rgba(216, 218, 224, 1));
}

.inputContainer2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.radioGroupl label.ant-radio-wrapper {
  font-size: 35px !important;
}

.portfolioRiskChartCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  right: 276px;
  position: relative;
  margin-left: -100px;
  width: 113px;
}

@media (max-width: 1280px) {
  .gridCard {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .card {
    max-width: 410px;
    min-width: 280px;
  }
}

@media (max-width: 992px) {

  .card,
  .ant-col.ant-col-sm-24.ant-col-md-12.ant-col-lg-8 {
    max-width: 410px !important;
  }
}

@media (max-width: 954px) {
  .gridCard {
    grid-template-columns: 1fr 1fr;
  }

  .card {
    max-width: 465px;
    min-width: 302px;
  }

}

@media (max-width: 628px) {
  .gridCard {
    grid-template-columns: 1fr;
  }

  .card {
    max-width: 628px !important;
  }
}

@media (max-width: 480px) {

  .card,
  .ant-col.ant-col-sm-24.ant-col-md-12.ant-col-lg-8 {
    max-width: 628px !important;
    min-width: 302px;
  }

  .gridCard {
    grid-template-columns: 1fr;
  }
}

/* Sahil */
.width150 {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--Color-Content-teritiary, rgba(45, 48, 58, 1));
  /* 133.333% */
  height: 36px;
  /* border-radius: 4px; */
  border: none;
  background: inherit !important;

}


.inputFontSize {
  /* Web/Input/Placeholder */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  height: 36px;
  border-radius: 4px;
}

.inputFontSizeNew {
  width: 100%;
  /* Web/Input/Placeholder */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  height: 36px;
}






.bannerCon {
  margin: 0px 208px;
  padding-top: 12px;
}

.bannerMainContainer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid black; */
  align-items: center;
}

.customCol {
  display: block !important;
  flex-grow: initial !important;
  flex-shrink: initial !important;
  flex-basis: auto !important;
  color: var(--grays-200, #9A9CA2);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  z-index: 9;
  background: #fff;
  border-radius: 6px;
}

.customColSpan {
  color: #2D303A;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-left: 4px;
}

.colInput {
  display: flex;
  width: 304px;
  height: 40px;
  padding: 8px 157px 8px 4px;
  align-items: center;
  padding-right: 12px;
  gap: 8px;
  z-index: 9 !important;
}


.myPort {
  color: #05476F;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 114.286% */
  margin-top: 52px;
}

.myPortIcon {
  color: #05476F;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  /* 114.286% */
  margin-top: 52px;
  margin-left: 10px;
}

.portfolioData {
  color: #05476F;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}

.portLabel {
  font-weight: 400;

}

.portValue {
  font-weight: 600;
}


.customInputPlaceholder::placeholder {
  color: #80838B;
  /* Replace with your desired color */
}

.customInputPlaceholder:-ms-input-placeholder {
  color: #80838B;
  /* For Microsoft Edge */
}

.customInputPlaceholder::-webkit-input-placeholder {
  color: #80838B;
  /* For Chrome, Safari, etc. */
}

.spinLoader {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
}


.customInputPlaceholder input::placeholder {
  color: #80838B !important;
}


.customSelectOptionText {
  color: #80838B;
  font-size: 30px;
}


.gridInput {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e4e6ed;
  padding: 12px;
  width: 100%;
  font-size: 12px;
}

.gridInput :focus-visible {
  border: 1px solid rgba(1, 149, 206, 1) !important
}


.subComponent1 {
  border-bottom-style: none;
}

.subtitle41 {
  color: #2D303A;

}


/* pankaj */


.header {
  display: flex;
  justify-content: space-between;
}

.cardTitleName {
  display: inline-block;
  width: 199px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}


.CaptionXLfw {
  font-weight: 500;
}

.inputFontSize {
  width: 100%;

}

.inputFontSizeNew input {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  height: 36px;
}

.inputFontSizeNew .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: 1px solid red !important;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selector {
  border: none !important;
}

.ProgressColor1 {
  color: #1A9C3E;
}

.ProgressColor2 {
  color: #F28C28;
}

.ProgressColor3 {
  color: #B70000;
}

.sucessView {
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Color-Border-brand, rgba(0, 149, 206, 1));
  display: flex;
}
.successMessage{
font-family: Inter;
font-size: 13px;
font-weight: 500;
line-height: 24px;
text-align: left;
color: var(--Color-Content-secondary, rgba(31, 32, 36, 1));



}

.successItem{
font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 24px;
text-align: left;
color: var(--Color-Content-primary, rgba(13, 13, 14, 1));
margin-left: 5px;
}

.isinStyle{
font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 16px;
text-align: end;

}

