.companyNamesearch {
  display: block;
  font-family: inter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: #2D303A;
  font-weight: 500;
}

.tableCreatePortfolio .ant-table-thead>tr>th {
  padding: 0 0 !important;
}

.tableCreatePortfolio .ant-table-tbody>tr>td {
  padding: 0 0 !important;
}

.tableCreatePortfolio th.ant-table-cell {
  font-weight: 600 !important;
  font-size: 13px !important;
  font-family: inter;
  color: #3d4353 !important;
  padding-left: 0px !important;
}

.autoCompleteDropDown .ant-select-dropdown .ant-select-item {
  background-color: red;
}


.tableCreatePortfolio .ant-table-cell-row-hover{
background-color:  rgba(247, 248, 250, 1) !important;
}
.tableCreatePortfolio .ant-table-row-level-0 > td:nth-child(4){
 border-right: 1px solid rgb(216, 218, 224);
}
.tableCreatePortfolio .ant-table-thead > tr > th:nth-child(4){
  border-right: 1px solid rgb(216, 218, 224) !important;
  border-start-end-radius: 0px !important;
}

.redBG{
  background-color: rgba(239, 241, 246, 1) !important;
}