@media (max-width: 1275px) {
  .table-box.incident {
    overflow: auto;
    white-space: nowrap;
  }
  .search-modal-dgn-hv {
    width: 45%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}
@media (min-width: 1600px) {
  .container {
    /* max-width: 1120px; */
    margin: auto;
  }
  .container1360 {
    max-width: 1360px;
    margin: auto;
  }
}
@media (max-width: 1668px) {
  .search-modal-dgn-hv {
    width: 60%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}
@media (max-width: 1440px) {
  .search-modal-dgn-hv {
    width: 55%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}
@media (max-width: 1218px) {
  .search-modal-dgn-hv {
    width: 45%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
  .hone-left-side1 {
    max-width: 200px;
    overflow: auto;
  }
}

@media (max-width: 1200px) {
  /* form.example input[type="text"] {
    font-size: 9px;
  } */

  .hone-left-side1 {
    display: none;
  }
  .table-box.incident {
    overflow: auto;
    white-space: nowrap;
  }

  .margin-both-side {
    padding: 0 32px;
  }

  .adv-search-row .search-container form.example button {
    width: 12%;
  }

  .apple-pic {
    margin: 18px 0px;
  }

  .apple-pic img {
    width: 100%;
    height: 309px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 46% 29%;
  }

  .comp-name-tex h5 {
    font-size: 11px;
  }

  .esg-cate-name {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
  }
  .search-modal-dgn-hv {
    width: 45%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }

  .comp-loc {
    display: grid;
    grid-template-columns: auto auto;
  }

  .containersize {
    width: 80%;
  }

  .page-size {
    padding: 20px 58px;
  }

  .subscribe {
    gap: 10px;
  }

  .pro-page-bg {
    padding-left: 133px;
  }

  .table-box.incident.collection table tr th {
    border-bottom: 1px solid #ced2e0;
    padding: 10px 12px;
  }

  .tab-active {
    width: 100%;
    max-width: 205px;
  }
}

@media (max-width: 1100px) {
  .home-left-list {
    width: 100%;
    overflow: auto;
  }

  .inciden-box {
    gap: 47px;
  }

  /* .hone-left-side {
    width: 208px;
  } */

  .empty-watchlist-page {
    display: block;
    padding: 9px 10px;
  }

  .email-up {
    padding: 17px 13px;
    display: block;
  }
  .pro-page-bg {
    padding-left: 75px;
  }

  .table-box.incident.collection {
    overflow: scroll;
    white-space: nowrap;
  }

  .table-box.incident table tr td {
    padding: 12px 19px;
  }
}

@media (max-width: 992px) {
  .dashbored-head {
    /* padding: 2px 99px 0px 105px; */
  }

  .apple-logo {
    left: 159px;
  }

  /* .hone-left-side {
    position: relative;
  } */

  .serch-bar-in input {
    width: 189px;
  }
  /* .topp-div {
    display: block;
} */

  .page-nation {
    display: block;
    margin-top: 26px;
  }
  .page-num {
    justify-content: center;
    margin: 10px 0px;
  }

  .add-to-withc {
    margin-top: 27px;
  }

  .custom-slider {
    width: 89%;
    margin: auto;
    margin-top: 15px;
  }
  .slider-low {
    display: block;
  }

  .low-text-temp p {
    top: -2px;
  }

  .home-left-list {
    width: 100%;
    overflow: auto;
    display: none;
  }

  .model-tabs
    :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin: 0px 13px 0 7px;
    padding: 0 10px;
  }

  .model-tabs1
    :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin: 0px 13px 0 7px;
    padding: 0 10px;
  }

  .advanced-search-modal .ant-modal-content {
    /* height: auto !important; */
    margin: 0 20px;
  }

  .advanced-search-modal1 .ant-modal-content {
    /* height: auto !important; */
    margin: 0 20px;
  }

  /* .global-inner-sec p {
    line-height: 16px;
  } */
  .adv-sech-btn-top {
    margin-top: 8px;
  }
  .m-watch-drop {
    margin-bottom: 8px;
  }

  .top-bag-image {
    padding-top: 22px;
  }

  /* .global-inner-sec p {
    font-size: 11px;
    padding-top: 1px;
  } */

  .adv-search-row {
    display: block;
  }

  .inciden-box {
    gap: 25px;
  }

  form.example button {
    width: 9%;
  }

  .total-box h5 {
    font-size: 18px;
  }

  .new-box p {
    font-size: 14px;
  }

  .bot-padding {
    margin-bottom: 20px;
  }

  .esg-cate-name {
    grid-template-columns: auto auto auto;
    gap: 20px;
    display: grid;
  }

  .comp-name-tex h5 {
    font-size: 13px;
  }

  .left-details-side {
    margin-left: 0px;
  }

  .top-high-ris {
    grid-template-columns: 1fr 1fr;
  }

  .grid-container-accor {
    grid-template-columns: 1fr 1fr;
  }

  .page-size {
    padding: 20px 60px;
  }

  .supply-chain-sec {
    padding: 23% 0px 0px 0px;
  }

  .chain-des p {
    font-size: 14px;
    line-height: 24px;
  }

  .indexing p {
    margin-left: 21%;
  }

  .table-head-sec {
    display: flex;
    align-items: center;
    width: 40%;
    max-width: 430px;
    justify-content: space-between;
  }

  .subscribe {
    grid-template-columns: auto auto;
  }

  .pro-page-bg {
    padding-left: 55px;
  }

  .serch-bar-in {
    display: flex;
    margin-right: 4px;
  }
  .search-modal-dgn-hv {
    width: 85%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .pie-chart {
    display: flex;
    justify-content: center;
  }

  .coll-table :where(.css-dev-only-do-not-override-yp8pcc).ant-table-wrapper {
    white-space: nowrap;
    overflow: scroll;
  }
  .topp-div {
    display: block;
  }
  .table-head-sec {
    margin: 10px 0px;
  }

  .compnay-grid-cont {
    grid-template-columns: auto;
  }

  .inciden-box {
    grid-template-columns: auto auto;
    gap: 31px;
  }

  .seve-main-boxes {
    gap: 34px;
  }

  .comp-loc {
    grid-template-columns: auto auto;
  }

  .esg-cate-name {
    grid-template-columns: auto auto;
  }

  .all-grid-sys {
    grid-template-columns: auto auto;
    gap: 33px;
  }

  .subscribe {
    grid-template-columns: auto;
  }

  .pro-page-bg {
    padding-left: 25px;
  }

  .flex-pro {
    gap: 12px;
  }
}

@media (max-width: 667px) {
  .top-section {
    display: block;
  }

  .top-heading {
    margin-bottom: 18px;
  }

  form.example button {
    width: 14%;
  }

  .seve-main-boxes {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .chain-des p {
    font-size: 13px;
    line-height: 22px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 150px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 138px;
  }
}

@media (max-width: 600px) {
  .apple-logo {
    left: 6px;
    margin-top: -69px;
    position: relative;
  }

  .top-margin-comp {
    margin-top: 0px;
  }

  .top-space {
    margin-top: 0px;
  }

  .dashbored-head {
    position: relative;
    width: 100%;
  }

  .top-header-sec {
    position: relative;
    width: 100%;
  }
  .top-fixed {
    margin-top: 0px;
  }

  .dashbored-head {
    /* padding: 13px 35px 0px 35px; */
  }
  .ellips {
    margin-top: 10px;
  }

  .caot-sec {
    display: block;
  }
  .text-fild input {
    width: 100%;
  }

  .text-fild input {
    width: 100%;
  }

  .text-fild button {
    margin-left: 0px;
    margin-top: 8px;
  }

  .m-watch-drop {
    margin-top: 8px;
  }

  .m-watch-drop {
    margin-top: 8px;
  }

  /* .global-inner-sec h3 {
    font-size: 26px;
  } */

  /* .global-inner-sec {
    padding: 24px 24px 24px 24px;
  } */

  .recent-table {
    overflow: scroll;
    padding-bottom: 10px;
  }

  .tab-top {
    display: block;
  }

  .grid-container-accor {
    grid-template-columns: 1fr;
  }

  .top-main-heading-text {
    display: block;
  }

  .apple-num {
    width: 60px;
    margin-top: 23px;
  }

  .apple-pic img {
    height: 214px;
  }

  .esg-cate-name {
    grid-template-columns: auto;
  }

  .mentio-tweet {
    display: block;
  }

  .user-steve {
    display: block;
  }

  .heading-ap {
    display: block;
  }

  .main-top-apple {
    margin-bottom: 17px;
  }

  .top-high-ris {
    grid-template-columns: 1fr;
  }

  .grid-container-accor {
    grid-template-columns: 1fr;
  }

  .apply-btn {
    margin-top: 23px;
    text-align: start;
  }

  .page-size {
    padding: 20px 31px;
  }

  .phone-view {
    display: block;
  }

  .web-view {
    display: none;
  }

  form.example.supply-chan-search input {
    font-size: 12px;
  }

  .indexing p {
    margin-left: 18%;
  }

  .supply-chain-sec {
    padding: 27% 0px 0px 0px;
  }

  .flex-pro {
    display: block;
  }

  .reset-btns {
    justify-content: start;
  }

  .serch-bar-in input {
    width: 150px;
  }
}

@media (max-width: 480px) {
  /* .global-inner-sec {
    margin: 0 31px;
  } */

  .top-header-sec {
    position: relative;
    width: 100%;
  }
  .top-fixed {
    margin-top: 0px;
  }

  .inci-sour-drop {
    margin-top: 15px;
  }

  .table-head-sec {
    display: block;
    width: 68%;
    max-width: 430px;
  }

  .colum-box {
    padding: 4px 1px;
    margin-left: 0px;
  }

  .right-side-colm {
    display: block;
    line-height: 42px;
  }
  .foot-sec-md {
    display: block;
    text-align: start;
  }
  .foot-sec-md1 {
    display: block;
    text-align: start;
  }
  .md-toggle-btn {
    margin-bottom: 11px;
  }
  .md-toggle-btn1 {
    margin-bottom: 11px;
  }

  .top-bag-image {
    height: 351px;
  }
  .adv-search-row .search-container form.example button {
    width: 17%;
  }

  /* .global-inner-sec h3 {
    font-size: 23px;
  } */
  .comp-loc {
    grid-template-columns: auto;
  }

  .grid-sec-source {
    grid-template-columns: 1fr 1fr;
  }

  .supply-chain-sec {
    padding: 30% 0px 0px 0px;
  }

  .chain-logo h1 {
    font-size: 33px;
  }

  /* form.example.supply-chan-search input {
    font-size: 9px;
  } */

  .us-btn.mt-5 {
    text-align: start;
  }

  .delete-sec {
    display: block;
  }

  .us-btn {
    margin-top: 18px;
  }

  .profile-sec {
    text-align: center;
  }

  .proname {
    text-align: center;
  }

  .notification-text-up {
    display: block;
  }

  .mail-info-box {
    display: block;
  }

  .info-update {
    padding: 15px 11px;
  }

  .cust-mize-mail {
    padding: 10px;
  }

  .envier-collap span.ant-collapse-header-text {
    font-size: 13px;
  }

  .reset-btns {
    display: block;
  }

  .text-rest {
    margin-bottom: 17px;
  }

  .pop-heading {
    padding-left: 1px;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 109px;
  }
  button.rest-btn1.del-red.center-btn {
    margin-right: 109px;
  }
}

@media (max-width: 420px) {
  .apple-logo {
    left: -1px;
  }
  .page-num {
    justify-content: start;
    margin: 10px 0px;
  }

  .page-nation :where(.css-dev-only-do-not-override-yp8pcc).ant-pagination {
    display: block;
  }

  span.inc-link {
    margin-right: 10px;
  }
  .margin-both-side {
    padding: 0 18px;
  }

  .dashbored-head {
    /* padding: 13px 20px 0px 30px; */
  }
  /* .global-inner-sec {
    margin: 0 8%;
  } */
  .fog-pass {
    display: block;
    text-align: center;
  }

  .custom-checkbox {
    justify-content: center;
  }

  .custom-checkbox {
    justify-content: center;
    padding-bottom: 10px;
  }

  .top-heading h2 {
    font-size: 22px;
  }

  form.example button {
    width: 16%;
  }

  form.example input[type="text"] {
    font-size: 11px;
  }

  .inciden-box {
    grid-template-columns: auto;
    gap: 44px;
  }

  .total-box {
    text-align: center;
  }

  .new-box {
    text-align: center;
  }

  .seve-main-boxes {
    grid-template-columns: auto;
  }

  .sev-mid {
    display: block;
  }

  .mager {
    margin-top: 10px;
    width: 159px;
  }

  .main-source-div {
    display: block;
  }

  .mssage-delete {
    display: block;
  }

  .del-all-msg h5 {
    margin-top: 12px;
  }

  .new-text-up p {
    padding-left: 2px;
  }

  .new-feat-up {
    display: block;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 147px;
  }

  span.lie-link {
    margin-left: 0px;
  }

  .topp-div {
    display: block;
  }

  .downl-btn {
    text-align: end;
    margin-top: 12px;
  }

  .right-side-colm {
    display: block;
  }

  .breadcrmbs {
    padding-left: 15px;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 80px;
  }
  button.rest-btn1.del-red.center-btn {
    margin-right: 80px;
  }
  .esg-heading {
    display: block;
  }
  .med-risk-cont {
    width: 100%;
    max-width: 150px;
    display: flex;
    align-items: center;
    margin-top: 7px;
  }
  .custom-slider.r-g-margin {
    width: 72%;
  }
}

@media (max-width: 375px) {
  .margin-both-side {
    padding: 0 2px;
  }

  .page-title h5 {
    font-size: 23px;
  }
  .apple-logo {
    gap: 3px;
  }

  .page-title p {
    font-size: 14px;
  }
  .forgot-link {
    display: block;
    text-align: center;
  }

  .forgot-link a {
    display: block;
    padding-top: 10px;
  }

  .top-heading h2 {
    font-size: 19px;
  }

  form.example input[type="text"] {
    font-size: 9px;
  }

  .companies-rate {
    padding: 10px;
  }

  .main-layout {
    padding: 0px 10px;
  }

  .infohover {
    right: 13px;
    top: 38px;
  }

  .grid-sec-source {
    grid-template-columns: 1fr;
  }

  .all-grid-sys {
    grid-template-columns: auto;
  }

  .supp-grid-sys {
    text-align: center;
  }

  .grid-comp {
    text-align: center;
  }

  .risk-leve {
    display: block;
    max-width: 80px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 133px;
  }

  .see-all-noti {
    display: block;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 50px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 50px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 148px;
  }

  .see-all-noti {
    display: block;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 50px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 50px;
  }

  .plus-add-btn button {
    padding: 4px 2px;
  }
}
@media (max-width: 1275px) {
  .table-box.incident {
    overflow: auto;
    white-space: nowrap;
  }
  .search-modal-dgn-hv {
    width: 45%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}
/* @media (min-width: 1600px) {
  .container{
      max-width: 1120px;
  }
} */
@media (max-width: 1668px) {
  .search-modal-dgn-hv {
    width: 60%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}
@media (max-width: 1440px) {
  .search-modal-dgn-hv {
    width: 55%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }

  .foot-sec {
    width: 74%;
  }
  .left-side-padding {
    padding-left: 70px;
  }

  .ant-layout-sider-children {
    width: 200px;
  }
  .side-padding.sodebar-left-padding {
    padding-left: 200px;
  }

  .email-message-ex p {
    font-size: 12px;
  }
  .up-date-text p {
    font-size: 12px;
  }
}
@media (max-width: 1218px) {
  .search-modal-dgn-hv {
    width: 45%;
    background-color: #fff;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}

@media (max-width: 1200px) {
  /* ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    margin-bottom: 28px;
} */

  /* form.example input[type="text"] {
    font-size: 9px;
  } */

  .table-box.incident {
    overflow: auto;
    white-space: nowrap;
  }

  .margin-both-side {
    padding: 0 32px;
  }

  .adv-search-row .search-container form.example button {
    width: 12%;
  }

  .apple-pic {
    margin: 18px 0px;
  }

  .apple-pic img {
    width: 89%;
    height: 309px;
    border-radius: 8px;
    object-fit: cover;
    object-position: 46% 29%;
  }

  .comp-name-tex h5 {
    font-size: 11px;
  }

  .esg-cate-name {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
  }
  .search-modal-dgn-hv {
    width: 45%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }

  .comp-loc {
    display: grid;
    grid-template-columns: auto auto;
  }

  .containersize {
    width: 80%;
  }

  .page-size {
    padding: 20px 58px;
  }

  .subscribe {
    gap: 10px;
  }

  .pro-page-bg {
    padding-left: 133px;
  }

  .table-box.incident.collection table tr th {
    border-bottom: 1px solid #ced2e0;
    padding: 10px 12px;
  }

  .tab-active {
    width: 100%;
    max-width: 205px;
  }
}

@media (max-width: 1100px) {
  .inciden-box {
    gap: 47px;
  }

  /* .hone-left-side {
    width: 208px;
  } */

  .empty-watchlist-page {
    display: block;
    padding: 9px 10px;
  }

  .email-up {
    padding: 17px 13px;
    display: block;
  }
  .pro-page-bg {
    padding-left: 75px;
  }

  .table-box.incident.collection {
    overflow: scroll;
    white-space: nowrap;
  }

  .table-box.incident table tr td {
    padding: 12px 19px;
  }
}

@media (max-width: 992px) {
  .dashbored-head {
    /* padding: 2px 99px 0px 105px; */
  }

  .apple-logo {
    left: 159px;
  }

  /* .hone-left-side {
    position: relative;
  } */

  .serch-bar-in input {
    width: 189px;
  }
  /* .topp-div {
    display: block;
} */

  .page-nation {
    display: block;
    margin-top: 26px;
  }
  .page-num {
    justify-content: center;
    margin: 10px 0px;
  }

  .add-to-withc {
    margin-top: 27px;
  }

  .custom-slider {
    width: 89%;
    margin: auto;
    margin-top: 15px;
  }
  .slider-low {
    display: block;
  }

  .low-text-temp p {
    top: -2px;
  }

  .home-left-list {
    width: 100%;
    max-width: 295px;
  }

  .model-tabs
    :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin: 0px 13px 0 7px;
    padding: 0 10px;
  }

  .model-tabs1
    :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
    .ant-tabs-tab
    + .ant-tabs-tab {
    margin: 0px 13px 0 7px;
    padding: 0 10px;
  }

  .advanced-search-modal .ant-modal-content {
    /* height: auto !important; */
    margin: 0 20px;
  }

  .advanced-search-modal1 .ant-modal-content {
    /* height: auto !important; */
    margin: 0 20px;
  }

  /* .global-inner-sec p {
    line-height: 16px;
  } */
  .adv-sech-btn-top {
    margin-top: 8px;
  }
  .m-watch-drop {
    margin-bottom: 8px;
  }

  .top-bag-image {
    padding-top: 22px;
  }

  /* .global-inner-sec p {
    font-size: 11px;
    padding-top: 1px;
  } */

  .adv-search-row {
    display: block;
  }

  .inciden-box {
    gap: 25px;
  }

  form.example button {
    width: 9%;
  }

  .total-box h5 {
    font-size: 18px;
  }

  .new-box p {
    font-size: 14px;
  }

  .bot-padding {
    margin-bottom: 20px;
  }

  .esg-cate-name {
    grid-template-columns: auto auto auto;
    gap: 20px;
    display: grid;
  }

  .comp-name-tex h5 {
    font-size: 13px;
  }

  .left-details-side {
    margin-left: 0px;
  }

  .top-high-ris {
    grid-template-columns: 1fr 1fr;
  }

  .grid-container-accor {
    grid-template-columns: 1fr 1fr;
  }

  .page-size {
    padding: 20px 60px;
  }

  .supply-chain-sec {
    padding: 23% 0px 0px 0px;
  }

  .chain-des p {
    font-size: 14px;
    line-height: 24px;
  }

  .indexing p {
    margin-left: 21%;
  }

  .table-head-sec {
    display: flex;
    align-items: center;
    width: 40%;
    max-width: 430px;
    justify-content: space-between;
  }

  .subscribe {
    grid-template-columns: auto auto;
  }

  .pro-page-bg {
    padding-left: 55px;
  }

  .serch-bar-in {
    display: flex;
    margin-right: 4px;
  }
  .search-modal-dgn-hv {
    width: 85%;
    background-color: white;
    z-index: 1000;
    position: absolute;
    height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 0px 8px 0px;
    margin-top: 3px;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .pie-chart {
    display: flex;
    justify-content: center;
  }

  .coll-table :where(.css-dev-only-do-not-override-yp8pcc).ant-table-wrapper {
    white-space: nowrap;
    overflow: scroll;
  }
  .topp-div {
    display: block;
  }
  .table-head-sec {
    margin: 10px 0px;
  }

  .compnay-grid-cont {
    grid-template-columns: auto;
  }

  .inciden-box {
    grid-template-columns: auto auto;
    gap: 31px;
  }

  .seve-main-boxes {
    gap: 34px;
  }

  .comp-loc {
    grid-template-columns: auto auto;
  }

  .esg-cate-name {
    grid-template-columns: auto auto;
  }

  .all-grid-sys {
    grid-template-columns: auto auto;
    gap: 33px;
  }

  .subscribe {
    grid-template-columns: auto;
  }

  .pro-page-bg {
    padding-left: 25px;
  }

  .flex-pro {
    gap: 12px;
  }
}

@media (max-width: 667px) {
  .top-section {
    display: block;
  }

  .top-heading {
    margin-bottom: 18px;
  }

  form.example button {
    width: 14%;
  }

  .seve-main-boxes {
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .chain-des p {
    font-size: 13px;
    line-height: 22px;
  }

  /* .side-padding.sodebar-left-padding {
    padding-left: 150px;
  } */

  /* .side-padding.sodebar-left-padding {
    padding-left: 138px;
  } */
}

@media (max-width: 600px) {
  .apple-logo {
    left: 6px;
    margin-top: -69px;
    position: relative;
  }

  .top-margin-comp {
    margin-top: 0px;
  }

  .top-space {
    margin-top: 0px;
  }

  .dashbored-head {
    position: relative;
    width: 100%;
  }

  .top-header-sec {
    position: relative;
    width: 100%;
  }
  .top-fixed {
    margin-top: 0px;
  }

  .dashbored-head {
    /* padding: 13px 35px 0px 35px; */
  }
  .ellips {
    margin-top: 10px;
  }

  .caot-sec {
    display: block;
  }
  .text-fild input {
    width: 100%;
  }

  .text-fild input {
    width: 100%;
  }

  .text-fild button {
    margin-left: 0px;
    margin-top: 8px;
  }

  .m-watch-drop {
    margin-top: 8px;
  }

  .m-watch-drop {
    margin-top: 8px;
  }

  /* .global-inner-sec h3 {
    font-size: 26px;
  } */

  /* .global-inner-sec {
    padding: 24px 24px 24px 24px;
  } */

  .recent-table {
    overflow: scroll;
    padding-bottom: 10px;
  }

  .tab-top {
    display: block;
  }

  .grid-container-accor {
    grid-template-columns: 1fr;
  }

  .top-main-heading-text {
    display: block;
  }

  .apple-num {
    width: 60px;
    margin-top: 23px;
  }

  .apple-pic img {
    height: 214px;
  }

  .esg-cate-name {
    grid-template-columns: auto;
  }

  .mentio-tweet {
    display: block;
  }

  .user-steve {
    display: block;
  }

  .heading-ap {
    display: block;
  }

  .main-top-apple {
    margin-bottom: 17px;
  }

  .top-high-ris {
    grid-template-columns: 1fr;
  }

  .grid-container-accor {
    grid-template-columns: 1fr;
  }

  .apply-btn {
    margin-top: 23px;
    text-align: start;
  }

  .page-size {
    padding: 20px 31px;
  }

  .phone-view {
    display: block;
  }

  .web-view {
    display: none;
  }

  form.example.supply-chan-search input {
    font-size: 12px;
  }

  .indexing p {
    margin-left: 18%;
  }

  .supply-chain-sec {
    padding: 27% 0px 0px 0px;
  }

  .flex-pro {
    display: block;
  }

  .reset-btns {
    justify-content: start;
  }

  .serch-bar-in input {
    width: 150px;
  }
}

@media (max-width: 480px) {
  /* .global-inner-sec {
    margin: 0 31px;
  } */

  .top-header-sec {
    position: relative;
    width: 100%;
  }
  .top-fixed {
    margin-top: 0px;
  }

  .inci-sour-drop {
    margin-top: 15px;
  }

  .table-head-sec {
    display: block;
    width: 68%;
    max-width: 430px;
  }

  .colum-box {
    padding: 4px 1px;
    margin-left: 0px;
  }

  .right-side-colm {
    display: block;
    line-height: 42px;
  }
  .foot-sec-md {
    display: block;
    text-align: start;
  }
  .foot-sec-md1 {
    display: block;
    text-align: start;
  }
  .md-toggle-btn {
    margin-bottom: 11px;
  }
  .md-toggle-btn1 {
    margin-bottom: 11px;
  }

  .top-bag-image {
    height: 351px;
  }
  .adv-search-row .search-container form.example button {
    width: 17%;
  }

  /* .global-inner-sec h3 {
    font-size: 23px;
  } */
  .comp-loc {
    grid-template-columns: auto;
  }

  .grid-sec-source {
    grid-template-columns: 1fr 1fr;
  }

  .supply-chain-sec {
    padding: 30% 0px 0px 0px;
  }

  .chain-logo h1 {
    font-size: 33px;
  }

  /* form.example.supply-chan-search input {
    font-size: 9px;
  } */

  .us-btn.mt-5 {
    text-align: start;
  }

  .delete-sec {
    display: block;
  }

  .us-btn {
    margin-top: 18px;
  }

  .profile-sec {
    text-align: center;
  }

  .proname {
    text-align: center;
  }

  .notification-text-up {
    display: block;
  }

  .mail-info-box {
    display: block;
  }

  .info-update {
    padding: 15px 11px;
  }

  .cust-mize-mail {
    padding: 10px;
  }

  .envier-collap span.ant-collapse-header-text {
    font-size: 13px;
  }

  .reset-btns {
    display: block;
  }

  .text-rest {
    margin-bottom: 17px;
  }

  .pop-heading {
    padding-left: 1px;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 109px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 109px;
  }
}

@media (max-width: 420px) {
  .apple-logo {
    left: -1px;
  }
  .page-num {
    justify-content: start;
    margin: 10px 0px;
  }

  .page-nation :where(.css-dev-only-do-not-override-yp8pcc).ant-pagination {
    display: block;
  }

  span.inc-link {
    margin-right: 10px;
  }
  .margin-both-side {
    padding: 0 18px;
  }

  .dashbored-head {
    /* padding: 13px 20px 0px 30px; */
  }
  /* .global-inner-sec {
    margin: 0 8%;
  } */
  .fog-pass {
    display: block;
    text-align: center;
  }

  .custom-checkbox {
    justify-content: center;
  }

  .custom-checkbox {
    justify-content: center;
    padding-bottom: 10px;
  }

  .top-heading h2 {
    font-size: 22px;
  }

  form.example button {
    width: 16%;
  }

  form.example input[type="text"] {
    font-size: 11px;
  }

  .inciden-box {
    grid-template-columns: auto;
    gap: 44px;
  }

  .total-box {
    text-align: center;
  }

  .new-box {
    text-align: center;
  }

  .seve-main-boxes {
    grid-template-columns: auto;
  }

  .sev-mid {
    display: block;
  }

  .mager {
    margin-top: 10px;
    width: 159px;
  }

  .main-source-div {
    display: block;
  }

  .mssage-delete {
    display: block;
  }

  .del-all-msg h5 {
    margin-top: 12px;
  }

  .new-text-up p {
    padding-left: 2px;
  }

  .new-feat-up {
    display: block;
  }

  /* .side-padding.sodebar-left-padding {
    padding-left: 147px;
  } */

  span.lie-link {
    margin-left: 0px;
  }

  .topp-div {
    display: block;
  }

  .downl-btn {
    text-align: end;
    margin-top: 12px;
  }

  .right-side-colm {
    display: block;
  }

  .breadcrmbs {
    padding-left: 15px;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 80px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 80px;
  }

  .esg-heading {
    display: block;
  }
  .med-risk-cont {
    width: 100%;
    max-width: 150px;
    display: flex;
    align-items: center;
    margin-top: 7px;
  }
  .custom-slider.r-g-margin {
    width: 72%;
  }
}

@media (max-width: 375px) {
  .margin-both-side {
    padding: 0 2px;
  }

  .page-title h5 {
    font-size: 23px;
  }
  .apple-logo {
    gap: 3px;
  }

  .page-title p {
    font-size: 14px;
  }
  .forgot-link {
    display: block;
    text-align: center;
  }

  .forgot-link a {
    display: block;
    padding-top: 10px;
  }

  .top-heading h2 {
    font-size: 19px;
  }

  form.example input[type="text"] {
    font-size: 9px;
  }

  .companies-rate {
    padding: 10px;
  }

  .main-layout {
    padding: 0px 10px;
  }

  .infohover {
    right: 13px;
    top: 38px;
  }

  .grid-sec-source {
    grid-template-columns: 1fr;
  }

  .all-grid-sys {
    grid-template-columns: auto;
  }

  .supp-grid-sys {
    text-align: center;
  }

  .grid-comp {
    text-align: center;
  }

  .risk-leve {
    display: block;
    max-width: 80px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 133px;
  }

  .see-all-noti {
    display: block;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 50px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 50px;
  }

  .side-padding.sodebar-left-padding {
    padding-left: 148px;
  }

  .see-all-noti {
    display: block;
  }

  button.rest-btn.del-red.center-btn {
    margin-right: 50px;
  }

  button.rest-btn1.del-red.center-btn {
    margin-right: 50px;
  }

  .plus-add-btn button {
    padding: 4px 2px;
  }
}
