.container {
    width: 28.3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imageStyle {
    width: 116px;
    height: 116px;
}

.headerSection {
    margin: 1vw 0 .8vw 0;
    font-size: 15px;
    color: #07132F;
    font-weight: 600;
}

.paragraphSection {
    text-align: center;
    color: var(--Grays-400, #676A73);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.cge {
    background-color: red;
}

.btn {
    background: #0195ce;
    border-radius: 4px;
    border: none;
    outline: none;
    align-items: center;
    /* padding: 8px 15px; */
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    height: 36px;
    width: 173px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }

  .container1 {
    width: 28.3%;
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}