@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("../Themes/colorContant.css");

body {
  margin: 0px;
  padding: 0px;
  font-family: "Inter" !important;
}

a,
a:hover,
a:focus {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
}

.page-header {
  width: 100%;
  background-image: url("../Assets//Images/bg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  max-height: 1000px;
  display: flex !important;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  align-items: center;
}

.side-padding {
  padding-left: 6px;
  width: 100%;
  overflow: auto;
}

.list {
  padding-left: 0px;
}

.left-right-mg {
  margin: auto;
  width: 100%;
  max-width: 650px;
}

.main-div {
  background: #ffffff;
  border-radius: 8px;
  margin: 0 32px;
}

.login-form h3 {
  margin: 24px 0px 24px 0px;
  font-size: 21px;
  font-weight: 400;
}

.user input {
  width: 91%;
  font-size: 0.8rem;
  border-radius: 10rem;
  padding: 15px;
  margin-bottom: 20px;
  outline: none;
  font-weight: 400;
  line-height: 0.5;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #42453f;
}

.login-link a {
  color: white;
}

.forgot-link {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #676a73;
  justify-content: center;
  padding-bottom: 32px;
}

.forgot-link a {
  color: #42453f;
}

.login-page-logo {
  background: linear-gradient(90deg, #0466c8 0%, #038bc8 56.83%, #02b1c7 100%);
  border-radius: 8px 8px 0px 0px;
}

.login-page-logo {
  background: linear-gradient(90deg, #0466c8 0%, #038bc8 56.83%, #02b1c7 100%);
  border-radius: 8px 8px 0px 0px;
  text-align: start;
  padding: 16px 24px;
  cursor: pointer;
}

.log-side {
  padding: 0px 35px;
}

.login-text h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #0281b6;
  margin: 36px 0;
  /* padding-left: 8px; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.with-go span {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  color: #0281b6;
  margin-left: 15px;
}

.with-go {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0195ce;
  border-radius: 6px;
  padding: 13px 0;
  margin-bottom: 30px;
}

.input-text input {
  width: 100%;
  padding: 15px;
  outline: none;
  margin-top: 11px;
  color: #07132f;
  font-size: 14px;
  border: 1px solid #ced2e0;
  border-radius: 6px;
}

.input-text label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #07132f;
  padding-left: 8px;
}

.input-text {
  margin-bottom: 32px;
  /* position: relative; */
}

.login-link button {
  background: var(--blueButton);
  color: var(--whiteText);
  border-radius: 6px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  width: 100%;
  margin: auto;
  display: inline-block;
  margin-bottom: 31px;
  font-size: 16px;
  font-weight: 600;
  border: 0;
}

.forgot-link a {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #0195ce;
  margin-left: 5px;
}

.fog-pass {
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
  align-items: center;
}

.custom-checkbox label {
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
  margin-left: 7px;
}

.fog-link a {
  font-weight: 600;
  font-size: 16px;
  color: #0195ce;
  text-decoration: none;
}

.custom-checkbox input {
  accent-color: #0195ce;
  width: 13px;
  height: 15px;
}

hr {
  background: #ced2e0;
  opacity: 0.3;
  margin: 31px 0;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.custom-checkbox-top {
  display: flex;
  align-items: center;
  gap: 10px;
}

.custom-checkbox-top:hover {
  background-color: #f7f8fa;
}

/* signup page  css*/

.term-cond span {
  font-weight: 400;
  font-size: 13px;
  color: #676a73;
}

.term-cond a {
  font-weight: 400;
  font-size: 13px;
  color: #0195ce;
  text-decoration: none;
}

.term-cond {
  margin-bottom: 33px;
}

.main-input-sec {
  height: 320px;
  overflow-y: scroll;
  padding: 0 5px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: rgba(0, 0, 0, 0.25) !important;
}

/* forgot password css */

.em-add p {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #07132f;
  margin-bottom: 25px;
  padding-left: 10px;
}

.login-link.conf-btn a {
  width: 100%;
  margin-bottom: 44px;
}

/* confirmemail page css */

.login-text.new-col h5 {
  color: #1f427d;
}

.email-envelop {
  text-align: center;
  margin: 37px 0;
}

.email-res span {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  color: #07132f;
}

.email-res a {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #0195ce !important;
  text-decoration: underline !important;
}

.email-res {
  margin-bottom: 37px;
}

/* side bar css  */

.side-background {
  max-width: 70px;
  min-width: 70px;
  width: 70px;
  background: #eff1f7;
  border-right: 1px solid #ced2e0;
  position: fixed;
  z-index: 3;
}

.inner-sidebar-sec {
  padding: 34px 10px;
  overflow: auto;
}

.side-logo {
  text-align: center;
  margin-bottom: 74px;
}

.side-navbar ul li a {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #9a9ca2;
  text-decoration: none;
}

.side-navbar ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
  text-align: center;
}

.side-navbar ul li {
  margin-bottom: 30px;
  line-height: 13px;
}

.side-dott-nav ul {
  text-decoration: none;
  list-style: none;
  padding: 0;
  text-align: center;
}

.side-dott-nav ul li {
  margin-bottom: 15px;
}

.combo-box .ant-select.css-dev-only-do-not-override-yp8pcc.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

.nav-sec {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  height: calc(100vh - 183px);
}

li.active-nav:before {
  content: "";
  width: 5px;
  background: #0195ce;
  border-radius: 100px 0px 0px 100px;
  right: -10px;
  position: absolute;
  top: -5px;
  height: 52px;
}

li.active-nav {
  position: relative;
  transition-duration: 0.5s;
}

.side-dott-nav ul li.active-nav a img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

/* home page css */

.top-checkbox .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px 11px !important;
}

.top-checkbox .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 6px !important;
}

.top-checkbox .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #4e515c !important;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-heading h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #07132f;
  margin: 0px;
}

.main-layout {
  width: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: 0;
  margin-bottom: 34px;
  padding: 0 20px;
}

form.example {
  display: flex;
  justify-content: space-between;
}

div.example {
  display: flex;
  justify-content: space-between;
}

.top-section {
  margin-top: 35px;
}

.search-text-sec p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3d4353;
}

.search-text-sec p b {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07132f;
}

.inciden-box {
  display: grid;
  align-items: flex-end;
  grid-template-columns: auto auto auto auto;
  gap: 16px;
}

.total-box h5 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #80838b;
  margin-bottom: 15px;
}

.list a:hover span {
  color: #0195ce !important;
}

.list a:hover {
  color: #0195ce !important;
}

.total-box h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 36px;
  line-height: 56px;
  color: #2d303a;
}

.total-box {
  min-width: 176px;
  min-height: 132px;
  padding: 16px;

  /* border-left: 3px solid #0195ce; */
  border-radius: 8px;
  border: 1px solid #e4e6ed;
  text-align: center;
  cursor: pointer;
}

.new-box p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #80838b;
  margin: 10px 0;
}

.new-box h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #07132f;
}

.new-box {
  cursor: pointer;
  min-width: 176px;
  min-height: 132px;
  /* padding: 12px 9px; */
  padding: 16px;
  border: 1px solid #e4e6ed;
  border-radius: 8px;
  text-align: center;
}

.seve-main-boxes {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 17px;
}

.fir-box p {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4e515c;
  margin: 4px 0 6px 0;
}

.fir-box h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #07132f;
}

.hover-page {
  height: 24px;
  width: 24px;
}

.hover-page:hover~.infohover-home {
  display: block !important;
}

.infohover-home.desh h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07132f;
  margin: 3px 0px;
}

.infohover-home.desh p {
  line-height: 21px;
}

.infohover-home.desh {
  top: 31px;
  left: -4px;
  z-index: 33;
}

.infohover-home {
  background: #f7f8fa;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 196px;
  position: absolute;
  position: absolute;
  right: 13px;
  top: 92px;
  z-index: 999;
  display: none;
}

.infohover-home p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.fir-box {
  width: 240px;
  min-width: 240px;
  text-align: center;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 12px;
  position: relative;
  cursor: pointer;
}

.fir-box p span {
  margin-left: 6px;
}

.fir-box p:nth-child(3) {
  font-weight: 400;
  font-size: 13px;
  color: #4e515c;
  margin: 2px 0 !important;
}

h5.gap {
  margin-top: 18px;
}

form.example input[type="text"] {
  padding: 13px;
  float: left;
  width: 95%;
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 8px 0px 0px 8px;
  background: #ffffff;
  outline: none;
  font-weight: 400;
  height: 40px;
  font-size: 13px;
  color: #80838b;
}

div.example input[type="text"] {
  padding: 13px;
  float: left;
  width: 95%;
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 8px 0px 0px 8px;
  background: #ffffff;
  outline: none;
  font-weight: 400;
  height: 40px;
  font-size: 13px;
  color: #80838b;
}

form.example button {
  float: left;
  width: 5%;
  padding: 10px;
  color: white;
  font-size: 17px;

  cursor: pointer;
  background: #f7f8fa;
  border: 1px solid #ced2e0;
  border-radius: 0px 8px 8px 0px;
}

div.example button {
  float: left;
  width: 5%;
  padding: 10px;
  color: white;
  font-size: 17px;
  height: 47px;
  cursor: pointer;
  background: #f7f8fa;
  border: 1px solid #ced2e0;
  border-radius: 0px 8px 8px 0px;
}

div.example h1 {
  float: left;
  min-width: 5%;
  padding: 10px;
  color: white;
  font-size: 17px;

  cursor: pointer;
  background: #f7f8fa;
  border: 1px solid #ced2e0;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  justify-content: center;
}

form.example::after {
  content: "";
  clear: both;
  display: table;
}

div.example::after {
  content: "";
  clear: both;
  display: table;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

.accordion p.MuiTypography-root.MuiTypography-body1 {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  text-align: right;
  color: #0195ce;
}

.accordion .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px;
}

.grid-container-accor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 29px;
  width: 100%;
}

.grid-container-accor .grid-inner-accor {
  /* background-color: rgba(255, 255, 255, 0.8); */
  font-size: 30px;
  text-align: start;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 0px 0px !important;
}

.text-comp h6 {
  margin: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 4px;
  color: #07132f;
  margin-left: 7px;
}

.combo-box .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  background: #ffffff !important;
  /* Grays/50 */

  border: 1px solid #e7e9f0 !important;
  border-radius: 6px !important;
}

.combo-box .css-1wuilmg-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0.5px 4px 4.5px 5px !important;
}

.combo-box .css-1wuilmg-MuiAutocomplete-root {
  width: 100%;
  max-width: 390px;
}

.top-head-ser h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #07132f;
  margin: 0px;
}

.top-head-ser {
  background: #f7f8fa;
  padding: 11px 20px;
}

.text-list .list a {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  cursor: pointer;
  text-decoration: none;
  transition-duration: 0.5s;
}

.text-list .list a span {
  transition-duration: 0.5s;
  color: #b70000;
}

.list>a:hover~img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(138%) !important;
}

/* .text-list .list a span:nth-child(3) {
  color: #676a73;
  transition-duration: 0.5s;
} */
.text-list .list a span:nth-child(3) {
  /*des change */
  transition-duration: 0.5s;
  color: var(--Grays-400, #676a73);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text-list .list {
  padding: 10px 10px;
  list-style: none;
}

.text-list .list:hover {
  color: #0281b6;
}

.text-list .list {
  padding: 0px;
  margin: 14px 0;
}

.text-list .list1 {
  display: flex;
}

.recent-ser {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  max-width: 752px;
  width: 752px;
  min-width: 752px;
}

.text-list {
  padding: 0px 21px;
}

.tab-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  border: 0px, 0px, 1px, 0px;
  gap: 8px;
  border-bottom: 1px solid #e4e6ed;
}

.tab-top1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px 14px 24px;
  border: 0px, 0px, 1px, 0px;
  gap: 8px;
  border-bottom: 1px solid #e4e6ed;
}

.backgroundColor {
  background: #f7f8fa;
}

.left-text h2 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d303a;
}

.left-text h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4e515c;
  margin: 0;
}

.righ-side span {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #0195ce !important;
  text-decoration: none;
}

.link_btn {
  display: inline-block;
  cursor: pointer;
}

.link_btn span {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #0195ce !important;
  text-decoration: none;
}

.loderHandlerText {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  text-decoration: none;
  color: rgb(182, 180, 180) !important;
}

.righ-side a {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #0195ce !important;
  text-decoration: none;
  display: flex;
  text-align: center;
}

.righ-side span span {
  margin-left: 10px;
}

.righ-side a span {
  margin-left: 10px;
}

.recent-table {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
}

.table-box {
  overflow: auto;
}

.table-box table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table-box table td,
th {
  text-align: left;
  padding: 8px;
}

.table-box table tr:nth-child(odd) {
  background: #f5f6fa;
}

.table-box table td,
th {
  text-align: left;
  padding: 8px 0px;
  font-weight: 400;
  font-size: 12px;
  color: #3d4353;
}

/* .table-box tr td span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #fff !important;
} */

td.rec-previ-ps span {
  font-weight: 500;
  font-size: 11px;
  color: #9a9ca2;
  margin-right: 6px;
}

.list-impo {
  width: 36px;
  background: #b70000;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
  color: #fff;
}

.list-impo.orange {
  background: #f28c28;
}

.list-impo.gray {
  background: #9a9ca2;
}

.list-impo.yellow {
  background: yellow;
}

.table-box table tr td {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #07132f;
}

.ant-table-cell.company-supplyChain:last-child {
  padding: 5px 11px !important;
}

.ant-table-cell.company-supplyChain {
  padding: 5px 6px !important;
}

.table-box table tr {
  padding: 10px;
}

.table-box table tr {
  height: 48px;
}

th.first-ps {
  padding-left: 34px;
}

.table-box tr td p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* display: flex; */
  /* align-items: center; */
  text-align: right;
  color: #4e515c;
  text-align: start;
  margin: 0;
}

.righ-side {
  cursor: pointer;
}

.pie-chart {
  width: 100%;
  margin: auto;
  height: 200px;
}

.pie-char-main-sec {
  background: #f7f8fa;
  border-radius: 12px;
  max-width: 752px;
  width: 752px;
  min-width: 752px;
  margin-left: 0px;
}

.pie-chart-sec {
  margin: auto;
}

.category {
  padding: 20px;
}

.category h5 {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3d4353;
}

.cat-name {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 0 27px;
}

.colors h5 {
  font-family: "inter";
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #07132f;
  margin: 0px;
}

.colors h5 img {
  margin-right: 7px;
  width: 12px;
}

.colors p img {
  margin-right: 7px;
  width: 10px;
}

.number p {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #676a73;
  margin: 0;
}

.flx-color-se {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  line-height: 22px;
}

.caot-sec h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2d303a;
}

.line-chart {
  padding: 30px;
  padding-top: 15px;
  width: 100%;
}

.HighLightLayout {
  padding: 24px;
}

.highLightView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.highLightTextView {
  display: flex;
  padding: 0px 8px;
  align-items: flex-start;
  gap: 8px;
  background: #e5f7ff;
}

.highLightText {
  color: #05476f;
  font-size: 13px;
  font-weight: 600;
}

.colors {
  padding: 4px 0;
}

.companies-rate h5 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #07132f;
  margin-bottom: 30px;
}

.with-comp-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
}

.comp p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-bottom: 1px solid #cccfd8;
  color: #4e515c;
  margin: 0;
  padding-bottom: 5px;
}

.incdi p {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #0d0d0e;
  margin: 0;
  border-bottom: 1px solid #cccfd8;
  padding-bottom: 5px;
}

.companies-rate {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 20px;
  max-height: 600px;
  overflow: auto;
}

.comp-name-sec {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.comp-full-name p {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin: 0px;
  color: #07132f;
}

.comp-prc-num p {
  font-weight: 500;
  font-size: 12px;
  color: #5e0000;
  margin: 0px;
}

.comp-full-name p span {
  background: #eff1f7;
  border-radius: 50%;
  padding: 10px 12px;
  margin-right: 5px;
  font-size: 9px;
}

.comp-prc-num {
  width: 100%;
  max-width: 100px;
  background: #fceded;
  border-radius: 5px;
  padding: 5px 7px;
  /* margin-left: -12px; */
}

.download-tabs {
  margin-right: -5px;
}

.most-feq-inc h5 span {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0d0d0e;
}

.most-feq-inc h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #3d4353;
}

.most-feq-inc p {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #07132f;
  margin-top: 2px;

  display: inline-block;
  width: 350px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.most-sec {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 30px;
  height: 100%;
}

.hr-bar-chart {
  height: 100%;
  margin-top: 20px;
}

.most-sec-over {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
}

.md-toggle-btn .ant-switch.ant-switch-checked {
  background: #ced2e0 !important;
}

.caot-sec {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.caot-sec1 {
  background: #ffffff;
  border-bottom: 1px solid #e7e9f0;
  padding: 20px;
  padding: 20px;
  background: #f7f8fa;
  border-bottom: 1px solid #e7e9f0;
}

.ellips {
  align-items: center;
  display: flex;
  gap: 13px;
}

.ellips p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #4e515c;
}

.comp-prc-num.two {
  width: 100%;
  max-width: 89px;
  margin-right: 11px;
}

.comp-prc-num.three {
  width: 100%;
  max-width: 82px;
  margin-right: 18px;
}

.comp-prc-num.four {
  width: 100%;
  max-width: 75px;
  margin-right: 25px;
}

.comp-prc-num.five {
  width: 100%;
  max-width: 65px;
  margin-right: 35px;
}

.comp-prc-num.six {
  width: 100%;
  max-width: 55px;
  margin-right: 45px;
}

.comp-prc-num.seven {
  width: 100%;
  max-width: 45px;
  margin-right: 55px;
}

.comp-prc-num.eight {
  width: 100%;
  max-width: 35px;
  margin-right: 65px;
}

.comp-prc-num.nine {
  width: 100%;
  max-width: 25px;
  margin-right: 75px;
}

.comp-prc-num.ten {
  width: 100%;
  max-width: 21px;
  margin-right: 77px;
}

/* inscidentsList css start */

.main-layout.table-layout {
  width: 100%;
  max-width: 100%;
  margin: auto;
  /* min-height: 90vh; */
  margin-top: 0;
}

.bg-sec {
  background: #f7f8fa;
  /* Grays/50 */

  border: 1px solid #e7e9f0;
  border-radius: 8px;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
}

.cp-sec {
  width: 100%;
  margin-top: 64px;
}

.marginAuto {
  margin: auto !important;
}

.back-link a {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  /* display: flex; */
  /* align-items: center; */
  color: #4e515c;
  margin-right: 26px;
  text-decoration: none;
}

.linc-link a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  /* align-items: center; */
  color: #07132f;
  text-decoration: none;
}

/* .css-1wuw8dw-MuiBreadcrumbs-separator {
  display: none !important;
} */

.linc-link {
  border-right: 1px solid #ced2e0;
  padding-right: 14px;
  margin-right: 12px;
}

.list-link a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #676a73;
  text-decoration: none;
}

.back-link a img {
  margin-right: 7px;
}

.table-page-sec p {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #676a73;
  margin: 0px;
  width: max-content;
}

.table-head-sec {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 421px;
  /* my-change */
  justify-content: space-between;
}

.top-checkbox span.ant-select-selection-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4e515c;
}

.right-side-colm {
  display: flex;
  align-items: center;
  /* gap: 4px; */
}

.topp-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ced2e0;
  border-bottom: 1px solid #ced2e0;
  padding: 10px 0;
}

.serch-bar-in input {
  border: none;
  background: no-repeat;
  outline: none;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #3d4353;
  width: 220px;
  padding-right: 10px;
}

.inci-sour-drop span.ant-select-selection-item {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #3d4353;
}

.inci-sour-drop :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.colum-box {
  border-radius: 4px;
  padding: 4px 10px;
  /* margin-left: 9px; */
}

.serch-bar-in {
  border-right: 1px solid #d9d9d9;
  padding-left: 9px;
}

.table-box.incident table tr th {
  min-width: 83px;
  border-bottom: 1px solid #ced2e0;
  /* padding: 10px 0; */
}

.sn-num {
  display: flex;
}

/* .sn-num p{
    margin-right:5px ;
    color: #9A9CA2 !important;

} */

.table-box.incident table tr td h6 {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: var(--Grays-500, #4e515c);
  margin: 0px;
  padding-left: 8px;
}

.table-txt-sz:hover p {
  color: red !important;
}

.table-box.incident table tr td p {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: var(--Grays-700, #2d303a);
  /* margin: 4px; */
  width: 205px;
  font-family: "Inter";
  white-space: pre-wrap;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

:where(.css-dev-only-do-not-override-12jzuas)[class^="ant-table"] [class^="ant-table"],
:where(.css-dev-only-do-not-override-12jzuas)[class*=" ant-table"] [class^="ant-table"],
:where(.css-dev-only-do-not-override-12jzuas)[class^="ant-table"] [class*=" ant-table"],
:where(.css-dev-only-do-not-override-12jzuas)[class*=" ant-table"] [class*=" ant-table"] {
  box-sizing: border-box;
  font-family: "Inter";
}

.table-txt-sz a {
  color: #07132f !important;
}

.table-txt-sz:hover p {
  color: #0281b6 !important;
}

.table-box.incident table tr td {
  padding: 16px 2px;
}

.table-box.incident table tr:nth-child(even) {
  background: #ffffff;
}

.breadcrmbs span.ant-breadcrumb-link {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #4e515c;
  font-family: "inter";
  /* margin-right: 23px; */
}

/* .breadcrmbs li.ant-breadcrumb-separator {
  display: none;
} */

span.bad-link {
  font-weight: 600;
  font-size: 15px;
  /* line-height: 23px; */
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #4e515c;
  margin-right: 26px;
}

span.main-link {
  color: #9a9ca2;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  line-height: 24px;
  /* 160% */
}

.breadcrmbs :where(.css-dev-only-do-not-override-yp8pcc).ant-breadcrumb a {
  height: 15px !important;
  color: #4e515c;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-breadcrumb a:hover {
  color: #4e515c;
  background-color: transparent;
}

.breadcrmbs :where(.css-dev-only-do-not-override-yp8pcc).ant-breadcrumb ol {
  align-items: center;
}

.backBtnText {
  color: #0195ce;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
}

.pv10ph16 {
  padding: 10px 16px;
}

span.inc-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #07132f;
}

span.lie-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #676a73;
  /* margin-left: 15px;  my change */
}

span.inc-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* color: #07132f; */
  color: var(--Grays-200, #9a9ca2) !important;
  /* padding-right: 13px;
  border-right: 1px solid #ced2e0;  my change*/
}

span.bad-link img {
  margin-right: 7px;
}

.top-info-section p:hover {
  color: #0281b6;
}

.top-info-section p:hover img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(195deg) brightness(100%) contrast(138%) !important;
}

.top-info-section>p:hover~.go-to {
  display: block;
}

.go-to:hover {
  display: block;
}

.go-to h6 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3d4353;
}

.go-to {
  width: 100%;
  max-width: 200px;
  background: #f7f8fa;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 14px;
  position: absolute;
  display: none;
  z-index: 1000;
}

.setting-text span {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #0195ce;
}

.table-txt-sz p:hover {
  color: #0195ce;
}

.row-per span {
  font-weight: 400;
  font-size: 12px;
  /* color: #80838B; */
}

.row-per :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #d2d7e5;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-left: 5px;
}

.row-per .ant-select .ant-select-arrow .anticon {
  font-size: 9px !important;
  color: #9a9ca2 !important;
}

.page-num .ant-pagination .ant-pagination-options {
  display: inline-block;
  margin-inline-start: 16px;
  vertical-align: middle;
  /* display: none; */
}

.page-num {
  display: flex;
  align-items: center;
}

.goto-pg span {
  font-weight: 400;
  font-size: 12px;
  color: #3d4353;
  margin-right: 5px;
}

.pg-number {
  display: flex;
  align-items: center;
}


.jak-option-dropNew {
  width: 100%;
}

.jak-option-dropNew .ant-select-selector {
  height: 40px !important;
  border-radius: 4px !important;

}

.pg-number input {
  width: 40px;
  outline: none;
  border: 1px solid #ced2e0;
  border-radius: 4px 0px 0px 4px;
  padding: 0px 7px;
  font-size: 14px;
}

.pg-number button {
  background: #41afda;
  border-radius: 0px 4px 4px 0px;
  outline: none;
  /* border: 1px solid #CED2E0; */
  color: #fff;
  border: none;
  padding: 1px 9px;
  font-size: 14px;
}

.goto-pg {
  display: flex;
  align-items: center;
}

.page-nation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 31px;
}

.page-nation .ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  border: none;
  background: #d7f0fa;
  border-radius: 4px;
  color: #045b86;
}

.page-nation .ant-pagination {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Grays/300 */

  color: #80838b;
}

.page-nation .ant-pagination .ant-pagination-prev {
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;

  color: #80838b;
}

.page-nation .ant-pagination .ant-pagination-next {
  font-weight: 400;
  font-size: 12px;
  line-height: 35px;

  color: #80838b;
}

.page-nation .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: transparent;
  border: 1px solid #d9d9d9;
  color: #4e515c;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.table-txt-sz a {
  text-decoration: none;
}

.list a img {
  margin-right: 6px;
}

.accordion :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse {
  background-color: transparent !important;
  border: none !important;
  border-bottom: none !important;
  border-radius: 0;
}

.accordion :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse .ant-collapse-content {
  color: rgba(0, 0, 0, 0.88);
  /* background-color: #ffffff; */
  border-top: none !important;
}

.accordion :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.accordion :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #0195ce;
  position: absolute;
  left: 153px;
  rotate: -88deg;
}

.accordion span.ant-collapse-header-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0195ce;
}

.accordion span.ant-select-selection-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #80838b;
}

.accordion :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 37px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.3s;
  transform: rotate(180deg);
}

.check-box {
  display: flex;
  align-items: center;
}

.check-box .ant-select-item-option-content {
  display: flex !important;
  align-items: center !important;
}

.check-box p {
  margin: 0px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.check-box .ant-select-item-option-content input {
  min-width: 14px;
  height: 14px;
  accent-color: #0195ce;
}

.side-navbar ul li.active-nav a {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #0195ce;
}

.side-navbar ul li.active-nav a img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

span.inc-bad-link a {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #9a9ca2 !important;
  /* margin-right: 5px; */
}

span.inc-bad-link {
  margin-right: 5px;
}

span.detail-lie-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2d303a;
}

.apple-face h4 {
  font-weight: 600;
  font-size: 16px;
  color: #0d0d0e;
  margin: 0px;
}

.top-main-heading-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sum-text h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2d303a;
  margin: 0px;
}

.apple-face {
  border-left: 3px solid #0195ce;
  padding-left: 8px;
}

.apple-sum p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
  margin: 0px;
}

.second-text-sec p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.apple-sum p a {
  color: #0195ce;
  text-decoration: none;
}

.second-text-sec p a {
  color: #0195ce;
  text-decoration: none;
}

.comp-loc {
  display: grid;
  grid-template-columns: auto auto auto;
}

.comp-name-tex {
  width: 32%;
}

.comp-name-tex h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #2d303a;
  margin: 0px;
  background: #e5f7ff;
  border-radius: 4px;
  padding: 10px;
  margin-right: 10px;
}

.comp-name-tex h5 img {
  margin-right: 7px;
}

.comp-name-tex ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
}

.comp-name-tex ul {
  margin-top: 8px;
}

.comp-name-tex p {
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  text-align: start;
}

.comp-name-tex ul li img {
  margin-left: 5px;
}

.comp-name-tex ul li img:hover {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(195deg) brightness(100%) contrast(138%);
}

.esg-cate {
  background: #e6f7ff;
  border-radius: 4px;
  padding: 10px;
}

.esg-cate h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  margin: 0px;
}

.esg-cate h4 img {
  margin-right: 5px;
}

.air p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
  margin: 0px;
  padding: 10px 15px;
}

.air {
  background: #eff1f7;
  border-radius: 100px;
}

.esg-cate-name {
  display: flex;
  row-gap: 14px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}

.air p img {
  margin-right: 6px;
}

.issue h4 {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  margin: 0px;
}

.issue {
  background: #f9eddf;
  border-radius: 4px;
  padding: 10px;
}

.issue h4 img {
  margin-right: 5px;
}

.reactions h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #2d303a;
  margin: 0px;
}

.reactions {
  display: flex;
  justify-content: space-between;
  background: #e6f7ff;
  border-radius: 8px 8px 0px 0px;
  padding: 10px 15px;
}

.responses {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
}

.comp-res h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #036e9e;
  padding: 10px 15px;
  margin: 0px;
}

.comp-res ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-align: justify;
  color: #07132f;
  padding-right: 20px;
}

.comp-res ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  text-decoration: none;
  color: #0195ce;
}

.quant p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d303a;
  border-bottom: 1px solid #05476f;
  padding-bottom: 8px;
  margin: 0px;
}

.comp-res.test h6 {
  padding-left: 0px;
}

.left-details-side {
  margin-left: 42px;
}

.desc-tab {
  background: #ffffff;
  border: 1px solid #d07d00;
  border-radius: 8px;
  padding: 14px;
}

.sev-mid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sev-we p span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #2d303a;
  margin: 0px;
}

.sev-we p {
  font-weight: 400;
  font-size: 13px;
  color: #4e515c;
  margin: 0px;
}

.mager {
  background: #eff1f7;
  border-radius: 2px 0px 0px 2px;
  padding: 3px 10px;
}

.mager p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #80838b;
  margin: 0px;
}

.tex-dis-lef p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #0d0d0e;
}

.tex-dis-lef p span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #0d0d0e;
}

.generator {
  background: #fcf6ef;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px;
  position: relative;
}

.ai-text p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin: 0px;
  color: #9d5600;
}

.new-source {
  border: 1px solid #ced2e0;
  border-radius: 8px;
  padding: 8px 14px;
}

.twitte-sec {
  background: #f7f8fa;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
}

.grid-sec-source {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.new-source p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3d4353;
  padding-bottom: 8px;
}

.twitte-sec p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  padding: 10px 0px;
  color: #3d4353;
  margin: 0px;
}

.twitte-sec h6 {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #0d0d0e;
  margin: 0px;
}

.tw-text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3d4353;
}

.tw-text h5 span {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4e515c;
}

.tw-text h5 span img {
  width: 22px;
  margin-right: 4px;
}

.mentio-tweet {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fa;
  padding: 10px 15px;
  flex-wrap: wrap;
}

.tweets {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
}

.see-all-link p {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
}

.user-steve {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
}

.user-prof {
  display: flex;
  align-items: center;
}

.tweet-steve {
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 10px;
}

.user-image {
  padding-right: 15px;
}

.user-name h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #07132f;
}

.user-name p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-top: 5px;
  color: #80838b;
}

.user-tweet p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
  font-family: "inter";
}

.user-tweet span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #80838b;
  margin-top: 3px;
}

.user-likes span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #80838b;
  margin-right: 14px;
}

.user-image span {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  color: #05476f;
  font-family: Inter;
}

.twitter-logo span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #676a73;
  text-wrap: nowrap;
}

.user-tweet.mt-2 ul li a {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.user-tweet.mt-2 ul {
  margin-bottom: 0px;
  padding-left: 18px;
}

.infohover p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.infohover {
  background: #f7f8fa;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 196px;
  position: absolute;
  position: absolute;
  right: 44px;
  top: 19px;
  display: none;
}

.info-mg-icon>img:hover~.infohover {
  display: block;
}

.summ-ry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.twit-logo {
  background: #e5f7ff;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
}

.linkedin {
  background: #e9f1fe;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
}

.copy p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #3d4353;
  margin-top: 8px;
}

.copy-box {
  display: flex;
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
  padding: 5px;
  margin-top: 5px;
  cursor: pointer;
  overflow: auto;
  gap: 6px;
}

.ant-popover.css-dev-only-do-not-override-yp8pcc.css-dev-only-do-not-override-yp8pcc.ant-popover-placement-bottom {
  left: 660px !important;
  top: 184px !important;
}

.ant-popover-arrow {
  display: none !important;
}

.title-save h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #07132f;
  border-bottom: 1px solid #eff1f7;
  padding: 10px 0px;
}

.title-addFile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  height: 40px;
  line-height: 16px;
  color: #07132f;
  border-bottom: 1px solid #eff1f7;
  padding: 0px 12px;
  display: flex;
  justify-content: space-between;
}

/* .title-addFile:first-child {
  padding: 0px 0px 10px 0px!important;
} */

.save-collection span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9a9ca2;
  margin-left: 5px;
}

.save-collection {
  padding: 10px 0px;
}

.checkbox-collection {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
}

.checkbox-collection p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #3d4353;
  margin-left: 8px;
  word-break: break-all;
  cursor: pointer;
}

.checkbox-collection input {
  accent-color: #0195ce;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.save-btn button {
  width: 100%;
  border: 1px solid #0195ce;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #0195ce;
  background-color: transparent;
  padding: 4px 0px;
}

.save-btndisabled button {
  width: 100%;
  border: 1px solid #8dcfe9;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #8dcfe9;
  background-color: transparent;
  padding: 4px 0px;
}

.tab-left-side :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0195ce;
}

.ant-tabs-tab {
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 24px;
  /* identical to box height, or 185% */

  display: flex;
  align-items: center;

  /* Grays/600 */

  color: #3d4353;
}

.link-re {
  background: #f7f8fa;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 243px;
  position: absolute;
  display: none;
}

.tab-right-side {
  display: flex;
}

.selectedSpan0 {
  font-style: normal;
  font-size: 13px;
  color: #000;
  font-family: "inter";
}

.selectedSpan1 {
  color: #036e9e;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  font-family: "inter";
}

.tab-right-side p {
  margin-right: 14px;
  font-weight: 400;
  font-size: 13px;
  color: #676a73;
}

/* div#rc-tabs-1-tab-3:before {
    content: "";
    height: 31px;
    width: 23px;
    background: black;
} */

.row.agg-sent {
  background: rgba(229, 247, 255, 1);
  border-radius: 12px;
  padding: 24px 40px;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.aggregated-sec h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #3d4353;
  margin: 10px 0 0 26px;
  font-family: "inter";
}

.main-source-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-time span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  font-family: Inter;
  color: #676a73;
}

.message-section {
  border-bottom: 1px solid #e7e9f0;
  padding: 0px 0 15px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tab-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab:hover {
  color: #3d4353;
}

.tab-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #0195ce;
  pointer-events: none;
}

.user-tweet p a:hover {
  color: #036e9e;
}

.user-tweet ul li:hover {
  color: #036e9e;
}

.user-tweet ul li a:hover {
  color: #036e9e;
}

.link-re p span {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.link-re span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #07132f;
}

.apple-sum {
  position: relative;
}

.apple-sum>p a:hover~.link-re {
  display: block;
}

.save-collection img {
  width: 19px;
}

/* supply Chane css */

.chain-logo {
  text-align: center;
}

.chain-logo h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  color: #0195ce;
  margin-top: 11px;
}

.chain-des p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #676a73;
}

form.example.supply-chan-search input {
  width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
  min-height: 47px;
}

div.example.supply-chan-search input {
  width: 50%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
  min-height: 47px;
}

.autocomplete-inputNew {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.autocomplete-inputNew.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 47px !important;
}

.autocomplete-inputNew:where(.css-dev-only-do-not-override-pr0fja).ant-select-single .ant-select-selector {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.autocomplete-inputNew:where(.css-dev-only-do-not-override-pr0fja).ant-select-single {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.autocomplete-inputNew:where(.css-dev-only-do-not-override-19gw05y).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 0 11px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.autocomplete-input-search .ant-input-group>.ant-input:first-child {
  border-radius: 6px !important;
  border: 1px solid var(--grays-50, #e4e6ed) !important;
  background-color: transparent;
  width: 289px;
  padding-left: 29px;
}

.autocomplete-input-search .ant-input-group>.ant-input:first-child::placeholder {
  color: var(--grays-400, #676a73);

  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.serchmedia-icon {
  background-image: url("../Assets//Images/search-table.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-position: 3% 58%;
}

.autocomplete-input-search span.ant-input-group-addon {
  display: none !important;
}

.ant-select-single.ant-select-lg .ant-select-selector {
  display: block !important;
}

.autocomplete-input {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.autocomplete-input:where(.css-dev-only-do-not-override-12jzuas).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
  min-height: 47px !important;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.autocomplete-input:where(.css-dev-only-do-not-override-19gw05y).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 47px;
  padding: 0 11px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.home-Watchlist:where(.css-dev-only-do-not-override-12jzuas).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 0 11px;
}

form.example.supply-chan-search {
  display: flex;
  justify-content: center;
}

div.example.supply-chan-search {
  display: flex;
  justify-content: center;
}

form.example.supply-chan-search button {
  min-height: 47px;
}

div.example.supply-chan-search button {
  min-height: 47px;
}

.indexing p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #676a73;
  text-align: start;
  /* margin-left: 23%; */
  padding-top: 10px;
}

.supply-chain-sec {
  padding: 11% 0px 0px 0px;
}

/* search result page css */
.text-comp {
  position: relative;
}

.text-comp.right-side-border:before {
  right: -2px;
  height: 33px;
  top: 15px;
  border: 1px solid #ced2e0;
  position: absolute;
  content: "";
}

form.example.supply-chan-search.chain-apple {
  display: flex;
  justify-content: flex-start;
}

div.example.supply-chan-search.chain-apple {
  display: flex;
  justify-content: flex-start;
}

.sear-top-head h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #2d303a;
  margin-bottom: 5px;
}

.main-top-apple h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  color: #0d0d0e;
}

.heading-ap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.all-grid-sys {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 100%;
  max-width: 800px;
}

.grid-comp h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #80838b;
  margin-bottom: 18px;
}

.grid-comp p {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #07132f;
}

.supp-grid-sys p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #80838b;
  margin-bottom: 18px;
}

.supp-grid-sys span {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #07132f;
}

.supp-grid-sys {
  border-left: 1px solid #eff1f7;
  padding-left: 14px;
  padding-top: 13px;
}

.grid-comp {
  border-left: 3px solid #0195ce;
  padding-left: 12px;
}

.top-high-ris {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  gap: 10px;
}

.first-suppli {
  background: #e6f7ff;
  border-radius: 8px;
  padding: 19px 20px;
}

.first-suppli h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #07132f;
}

.hon-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #2d303a;
  cursor: pointer;
}

.num-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-decoration-line: underline;
  color: #007da9;
  cursor: pointer;
}

.hon-precision {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hon-text p span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  color: #3d4353;
  background: #b9e0f0;
  border-radius: 100px;
  margin-right: 9px;
  padding: 4px 8px;
  line-height: 36px;
}

.first-suppli.last-bg {
  background: #fcf6ef;
}

.hon-text.num-bg p span {
  background: #f6e3cc;
}

.filters-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heding-filter h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #07132f;
}

.clear-text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
}

section.filt-bg {
  background: #f7f8fa;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 20px 30px;
}

.all-filt-drop :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: #4e515c;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.all-filt-drop .ant-select.css-dev-only-do-not-override-yp8pcc.ant-select-single.ant-select-show-arrow {
  width: 94%;
}

.apply-btn button {
  background: #5cbbe0;
  border-radius: 4px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #ffffff;
  padding: 6px 29px;
}

.apply-btn {
  text-align: end;
}

.drop-search input {
  background: #ffffff;
  padding-left: 24px;
  border: 1px solid #ced2e0;
  border-radius: 6px;
  width: 100%;
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #9a9ca2;
}

.risk-leve {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.risk-leve p span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #676a73;
  margin-right: 5px;
}

.low span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
}

.med span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
}

.high span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #fff;
}

.low {
  border-radius: 2px 0px 0px 2px;
  background: var(--yellow-yellow-500, #ffc000);
  padding: 0px 17px;
  line-height: 22px;
}

.med {
  background: var(--orange-orange-500, #f28c28);
  border-radius: 2px 0px 0px 2px;
  padding: 0px 17px;
  line-height: 22px;
}

.high {
  background: var(--red-red-500, #d00000);
  border-radius: 0px 2px 2px 0px;
  padding: 0px 17px;
  line-height: 22px;
}

.chart-btn button {
  background: #0195ce;
  border-radius: 4px;
  border: none;
  outline: none;
  height: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 5px 10px;
}

.cstm_height_chart_btn button {
  height: 30px !important;
}

.chart-btn button img {
  margin-right: 8px;
}

.coll-table th.ant-table-cell {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 13px;
  color: #3d4353 !important;
}

.coll-table td.ant-table-cell {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
  line-height: 24px;
  font-family: Inter;
}

/* .coll-table td.ant-table-cell:nth-child(3) {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  text-decoration-line: underline;
  color: #0281b6;
} */

.coll-table td.ant-table-cell:nth-child(7) {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.coll-table tr:nth-child(even) {
  background: #f5f6fa;
}

.coll-table td.ant-table-cell span img {
  margin-right: 9px;
}

.coll-table span.ant-table-column-sorter.ant-table-column-sorter-full {
  position: absolute;
  left: 70px;
}

.sort-twe-inner p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #676a73;
}

.sort-twe-inner p span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #07132f;
}

.qual-comm p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #07132f;
  text-align: center;
}

.qual-comm {
  background: #41afda;
  border-radius: 100px;
  padding: 5px;
  width: 100%;
  max-width: 110px;
  text-align: center;
}

.wapper-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.steps :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: transparent;
}

.steps :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #d9e0e9;
}

.steps.ant-popover-content {
  display: none;
}

.steps :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: #1677ff;
}

.steps :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-title {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4e515c !important;
}

.steps :where(.css-dev-only-do-not-override-yp8pcc).ant-popover .ant-popover-inner {
  display: none;
}

/* side bar */

.ant-layout-sider-children {
  height: 100vh !important;
  margin-top: -0.1px;
  padding-top: 0.1px;
}

/* .ant-layout-sider-children {
    padding-top: 15px !important;
} */

span.line_dash {
  font-style: normal;
  font-weight: 400;
  font-family: inter;
  font-size: 13px;
  color: #676a73;
  display: inline-block;
  width: 134px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.ant-layout-sider-children {
  background: #ffffff;
  width: 270px;
  border-right: 1px solid #e7e9f0;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-menu-dark,
:where(.css-dev-only-do-not-override-yp8pcc).ant-menu-dark>.ant-menu {
  background: #ffffff !important;
  background-size: cover;
}

li.ant-menu-item.ant-menu-item-only-child {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
}

.admin-text h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.admin-text {
  text-align: left;
  padding: 16px 24px;
  border-bottom: 1px solid #e7e9f0;
  margin-bottom: 24px;
}

ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-dark.css-dev-only-do-not-override-yp8pcc {
  padding: 0px 8px;
}

span.sidebar-cions {
  padding-right: 10px;
}

.selected {
  background: #e6f7ff !important;
  border-radius: 6px;
  border: 1px solid #e6f7ff !important;
  width: 40%;
}

.left-side-padding {
  padding-left: 70px;
  position: fixed;
  z-index: 2;
}

.side-padding.sodebar-left-padding {
  padding-left: 270px;
  width: 100%;
  margin: auto;
}

.flex-pro {
  display: flex;
  align-items: center;
  gap: 27px;
  padding-top: 73px;
}

.pro-page-bg {
  background: linear-gradient(to top, #ffffff 72%, #f7f8fa 55%);
  /* height: 100vh; */
  padding-left: 200px;
  padding-right: 20px;
}

.edit-prof-text-tp h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #07132f;
}

.us-name-in label {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #07132f;
}

.us-name-in input {
  width: 100%;
  border: 1px solid #ced2e0;
  border-radius: 6px;
  padding: 10px;
  outline: none;
  font-size: 12px;

  margin-top: 8px;
}

.us-name-in input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #80838b;
}

.us-btn button {
  background: #0195ce;
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 8px 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}

.edit-prof-text-tp.mt-4 {
  width: 100%;
  max-width: 600px;
}

.us-btn.mt-5 {
  text-align: end;
}

.proname h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #07132f;
  padding-bottom: 6px;
}

.proname p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #676a73;
  padding-top: 6px;
}

.us-name-in p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  color: #676a73;
  margin-top: 3px;
}

.delete-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.delete-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary {
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  color: #d00000 !important;
}

.delete-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-btn {
  height: 0px;
  padding: 0px;
  border-radius: none;
}

.delete-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary:not( :disabled):hover {
  color: red;
  background-color: transparent;
}

.delete-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary {
  color: transparent;
  background-color: transparent;
  box-shadow: none;
  outline: none !important;
  border: none !important;
}

.delete-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-btn:not( :disabled):focus-visible {
  outline: none !important;
}

.popup-pic {
  text-align: center;
}

.pop-heading h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #07132f;
}

.pop-heading p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2d303a;
  margin-top: 27px;
}

.pop-heading p a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0195ce;
}

.pop-heading {
  padding-left: 6px;
}

.pop-heading h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #0d0d0e;
  margin-top: 21px;
}

button.first-btn {
  background: #fff;
  border: 1px solid #e7e9f0;
  border-radius: 4px;
  padding: 4px 23px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #80838b;
}

button.sec-btn {
  background: #dc4040;
  border-radius: 4px;
  border: none;
  padding: 4px 20px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}

/* :where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-footer {
    padding-right: 27px !important;
} */

/* button.ant-modal-close {
    display: none;
} */

/* email-nothification page css */
.page-size {
  width: 100%;
  padding: 20px 122px;
}

.containersize {
  margin: auto;
  width: 752px;
  padding: 20px 20px;
}

.containerWidth {
  width: 752px;
  /* background-color: red; */
  margin: auto;
}

.mail-info-box {
  display: flex;
  gap: 10px;
}

.imp-up p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.notification-text-up {
  display: flex;
  gap: 9px;
}

.info-update {
  background: #fdf1e4;
  border-radius: 8px;
  padding: 15px 20px;
}

th.ps-da-te {
  padding-left: 10px;
}

.alert-msg p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3d4353;
}

.cust-mize-mail {
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 16px;
}

.heading-noti h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0d0d0e;
}

.see-all-tx a {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #0195ce;
}

.see-all-noti {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ced2e0;
  padding-bottom: 7px;
}

.watch-noti input {
  accent-color: #0195ce;
}

.watch-noti {
  display: flex;
  gap: 7px;
}

.watch-noti h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
}

.up-m-watch p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3d4353;
}

.up-m-watch {
  border-bottom: 1px solid #ced2e0;
  padding-bottom: 13px;
}

.new-feature p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #3d4353;
}

.up-m-noti-btn button {
  background: #0195ce;
  border-radius: 4px;
  padding: 5px 15px;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}

.up-m-noti-btn {
  text-align: end;
}

/* plans page css */
.free-sub.outer-border {
  border: 2px solid #eff1f7;
}

.plan-heading h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #07132f;
}

.plan-heading p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #07132f;
  margin-top: 10px;
}

.free-sub {
  border: 2px solid #5cbbe0;
  border-radius: 12px;
  padding: 5px;
}

.subscribe {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.fee h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #07132f;
}

.fee {
  text-align: center;
  background: #e6f7ff;
  border-radius: 8px 8px 0px 0px;
  padding: 20px 0px;
}

.euro-sign h4 span {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #b6bcc7;
  margin-right: 12px;
}

.euro-sign h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #07132f;
}

.euro-sign {
  padding-left: 15px;
  border-bottom: 1px solid #eff1f7;
}

.unlimited {
  padding: 30px 16px 70px 16px;
  line-height: 41px;
}

.unlimited p span {
  margin-right: 8px;
}

.unlimited p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #07132f;
}

.fee.bg-free {
  background: #eff1f7;
}

.euro-sign.txt-clr h4 {
  color: #80838b;
}

.subscri-btn button {
  border: 1px solid #e7e9f0;
  border-radius: 4px;
  padding: 8px;
  width: 91%;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #676a73;
}

.subscri-btn {
  text-align: center;
  margin-bottom: 21px;
}

.envier-collap span.ant-collapse-header-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4e515c;
}

.envier-collap .ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-yp8pcc {
  border: 1px solid #e7e9f0;
  border-radius: 12px;
}

.envier-collap :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse {
  background-color: transparent !important;
}

.envier-collap :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse>.ant-collapse-item {
  border-bottom: none;
}

.envi-collaerp .ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-yp8pcc {
  margin-bottom: 17px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-collapse .ant-collapse-content {
  border-top: none !important;
}

.envier-collap .ant-collapse.ant-collapse-icon-position-start.css-dev-only-do-not-override-yp8pcc {
  margin-bottom: 20px;
}

.reset-btns {
  display: flex;
  gap: 11px;
  align-items: center;
  justify-content: end;
}

.text-rest button {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
  border: 1px solid #0195ce;
  border-radius: 4px;
  padding: 6px 50px;
  background: transparent;
  height: 37px;
}

.text-save-btns button {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  border: 1px solid #0195ce;
  background: #0195ce;
  border-radius: 4px;
  padding: 6px 50px;
}

.reset-setting p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
  padding-bottom: 5px;
}

.reset-setting {
  width: 100%;
  border-top: 1px solid #e7e9f0;
  padding: 29px 0px;
  margin-top: 16px;
}

button.can-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #80838b;
  border: 1px solid #e7e9f0;
  border-radius: 4px;
  background-color: white;
  padding: 5px 13px;
}

button.rest-btn {
  background: #0195ce;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  border: none;
  padding: 5px 17px;
  margin-left: 10px;
}

button.rest-btn:disabled {
  background: rgba(141, 207, 233, 1);
  cursor: no-drop;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  border: none;
  padding: 5px 17px;
  margin-left: 10px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary:not( :disabled):hover {
  color: #0195ce;
  background-color: transparent;
}

.slider-low {
  background: #f7f8fa;
  border-radius: 8px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.railStyle {
  background: linear-gradient(90deg,
      #cdd2e0 22.41%,
      #d98a29 70.71%,
      #d00000 100%);
}

.ghc-emi p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
}

.envier-collap .ant-collapse-expand-icon {
  position: absolute;
  right: 10px;
}

.top-head-on h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #07132f;
}

.mas-text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0d0d0e;
}

.del-all-msg h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
}

.mssage-delete {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced2e0;
  justify-content: space-between;
  padding-bottom: 11px;
}

.new-feat-up {
  display: flex;
  align-items: center;
  gap: 20px;
}

.new-text-up p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #0d0d0e;
  padding-left: 75px;
}

.twomin p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #676a73;
}

.up-timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ex-tag p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.ex-tag p a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0195ce;
}

.new-feat-up {
  display: flex;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #ced2e0;
  padding-bottom: 23px;
}

.pd-right-side-r {
  padding: 0 20px;
}

/*
.pd-right-side-r {
  padding: 0 68px;
}
*/

.table-box.incident.collection table tr th {
  min-width: 184px;
  border-bottom: 1px solid #ced2e0;
  padding: 10px 0px;
}

.table-box.incident.collection tr td h6 img {
  margin-left: 34px;
}

.dots {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-sidebar-div {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  height: 78vh;
  /* border-bottom: 1px solid #e7e9f0; */
}

/*
.main-sidebar-div {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 134px);
}
*/

.foot-sec a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0281b6;
}

.foot-sec {
  text-align: center;
}

.foot-sec {
  /* display: flex; */
  /* justify-content:"/"; */
  width: 100%;
  height: 56px;
  position: absolute;
  bottom: 0;
  padding: 18px;
  text-align: center;
  border-top: 1px solid #e7e9f0;
  background: #fff;
  z-index: 99999;
  border-right: 1px solid #e7e9f0;
}

.main-sidebar-div span.ant-menu-title-content {
  display: flex;
  justify-content: space-between;
  z-index: 99999;
}

.main-sidebar-div li.ant-menu-item {
  border: 1px solid #e7e9f0;
  border-radius: 6px;
  padding: 0px 10px 0px 10px;
}

span.ant-menu-title-content span {
  z-index: 999999;
}

.edit-rename a {
  color: var(--Grays-800, #07132f) !important;
  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.delet-btn-in a {
  color: var(--Grays-800, #07132f) !important;
  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.edit-rename {
  padding: 14px 24px;
  border-bottom: 1px solid #e7e9f0;
  cursor: pointer;
}

.delet-btn-in {
  padding: 14px 24px;
  cursor: pointer;
}

.delet-btn-in a img {
  margin-right: 7px;
}

.edit-rename a img {
  margin-right: 7px;
}

.po-up-in :where(.css-dev-only-do-not-override-yp8pcc).ant-popover .ant-popover-inner {
  padding: 0px !important;
}

.edit-rename :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary {
  color: #07132f;
  background-color: transparent;
  box-shadow: none;
}

.edit-rename :where(.css-dev-only-do-not-override-yp8pcc).ant-btn {
  padding: 0px;
}

.edit-rename :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary:not( :disabled):hover {
  color: #07132f;
}

.reset-setting.folder-num {
  padding: 24px;
}

.new-colle-popup {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  border: 1.5px solid #0195ce;
  border-radius: 6px;
  padding: 10px;
}

.new-colle-popup p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #07132f;
  background: #d7f0fa;
}

button.can-btn.chang {
  background: transparent;
}

.delet-folder-coll {
  display: flex;
  align-items: center;
  gap: 14px;
}

.delete-cll-text p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.rem-fold-delete p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  margin-top: 17px;
  margin-left: 10px;
}

button.rest-btn.del-red {
  background: #dc4040;
}

button.can-btn.can-del {
  background: transparent;
}

.in-put-popup input {
  width: 100%;
  padding: 8px;
  border: 1.5px solid #0195ce;
  border-radius: 6px;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #07132f;
  padding-right: 37px;
}

.cross-img {
  background-image: url("../Assets/Images/eye.svg");
  background-repeat: no-repeat;
  background-position: 97% 42%;
  cursor: pointer;
}

.reset-setting.center-aling-text {
  text-align: center;
}

.reset-setting.center-aling-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
  padding-top: 10px;
  font-family: Inter;
  margin-top: 10px;
}

button.rest-btn.del-red {
  background: #dc4040;
}

button.rest-btn.del-red.center-btn {
  margin-right: 150px;
}

.margin-both-side {
  padding: 0 64px;
}

/*
.margin-both-side {
  padding: 0 64px;
}
*/
/* .edit-rename.bg-edit {
    background: #F7F8FA;
    border-top: 1px solid #E7E9F0;

} */

.switch-btn button {
  margin-right: 10px;
}

.switch-btn {
  padding-bottom: 12px;
}

.switch-btn span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #07132f;
}

.switch-btn :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #1fa2d4;
}

.switch-btn :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #1fa2d4;
}

.custom-modal {
  width: 100% !important;
  max-width: 800px !important;
}

/* .ant-modal-content{
     padding: 0px !important; 
     background-color: blue; 
} */

.imp-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imp-flex p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #0195ce;
  display: flex;
  align-items: center;
}

.imp-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imp-select p {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #676a73;
}

.imp-select {
  border-bottom: 1px solid #eff1f7;
  padding-bottom: 15px;
}

.switch-btn.new-update-watch span {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
}

.email-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e9f0;
  padding-bottom: 44px;
}

.daily-sec {
  padding: 5px 10px;
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
  border-radius: 0px 0px 8px 8px;
}

.daily-sec button {
  border: none;
  outline: none;
  padding: 5px 14px;
  background: #fff;
  margin: 0 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #4e515c;
}

/* .daily-sec button:nth-child(3) {
    background: #1FA2D4;
border-radius: 4px;
    color: white;

} */
.selected-button {
  background: #1fa2d4 !important;
  border-radius: 4px !important;
  color: white !important;
}

.watchlist-name input {
  border: 1px solid #9a9ca2;
  border-radius: 6px;
  width: 61%;
  padding: 10px;
  margin-top: 8px;
  outline: none;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #07132f;
}

.custom-second .ant-modal-footer {
  text-align: center !important;
}

.reset-setting.del-test {
  padding: 16px;
}

.delete-second-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
  padding-top: 10px;
}

.top-edit-bar {
  display: flex;
  gap: 12px;
  background: #f7f8fa;
  border-radius: 8px;
  padding: 16px;
}

.top-edit-bar p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #3d4353;
}

.edit-top-search-btn a {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
}

.edit-top-search-btn {
  text-align: end;
}

.edit-top-search-btn a img {
  width: 16px;
}

.inciden-box.chain {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 75px;
}

.seve-main-boxes.watch-top-list {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 84px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap::after {
  display: none;
}

.phone-view {
  display: none;
}

.hidden {
  display: none !important;
}

.left-side-padding aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.sider_left.new {
  min-width: 82px !important;
  max-width: 90px;
}

.angle-btn-bott {
  text-align: center;
  margin-top: 25px;
}

.angle-btn-bott span {
  color: white;
  border-radius: 20px;
  padding: 5px;
  background: #0195ce;
}

.left-side-padding aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
  min-width: 82px !important;
}

.admin-text p {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #07132f;
}

.ant-select.columns2.css-dev-only-do-not-override-yp8pcc.ant-select-single.ant-select-show-arrow {
  background-image: url("../Assets/Images/columns-3.svg");
  background-repeat: no-repeat;
  background-position: 16% 55%;
  background-color: red;
}

.columns2 {
  font-size: 14px;
  width: 115px;
  background-image: url("../Assets/Images/columns-3.svg");
  background-repeat: no-repeat;
  background-position: 16% 55%;
}

.columns2 .ant-select-selector {
  padding: 0 2px 0px 44px !important;
}

.columns2:where(.css-dev-only-do-not-override-yp8pcc).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(.css-dev-only-do-not-override-yp8pcc).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 0px !important;
}

.columns2:where(.css-dev-only-do-not-override-yp8pcc).ant-select .ant-select-arrow {
  display: none;
}

/* .ant-select-dropdown {
    max-width: 200px !important;
    min-width: 200px !important;
    width: 200px !important;

} */

span.anticon.anticon-close.ant-modal-close-icon {
  background: #ced2e0;
  border-radius: 30px;
  color: white;
  padding: 2px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-close:hover {
  background-color: transparent !important;
}

/* Top Header page css */

.top-bag-image {
  background-image: url("../Assets/Images/newTopBg.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  height: 293px;
  background-size: cover;
  padding-top: 70px;
}

.sticky-Header {
  background-image: url("../Assets/Images/newTopBg.jpeg");
  background-repeat: no-repeat;
  width: 100%;
  min-height: 50px;
  background-size: cover;
  transition: top 3s ease-in-out;
}

.sticky-Header1 {
  padding: 20px 56px;
  width: 96%;
}

.top-sticky-header {
  position: fixed;
  width: 100%;
  z-index: 999;
  margin: 0px 0px;
  transition: top 1s ease-in-out;
}

.set-two {
  border-radius: 0px;
  margin: 0px 0px;
  padding: 16px 8px;
  position: fixed;
  width: 100%;
  background-image: url("../Assets/Images/newTopBg.jpeg");
  z-index: 10;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.global-inner-sec {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(44.8333px);
  border-radius: 8px;

  padding: 24px 24px 40px 24px;
  /* margin: 0 12%; */
}

.global-inner-sec h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #ffffff;
}

.global-inner-sec p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  padding-top: 8px;
}

.adv-search-row .search-container {
  width: 100%;
}

.adv-search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 11px;
}

.adv-search-row .search-container form.example input {
  padding: 16px !important;
  color: #07132f;
}

.adv-search-row .search-container form.example button {
  padding: 5px;
  width: 8%;
}

.adv-sech-btn-top {
  width: 100%;
  max-width: 155px;
}

.adv-sech-btn-top button {
  background: linear-gradient(259.22deg,
      #04ddbc 1.11%,
      #02b2c7 36.67%,
      #0096cd 98.79%);
  border-radius: 6px;
  padding: 7px 12px;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 15px;
  height: 40px;
}

.adv-search-row .m-watch-drop :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 6px;
}

.m-watch-drop span.ant-select-selection-item {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #3d4353;
}

.adv-search-row :where(.css-dev-only-do-not-override-yp8pcc).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 36px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #e6f7ff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #05476f;
}

.ant-select-item.ant-select-item-option.p-opt-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #676a73;
}

.ant-select-item.ant-select-item-option.p-opt-text {
  border-bottom: 1px solid #eff1f7;
  padding: 13px 9px;
  border-radius: 0;
}

.ant-select-item.ant-select-item-option.option-drop.ant-select-item-option-active h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #05476f;
}

.ant-select-item.ant-select-item-option.option-drop.ant-select-item-option-active {
  background: #e6f7ff;
}

.ant-select-item.ant-select-item-option.option-drop h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #3d4353;
  padding: 7px 3px;
}

.mod-search-bar input {
  width: 100%;
  padding: 10px 50px;
  border: none;
  outline: none;
  border-top: 1px solid #e7e9f0;
  border-bottom: 1px solid #e7e9f0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}

.search-mad-icon {
  background-image: url("../Assets/Images/search-md.svg");
  background-repeat: no-repeat;
  background-position: 20px 50%;
}

.custom-modal.advanced div#\:r3\: {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #3d4353;
  padding-left: 18px;
}

.model-tabs .ant-tabs-nav {
  display: none;
  background: #e6f7ff;
  margin-bottom: 0px;
  padding-left: 19px;
}

.model-tabs :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3d4353;
}

/* .model-tabs
  :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #0281b6 !important;
  text-shadow: 0 0 0.25px currentcolor;
} */

.model-tabs :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #0195ce;
  pointer-events: none;
}

.model-comp {
  background: #f7f8fa;
  border-width: 1px 0px;
  border-style: solid;
  border-color: #e7e9f0;
  padding: 7px 18px;
}

.model-comp-name {
  padding-left: 19px;
}

.model-comp-name:hover {
  background-color: #f7f8fa;
}

.model-comp p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #676a73;
  line-height: 16px;
}

.md-flex-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.md-flex-div:hover {
  background-color: #f7f8fa;
}

.md-ads p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #676a73;
}

.md-icons {
  display: flex;
  align-items: center;
  gap: 3px;
  font-family: "inter";
}

.foot-sec-md {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e9f0;
  position: absolute;
  width: 98%;
  /* bottom: -34px; */
  padding: 10px 19px 10px 19px;
  flex-wrap: nowrap;
}

.foot-sec-md2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e9f0;
  /* bottom: -34px; */
  padding: 18px 24px 18px 21px;
  flex-wrap: nowrap;
}

.foot-sec-md2-full-map {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e9f0;
  /* bottom: -34px; */
  padding: 8px 21px 5px 21px;
  flex-wrap: nowrap;
}

.md-compy-tc {
  padding: 0 19px;
  height: 540px;
  overflow-y: scroll;
}

.md-toggle-btn :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #0195ce;
}

.md-toggle-btn :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0195ce;
}

.custom-checkbox-top.incident-md-active {
  background: #f7f8fa;
  border-width: 1px 1px;
  border-style: solid;
  border-color: #e7e9f0;
  padding: 10px 20px;
}

.custom-checkbox-top.incident-md {
  background: #fff;
  border-width: 0px 0px 1px 0px;
  width: 100%;
  border-style: solid;
  border-color: #e7e9f0;
  padding: 10px 20px;
}

.custom-modal.advanced:where(.css-dev-only-do-not-override-yp8pcc).ant-modal .ant-modal-content {
  padding: 20px 0px !important;
  overflow: scroll;
}

.incidentModal:where(.css-dev-only-do-not-override-19gw05y).ant-modal .ant-modal-content {
  padding: 10px;
}

.border-right-md {
  border-right: 1px solid #e7e9f0;
}

.heightStyle {
  height: 540px;
  overflow: auto;
}

.right-arrow {
  background-image: url("../Assets/Images/chevron-right.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 95% 50% !important;
}

.m-watch-drop {
  width: 100%;
  max-width: 205px !important;
}

.m-watch-drop .ant-select.jak-option-drop.css-dev-only-do-not-override-yp8pcc.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

.advanced-search-modal .ant-modal-content {
  /* max-height: 63vh !important;
  padding: 10px;
  overflow-y: scroll; */
  height: 750px;
}

.add-to-wat button {
  background: #0195ce;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  outline: none;
  border: none;
  padding: 6px 18px;
}

.add-to-Com {
  background: transparent;
  border-radius: 4px;
  color: #3d4353;
  outline: none;
  border: 1px solid #ced2e0;
  padding: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.add-to-Com span {
  padding-bottom: 8px;
}

.ant-select-dropdown.css-dev-only-do-not-override-12jzuas.ant-select-dropdown-placement-bottomLeft {
  z-index: 10000;
}

.home-left-list ul {
  list-style: none;
  padding-left: 0px;
}

.home-left-list ul li {
  font-family: Inter;
  padding: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #80838b;
  margin: 6px 0px;
}

.top-right-home-drop {
  display: flex;
  gap: 10px;
  justify-content: end;
  min-width: 752px;
  max-width: 752px;
  width: 752px;
}

.mainHomeContainer {
  min-width: 752px;
  max-width: 752px;
  width: 752px;
  margin-left: 0px;
}

.placeholder-drop :where(.css-dev-only-do-not-override-yp8pcc).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #80838b;
}

.tab-active {
  background: #f7f8fa;
  border-radius: 1px 4px 4px 1px;
  border-left: 3px solid #0195ce;
}

/* .place-drop-list .ant-select-dropdown{
    background-color: red !important;
    width: 500px !important;
    height: 200px;
} */

.first-watch h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #0d0d0e;
}

.empty-watch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e7e9f0;
  padding: 14px 0px;
}

.first-watch p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #80838b;
}

.plus-add-btn button {
  border: 1px solid #0195ce;
  border-radius: 4px;
  background: #ffffff;
  padding: 4px 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0195ce;
}

.plus-added-btn button {
  border: 1px solid #b9e0f0;
  border-radius: 4px;
  background: #e5f7ff;
  padding: 4px 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0195ce;
}

.plus-add-btn button img {
  width: 20px;
  margin-right: 6px;
}

.plus-added-btn button img {
  width: 20px;
  margin-right: 6px;
}

.list-nam-md {
  background: #f7f8fa;
  border: 1px solid #e7e9f0;
  padding: 17px 0px;
}

.list-nam-md label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #3d4353;
}

.text-fild input {
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 6px;
  width: 78%;
  padding: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #80838b;
  height: 40px;
}

.model-watch .ant-modal-content {
  padding: 20px 1px !important;
}

.ant-modal-title {
  padding: 0px 18px;
}

.first-watch {
  padding-left: 18px;
}

.plus-add-btn {
  padding-right: 18px;
}

.plus-added-btn {
  padding-right: 18px;
}

div#\:r7\: {
  padding-bottom: 8px;
}

.text-fild {
  padding: 0 15px;
  display: flex;
  align-items: center;
  column-gap: 7px;
}

.list-nam-md label {
  padding: 0 0 5px 18px;
}

.text-fild button {
  background: #0195ce;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 6px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  justify-content: center;
  color: #ffffff;
  align-items: end;
  height: 38px;
}

.model-watch .ant-modal-footer {
  padding-right: 18px !important;
}

.model-watch .ant-modal-title {
  padding-bottom: 10px !important;
}

.text-fild button img {
  margin-right: 8px;
}

.plus-add-btn.tcik-added button {
  background: #d7f0fa;
  border-radius: 4px;
  border: none;
}

.plus-added-btn.tcik-added button {
  background: #d7f0fa;
  border-radius: 4px;
  border: none;
}

.tab-active a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0d0d0e !important;
}

.placeholder-drop {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
  padding: 4px 10px;
  width: 178px;
  cursor: pointer;
  font-family: Inter;
}

.placeholder-drop div {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #80838b;
}

.down {
  background-image: url("../Assets/Images/chevron-down.svg");
  background-position: 92% 50%;
  background-repeat: no-repeat;
}

.daily-sec.last-hour {
  border: none;
  padding: 2px 1px;
}

.daily-sec.last-hour button {
  border: none;
  outline: none;
  padding: 7px 12px;
  background: #f7f8fa;
  border-radius: 4px;
  margin: 4px 3px;
  font-weight: 500;
  font-size: 11px;
}

.enter-date-pop h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.enter-date-pop {
  padding: 12px 0px;
  border-top: 1px solid #e7e9f0;
  margin-top: 8px;
}

.from {
  background: #f7f8fa;
  border: 1px solid #e7e9f0;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  max-width: 137px;
  width: 121%;
  max-width: 140px;
}

.from span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #9a9ca2;
}

.colendar {
  display: flex;
  align-items: center;
  /* justify-content: space-evenly; */
  column-gap: 31px;
}

.calen-image {
  background-image: url("../Assets/Images/calendar-alt.svg");
  background-repeat: no-repeat;
  background-position: 92% 57%;
  cursor: pointer;
  height: 30px;
}

.air-que h5 {
  background: #d6edc1;
  border-radius: 4px;
  padding: 6px 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  width: 24px;
  text-align: center;
  height: 24px;
}

.air-que {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* .air-que p {
  color: #3c6812 !important;
} */

.air-que.product h5 {
  background: #c7e3fe;
  color: #145797;
}

.air-que.product p {
  color: #145797 !important;
}

.air-que.human h5 {
  background: #e0d8f6;
  color: #3b237b;
}

.air-que.human p {
  color: #3b237b !important;
}

.up-date-text h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #07132f;
  margin-bottom: 11px;
}

.up-date-text p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3d4353;
}

.up-date-text p a {
  color: var(--Primary-Primary-500, #0195ce) !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-left: 6px;
}

.empty-watchlist-page {
  position: relative;
  display: flex;
  gap: 13px;
  padding: 19px 10px;
  border-width: 4px;
  border-style: solid;
  background:
    linear-gradient(white, white) padding-box,
    linear-gradient(to right, #0096cd, #02b2c7, #04ddbc) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
}

.email-message-ex p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #07132f;
}

.email-up {
  border: 1px solid #e7e9f0;
  border-radius: 8px 8px 0px 0px;
  padding: 25px 13px;
  display: flex;
  gap: 11px;
}

.daily-sec.watch-btn-list button {
  padding: 5px 7px;
  margin: 0 2px;
}

.note-pad h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #05476f;
}

.note-pad textarea {
  border: navajowhite;
  resize: none;
  outline: navajowhite;
}

.note-pad {
  background: #f7f8fa;
  border-radius: 8px;
  padding: 13px;
}

.note-pad textarea {
  background: #f7f8fa;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #0d0d0e;
}

.note-pad span {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #676a73;
  display: flex;
  justify-content: center;
}

.w-my-list h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #2d303a;
}

.w-show-res a {
  color: #fff !important;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 114.286% */
  border-radius: 4px;
  background: var(--primary-primary-500, #0195ce);
  padding: 8px 16px;
}

.add-to-withc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e9f0;
  padding-bottom: 15px;
}

.comp-name-list-w {
  background: linear-gradient(270deg,
      #b9e0f0 0%,
      rgba(179, 234, 233, 0.5) 100%);
  border-radius: 6px 6px 0px 0px;
  padding: 10px;
}

.comp-name-list-w p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #045b86;
}

.list-of-com-w {
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e7e9f0;
  border-radius: 0px 0px 6px 6px;
  padding-top: 9px;
  max-height: 252px;
  overflow-y: scroll;
}

.list-of-com-w::-webkit-scrollbar {
  display: none;
}

.list-of-com-w ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.w-add-com-link {
  background: #ffffff;
  border: 1px solid #b9e0f0;
  border-radius: 6px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.w-add-com-link p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #036e9e;
}

.user-pro-se p {
  padding-bottom: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #80838b;
}

.user-sett {
  text-align: center;
  padding: 12px 0px;
}

.acc-set p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4e515c;
  padding: 8px 20px;
  border-bottom: 1px solid #ced2e0;
  border-top: 1px solid #ced2e0;
}

.log-out p {
  padding: 5px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #4e515c;
}

.user-pro-se img {
  padding: 6px;
}

li.setting {
  cursor: pointer;
}

.setting .ant-popover-inner {
  background: #eff1f7;
  padding: 0px;
}

.dashbored-head {
  background-image: url("../Assets/Images/companyPageHead.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 98%;
  height: 232px;
  position: fixed;
  z-index: 998;
  transition: height 0.2s ease;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-breadcrumb li:last-child {
  margin-top: 3px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-switch {
  height: 20px;
  width: 36px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-switch .ant-switch-handle {
  height: 16px;
  width: 16px;
}

span.apple-top-bread a {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff !important;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 3px;
  padding: 0px;
}

span.apple-top-bread span {
  font-style: normal;
  font-weight: 600;
  margin-top: 2px;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  color: #ffffff !important;
}

.apple-logo {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -117px;
  z-index: 999;
  position: fixed;
  width: 50%;
}

.page-title {
  width: 50%;
}

.page-title h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  color: #ffffff;
  padding-bottom: 23px;
}

.page-title p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #676a73;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-remove:focus:not(:focus-visible),
:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-btn:active,
:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-remove:active {
  color: #3d4353 !important;
}

.left-side-padding li.ant-menu-item.ant-menu-item-only-child.selected {
  padding-left: 12px !important;
}

.left-side-padding li.ant-menu-item.ant-menu-item-only-child {
  padding-left: 12px !important;
}

/* :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #0281b6 !important;
  text-shadow: 0 0 0.25px currentcolor;
} */

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab:hover {
  color: #0281b6 !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3d4353;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #0281b6;
  pointer-events: none;
}

.filter-img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

.import-csv p {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #0195ce;
  gap: 7px;
}

.popUp-unique {
  width: 170px !important;
}

.import-csv {
  margin-right: 15px;
  position: relative;
}

.hover-format {
  position: absolute;
  background: #f7f8fa;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 9px 9px;
  width: 150px;
  right: 5px;
  z-index: 2;
  top: 24px;
  display: none;
}

.import-csv p span img {
  cursor: pointer;
}

.hover-format span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.import-csv p span img:hover~.hover-format {
  display: block;
}

.date-cal button {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #80838b;
  padding: 2px 35px;
}

.date-cal button:nth-child(2) {
  background: #0195ce;
  color: #fff;
}

.date-cal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  line-height: 24px;
  margin-bottom: 15px;
}

.date-cal:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-footer-extra {
  line-height: 27px !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: none;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-footer-extra:not(:last-child) {
  border-bottom: none;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #eff1f7 !important;
  background: #eff1f7;
  border-radius: 50px !important;
  z-index: -2 !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-track {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider:hover .ant-slider-track {
  background-color: transparent;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider-horizontal {
  padding-block: 4px;
  height: 12px;
  background: linear-gradient(90deg,
      #cdd2e0 22.41%,
      #d98a29 70.71%,
      #d00000 100%);
  border-radius: 8px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider-horizontal .ant-slider-rail {
  width: 100%;
  height: 4px;
  display: none;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider-horizontal .ant-slider-step {
  inset-inline-start: 0;
  top: 6px;
  width: 94%;
  height: 4px;
  left: 10px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider-horizontal .ant-slider-handle {
  inset-block-start: 3px;
  /* left: 10px !important;  */
}

.custom-slider {
  width: 100%;
  max-width: 354px;
  margin: 0 49px;
  position: relative;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-dot {
  width: 5px;
  height: 5px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:hover::after,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:focus::after {
  box-shadow: none;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider:hover .ant-slider-dot-active {
  border-color: #fff !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle::after {
  content: "";
  box-shadow: none !important;
  width: 30px;
  height: 30px;
  top: -10px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:hover::after,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #1677ff;
  width: 30px;
  height: 30px;
  inset-inline-start: -1px;
  inset-block-start: -1px;
  top: -10px;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:hover::before,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:active::before,
:where(.css-dev-only-do-not-override-yp8pcc).ant-slider .ant-slider-handle:focus::before {
  inset-inline-start: -5px;
  inset-block-start: -5px;
  width: 30px;
  height: 30px;
  top: -10px;
}

.low-text-temp p {
  position: absolute;
  top: 9px;
  left: -28px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #80838b;
}

.low-text-temp {
  position: relative;
}

.high-text-temp {
  position: relative;
}

.high-text-temp p {
  top: -28px;
  right: -30px;
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #80838b;
}

/* :where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today {
  color: #fff !important;
} */

.from:where(.css-dev-only-do-not-override-yp8pcc).ant-picker:hover,
:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-focused {
  border-color: transparent !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  min-width: 31px;
  height: 31px;
  line-height: 31px;
}

li.ant-menu-item.ant-menu-item-only-child.selected span.line_dash {
  font-weight: 600;
  font-size: 13px;
  color: #05476f;
}

li.ant-menu-item.ant-menu-item-only-child.selected span img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

li.ant-menu-item.ant-menu-item-only-child:active {
  background-color: transparent !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: transparent !important;
}

li.ant-menu-item.selected span.line_dash {
  font-weight: 600;
  font-size: 13px;
  font-family: inter;
  color: #05476f;
}

.pie-sourceList {
  height: 200px !important;
  width: 200px !important;
}

.pie-wrap {
  display: flex;
  justify-content: center;
}

.side-dott-nav ul li img {
  cursor: pointer;
}

.compnay-grid-cont {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
}

.esg-risk-inner-cont {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  padding: 20px;
}

.text-esg-m h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #2d303a;
}

.med-risk-cont h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #794614;
}

.esg-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.med-risk-cont {
  background: #fce2c9;
  border-radius: 4px;
  padding: 11px 5px 9px 15px;
}

.med-risk-cont h5 span {
  color: #fff;
  background: #f28c28;
  border-radius: 2px;
  padding: 5px 13px;
  margin-left: 9px;
  font-weight: 600;
  font-size: 13px;
}

.slide-bar {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  column-gap: 16px;
}

.slide-barMain {
  padding: 72px 2px 24px 2px;
  background: #f7f8fa;
  border-radius: 6px;
  text-align: center;
}

.custom-slider.r-g-margin {
  /* margin-right: 1px; */
  width: 83%;
}

.med-risk-cont.gray-bg {
  background: #e7e9f0;
}

.med-risk-cont.gray-bg h5 span {
  background: #9a9ca2;
}

.custom-slider.r-g-margin.slider-bg :where(.css-dev-only-do-not-override-yp8pcc).ant-slider-horizontal {
  background: linear-gradient(90deg,
      #28a24a 15.79%,
      #cdd2e0 36.77%,
      #cdd2e0 67.16%,
      #d00000 100%);
}

.med-risk-cont h5 {
  color: #3d4353;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  color: #fff;
  background-color: #0195ce !important;
  border-radius: 50px !important;
}

.inc-map-pre {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
}

.containerWidth {
  max-width: 752px;
  width: 752px;
  min-width: 752px;
  margin-left: 0px;
}

.map-pre {
  text-align: center;
}

.asia-cont {
  margin-top: 15px;
  background: #e6f7ff;
  opacity: 0.8;
  border-radius: 6px;
  padding: 20px 10px 5px 14px;
  /* width: 50%; */
}

.africa-co h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #80838b;
  border-left: 3px solid #0195ce;
  border-radius: 2px;
  padding-left: 4px;
}

.africa-co p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #07132f;
}

.africa-co {
  margin-bottom: 10px;
}

.sev-title h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #07132f;
}

.sev-title p {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #3d4353;
}

.sev-title p span {
  margin-right: 5px;
}

.sev-title {
  position: absolute;
  bottom: 21px;
}

.postion-map {
  position: relative;
}

.over-time-map {
  background: #ffffff;
  box-shadow:
    0px -2px 8px rgba(0, 0, 0, 0.1),
    0px 8px 12px 1px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
  max-width: 182px;
  padding: 10px;
}

.over-time-map h6 {
  font-weight: 500;
  font-size: 12px;
  color: #676a73;
}

.tolltip-map p {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #3d4353;
}

.tolltip-number p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0d0d0e;
}

.feb-toll {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tolltip-map p span {
  margin-right: 5px;
}

.top-checkbox span.ant-select-selection-item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #80838b !important;
}

.coll-table :where(.css-dev-only-do-not-override-yp8pcc).ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: none;
}

.coll-table {
  margin-bottom: 31px;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: none !important;
  border-bottom: 0 !important;
}

.apexcharts-tooltip-marker {
  border-radius: 2px !important;
}

.Sentiment-grah {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.Sentiment-grah-halfWidth {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.chartTitle {
  justify-content: center;
  display: flex;
}

.chart-bottom-title {
  background-color: #f7f8fa;
  color: #4e515c;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  text-align: center;
  gap: 8px;
  border-radius: 4px;
}

.echarts-for-react.groupChart {
  height: 392px !important;
  margin-top: -32px;
}

.barchart-ps p.chart-bottom-title {
  margin-top: -45px;
}

.echarts-for-react.groupChart canvas {
  width: 376px !important;
  height: 376px !important;
}

.chart-bottom-legend {
  justify-content: center;
  margin-bottom: 10px;
}

.chart-bottom-legend-New {
  padding: 0px 8%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.doubleLayer-pieChart {
  /* height: 110% !important; */
  width: 320px !important;
}

/* .radar-CLS {
  height: 300px !important;
  width: 70% !important;
 
} */

.echarts-for-react.radar-CLS div {
  background-color: red;
  width: 0 !important;
  height: 0 !important;
  /* justify-content: center; */
}

.radar-Title-organizationName {
  color: #07132f;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
}

.radar-subTitle-organizationName {
  color: #676a73;
}

.wordChart-wrap {
  background: #ffffff;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
}

.up-date-text img {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker-dropdown .ant-picker-today-btn {
  color: #1677ff;
  display: none !important;
}

.most-user-ps h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #2d303a;
  padding-bottom: 10px;
}

.most-user-ps p {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #2d303a;
}

.most-user-ps {
  padding: 14px 20px;
}

.esg-select-btn {
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 6px;
  display: flex;
  /* gap: 10px; */
  width: 223px;
  padding: 1px;
}

.button-esg-active {
  background: #1fa2d4;
  border-radius: 4px;
  padding: 8px 40px;
}

.button-esg-active p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #ffffff;
}

.button-sent-ps p {
  font-weight: 400;
  font-size: 12px;
  color: #80838b;
  padding: 8px 20px;
}

.esg-sentiment-ps {
  padding: 13px 20px;
  border: 1px solid #e7e9f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.colors-div {
  display: flex;
  align-items: center;
  gap: 36px;
}

.impact-colors span {
  margin-right: 6px;
}

.impact-colors p {
  font-weight: 500;
  font-size: 11px;
  line-height: 21px;
  color: #4e515c;
}

.governance-main {
  display: flex;
  padding: 15px;
  align-items: center;
}

.wordChart-Title-organizationName {
  color: #2d303a;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

.wordChart-subTitle-organizationName {
  color: #2d303a;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
}

.wordChart-header {
  padding: 2%;
  padding-inline: 3%;
}

.wordChart-subHeader {
  display: flex;
  justify-content: space-between;
}

.switchBtn {
  border-radius: 6px;
  border: 1px solid #ced2e0;
  padding: 2px;
  width: 20%;
  display: flex;
  justify-content: space-around;
}

th.ant-table-cell {
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #3d4353 !important;
}

.col-index {
  font-family: Inter;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #9a9ca2 !important;
  margin-right: 5px;
  cursor: unset !important;
  word-wrap: break-word;
}

/* .titleText {
  font-size: 13px;
  white-space: break-spaces;
} */

.titleText {
  /*des change */
  white-space: break-spaces;
  color: var(--Grays-800, #07132f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.titleText:hover {
  color: #0195ce !important;
  cursor: pointer;
}

td.ant-table-cell.inidentTblCol-5 {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #4e515c !important;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-picker .ant-picker-clear {
  top: -14%;
}

.colendar :where(.css-dev-only-do-not-override-yp8pcc).ant-picker .ant-picker-input {
  width: 83%;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-btn:not( :disabled):focus-visible {
  outline: none !important;
  outline-offset: 0px !important;
  transition:
    outline-offset 0s,
    outline 0s;
}

:where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary:not( :disabled):active {
  color: #fff;
  background-color: transparent !important;
}

.custom-modal {
  top: 30px !important;
}

.envier-collap :where(.css-dev-only-do-not-override-yp8pcc).ant-collapse .ant-collapse-content {
  border: 1px solid #fff6f6;
}

.custom-checkbox-top.incident-md-active label {
  font-weight: 600;
  font-size: 13px !important;
  color: #3d4353;
}

.flex-pro.padding-top-pass {
  padding-top: 81px;
}

.fullmap-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-wrap {
  display: flex;
  position: relative;
  align-items: center;
}

.input-eye {
  position: absolute;
  top: 28px;
  right: 42px;
  cursor: pointer;
}

.input-x {
  position: absolute;
  top: 25px;
  right: 13px;
  cursor: pointer;
}

.sliderVal {
  position: absolute;
  top: 8px;
}

.fullmap-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullmap-header-right {
  gap: 10px;
  display: flex;
  align-items: center;
}

.top-checkbox.fullmap-header-right {
  background-color: #fff;
}

.bg-sec.full-map-padding {
  padding: 0px;
}

.padding-full {
  padding: 20px;
}

.padding-full {
  padding: 11px 20px 12px 20px;
}

.pd-full-map {
  padding: 16px 20px 13px 20px;
  border-top: 1px solid #e7e9f0;
  background: #fff;
}

.cross-model .anticon svg {
  padding: 3px !important;
}

.deleteModal:where(.css-dev-only-do-not-override-12jzuas).ant-modal .ant-modal-content {
  padding: 20px 64px;
}

img.user-profile-op {
  border-top: 1px solid #e7e9f0;
  padding-top: 12px;
}

/* .anticon svg {
  display: inline-block;
  padding: 2px;
} */

.spacebtw-icon-incidetsList {
  margin-left: 25px;
}

.esg-select-btn.fullMap-btn {
  position: absolute;
  top: 18px;
  left: 19px;
  z-index: 9;
}

.fullMap-switch {
  bottom: 35px;
  left: 19px;
}

.fullMap-switch-wrap {
  padding: 15px;
  border: 1px solid #e7e9f0;
  border-radius: 8px;
  background-color: #ffffff;
}

.downl-btn {
  cursor: pointer;
}

.downl-btn1 {
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--Grays-75-A200, #d2d7e5);
  padding: 6px;
}

.downl-btn1 img {
  display: flex;
  width: 15px !important;
  height: 15px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.white-border-sc {
  color: #ffffff00;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #ffffff96;
  height: 20px;
  margin-right: 15px;
  margin-left: 10px;
  width: 2px;
}

.downl-btn .incidence {
  margin-right: -9px;
}

.PST-wrap {
  display: flex;
  align-items: center;
}

.tile {
  border-radius: 0 0;
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
  font-size: 13px;
  font-weight: 600;
  color: #05476f;
}

.tile.IDC {
  justify-content: space-between;
  /* margin-bottom: 22px; */
}

td.ant-table-cell.companyPage-col2 {
  direction: rtl;
}

td.ant-table-cell.companyPage-col1 {
  white-space: break-spaces;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 6px 8px !important;
}

.titleCss {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  /* white-space: break-spaces; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}

.titleCss:hover {
  color: #0281b6 !important;
}

.pad-all-side {
  padding: 12px;
}

.full-map-active {
  padding: 9px 26px;
}

.save-btn img {
  margin-left: 10px;
  cursor: pointer;
}

.save-btndisabled img {
  cursor: default;
}

.steps-wrap {
  display: flex;
  justify-content: center;
  gap: 149px;
  position: absolute;
  top: -2px;
  text-align: center;
  left: 132px;
}

.steps {
  position: relative;
}

.on-sem p {
  font-weight: 400;
  font-size: 12px;
  color: #4e515c;
}

.apply-pz p {
  font-weight: 600;
  font-size: 13px;
  color: #ffffff;
}

.apply-pz {
  background: #41afda;
  border-radius: 100px;
  padding: 4px 16px;
}

.chain-full {
  border: 1px solid #ced2e0;
  border-radius: 100px;
  padding: 5px 10px;
}

.chain-full p {
  font-weight: 400;
  font-size: 13px;
  color: #3d4353;
}

.fullmap-header-right img {
  cursor: pointer;
  position: relative;
}

.apple-map-page {
  height: 85vh;
  text-align: center;
}

.fliter h5 {
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
  margin-bottom: 10px;
}

.apple-checkbox p {
  line-height: 22px;
}

.fliter {
  border-bottom: 1px solid #e7e9f0;
  padding-bottom: 12px;
  margin-bottom: 10px;
}

.apple-radio-btn label {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.check-box.apple-checkbox input {
  accent-color: #0195ce;
}

.check-box.apple-radio-btn input {
  accent-color: #0195ce;
}

.bottom-text-map p {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #07132f;
}

.view-map-ps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.apple-map-toggle :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #ced2e0;
}

.apple-map-toggle :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #ced2e0;
}

.map-tab ul {
  height: 37px;
  display: flex;
  align-items: center;
  list-style: none;
  border: 1px solid #e7e9f0;
}

.map-tab ul li {
  font-weight: 500;
  font-size: 12px;
  color: #4e515c;
}

.map-tab ul li {
  margin: 5px 20px;
}

.map-tab ul li a {
  padding: 6px 25px;
  position: relative;
}

li.active-tab {
  border-radius: 4px;
}

li.active-tab a {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0d0d0e;
  background: #eff1f7;
  border-radius: 4px;
}

.map-tab ul li a:after {
  content: "";
  border: 1px solid #ced2e0;
  position: absolute;
  right: -22px;
  height: 19px;
}

.inc-map-pre .row {
  justify-content: space-between;
}

.threeDot-pop .ant-popover-inner {
  padding: 0;
}

.table-txt-sz p {
  font-weight: 600 !important;
}

.slider-container {
  background: linear-gradient(90deg,
      #cdd2e0 22.41%,
      #d98a29 70.71%,
      #d00000 100%);
  width: 80%;
  height: 8px;
  border-radius: 50px;
}

.slider-container.corporateSlider {
  background: linear-gradient(90deg,
      #00a36c 0.65%,
      #ffd040 22.21%,
      #f5a95e 60.55%,
      #d00000 100%);

  width: 80%;
  height: 8px;
  border-radius: 50px;
}

.horizontal-slider {
  position: relative;
}

.horizontal-slider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 4px;
}

.example-mark {
  position: relative;
  z-index: 1;
}

.example-mark::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 8px;
  transform: translate(-50%, -50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #e7e9f0;
}

.example-thumb-example-thumb-1 {
  align-items: center;
  border-radius: 100%;
  height: 35px;
  margin-top: -8px;
  width: 35px;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding-top: 6px;
  background-color: #ffffff;
  position: absolute;
  z-index: 1000 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  /* Add any additional styles for the thumb container here */
}

.thumb {
  position: absolute;
  align-items: center;
  border-radius: 100%;
  height: 15px;
  margin-top: -3px;
  width: 17px;
  top: -2;
  font-size: 12px;
  align-content: center;
  justify-content: center;
  text-align: center;
  background-color: #ffffff;
  z-index: 1000 !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.line-chart.bubble-Pst-UM {
  padding: 20px 0px 20px 12px !important;
}

.overflow-chart {
  overflow: hidden;
}

.sup-data-Um {
  cursor: pointer;
}

.eniv-switch-ps :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #6ebd20;
}

.eniv-switch-ps :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #6ebd20;
}

.eniv-switch-ps {
  display: flex;
  align-items: center;
}

.eniv-switch-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #2191fb;
}

.eniv-switch-sec :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #2191fb;
}

.eniv-switch-sec {
  display: flex;
  align-items: center;
}

.eniv-switch-th :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #653dd4;
}

.eniv-switch-th :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #653dd4;
}

.eniv-switch-th {
  display: flex;
  align-items: center;
}

.search-image input {
  background-image: url("../Assets/Images/search-table.svg");
  background-repeat: no-repeat;
  background-position: 3% 50%;
}

.btn-none button.ant-modal-close {
  display: none;
}

.popover-side-pd:where(.css-dev-only-do-not-override-yp8pcc).ant-popover .ant-popover-inner {
  padding: 0px !important;
}

.dropDown-searchList-UM::placeholder {
  padding-left: 25px;
}

.combo-box {
  cursor: pointer;
}

button.ant-switch.ev-switch-Um.css-dev-only-do-not-override-yp8pcc.ant-switch-checked {
  background-color: #6ebd20;
  justify-content: center;
}

button.ant-switch.so-switch-Um.css-dev-only-do-not-override-yp8pcc.ant-switch-checked {
  background-color: #2191fb;
  justify-content: center;
}

button.ant-switch.gov-switch-Um.css-dev-only-do-not-override-yp8pcc.ant-switch-checked {
  background-color: #653dd4;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-switch:hover:not(.ant-switch-disabled) {
  background-color: #ced2e0;
}

.example-thumb-0:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #000000;
  transform: translateY(29px);
  right: 17px;
}

.example-thumb-4:before {
  content: "";
  position: absolute;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #000000;
  transform: translateY(-18px);
}

.example-thumb-1:before {
  content: "";
  position: absolute;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #000000;
  transform: translateY(-18px);
}

.example-thumb-2:before {
  content: "";
  position: absolute;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #000000;
  transform: translateY(-18px);
}

.example-thumb-3:before {
  content: "";
  position: absolute;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #000000;
  transform: translateY(-18px);
}

.slider-lables {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #80838b;
}

.slider-container.greenSlider {
  background: linear-gradient(90deg,
      #ffd040 20.19%,
      #f5a95e 60.55%,
      #d00000 100%);
}

.radar-chart-sp {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
  justify-content: space-around;
}

.rdr-wrap-Um {
  margin: 0px 10px;
  justify-content: center;
}

.jak-option-drop {
  width: 100%;
}

.jak-option-drop .ant-select-selector {
  height: 36px !important;
}

.top-checkbox .search-result-dropDown-Um {
  width: 94%;
}

.columns2 span.ant-select-selection-item {
  padding-inline-end: 0 !important;
}

.dlt-acc-um {
  background-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 15px !important;
  color: #d00000 !important;
  box-shadow: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #0281b6 !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.customizing-tab-um .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0195ce !important;
  border-bottom-color: #0195ce !important;
}

.example-thumb.example-thumb-1 {
  position: absolute;
  margin-top: 33px;
  outline: none;
  height: auto;
  width: fit-content;
  font-size: 12px;
  background-color: #e7e9f0;
}

.example-thumb.example-thumb-2 {
  position: absolute;
  margin-top: 33px;
  outline: none;
  height: auto;
  width: fit-content;
  font-size: 12px;
  background-color: #e7e9f0;
}

.example-thumb.example-thumb-3 {
  position: absolute;
  margin-top: 33px;
  outline: none;
  height: auto;
  width: fit-content;
  font-size: 12px;
  background-color: #e7e9f0;
}

.example-thumb.example-thumb-4 {
  position: absolute;
  margin-top: 33px;
  outline: none;
  height: auto;
  width: fit-content;
  font-size: 12px;
  background-color: #e7e9f0;
}

.example-thumb.example-thumb-0 {
  position: absolute;
  margin-top: -46px;
  outline: none;
  height: auto;
  min-width: 72px;
  font-size: 12px;
  background-color: #ffe080;
}

.slider-Lable-Text {
  margin: 4px;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
}

.example-thumb-0:before {
  transform: translate(-11px, 29px);
  border-top-color: #05476f;
}

.example-thumb-1:before {
  transform: translate(-5px, -18px);
}

.example-thumb-2:before {
  transform: translate(-5px, -18px);
}

.example-thumb-3:before {
  transform: translate(-5px, -18px);
}

.col-2.continent-sider {
  width: 13.666667%;
  padding-right: 0;
}

.advancedSearch .ant-modal-content {
  width: 108%;
  padding: 20px 0px;
  height: 750px;
  /* overflow: scroll; */
}

.check-box input {
  accent-color: #0195ce;
  border: 1px solid #ced2e0 !important;
}

.custom-slider {
  width: 100%;
  max-width: 380px;
  margin: 0 49px;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.px-3 {
  padding-bottom: 7px;
}

button.done-btn {
  padding: 5px 19px;
}

.menuBg.ant-menu-inline-collapsed li {
  padding-left: 22px !important;
}

.customizing-colaps-um {
  margin-bottom: 20px;
}

.dlt-acc-um:hover {
  background-color: #fff !important;
}

.error {
  color: red;
  display: flex;
  margin-left: 5px;
  font-weight: 400;
  font-size: 11px;
  align-items: center;
  font-family: Inter;
}

.relative-wrap {
  position: relative;
}

.page-header.confirm-mk {
  justify-content: center;
}

.imgMailRowStyle {
  justify-content: space-between;
}

.imgMailTextStyle {
  justify-content: space-between;
}

.imgMailPstyle {
  margin-left: 5px;
}

/* dgn changes */
.top-header-sec {
  position: fixed;
  width: 96%;
  z-index: 999;
}

.top-fixed {
  margin-top: 310px;
}

.hone-left-side {
  position: fixed;
  width: 304px;
}

.hone-left-side1 {
  position: fixed;
  width: 280px;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
:where(.css-dev-only-do-not-override-12jzuas).ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
  background: #0195ce;
  height: 3px !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #0195ce;
}

.custom-checkbox-top input {
  accent-color: #0195ce;
}

.colum-box :where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
}

.coll-table span.anticon.anticon-down {
  border: 1px solid #ced2e0;
  border-radius: 100px;
  padding: 3px;
  color: #9a9ca2;
  font-size: 8px;
}

span.anticon.anticon-up {
  border: 1px solid #ced2e0;
  border-radius: 100px;
  padding: 3px;
  color: #9a9ca2;
  font-size: 8px;
}

li.ant-menu-item.selected img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

.top-space {
  margin-top: 268px;
  transition: margin-top 0.2s ease;
}

.top-margin-comp {
  margin-top: 35px;
}

.waper-das {
  width: 100%;
  max-width: 1120px;
  margin: auto;
}

.home-left-list {
  overflow: auto;
  height: 440px;
}

.home-left-list ::-webkit-scrollbar {
  display: none;
}

.page-num li.ant-pagination-item.ant-pagination-item-active a {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #045b86 !important;
}

.page-num :where(.css-dev-only-do-not-override-12jzuas).ant-pagination .ant-pagination-item-active {
  font-weight: 500;
  border-color: #d7f0fa;
  background: #d7f0fa;
  border-radius: 4px;
}

.page-num li.ant-pagination-prev a {
  font-weight: 400 !important;
  font-size: 12px;
  color: #80838b !important;
}

.page-num li.ant-pagination-item a {
  font-size: 12px;
  color: #80838b;
  font-weight: 500;
}

.offsetSelectPage .ant-select-selector {
  padding: 0 11px 0 8px !important;
}

.offsetSelectPage .ant-select-arrow {
  padding-right: 3px;
}

.cross-modelNEW1 .ant-modal-content {
  padding: 0px 0px !important;
}

.cross-modelNew1-footer {
  padding: 20px 24px;
}

.cross-modelNew1 .ant-modal-title {
  padding: 0px 20px;
}

.page-num li.ant-pagination-next a {
  font-weight: 400;
  font-size: 12px;
  color: #80838b !important;
}

li.ant-pagination-options {
  display: none !important;
}

.toggle-emil :where(.css-dev-only-do-not-override-12jzuas).ant-switch.ant-switch-checked {
  background: #1fa2d4 !important;
  border-radius: 100px;
}

.apple-map-toggle :where(.css-dev-only-do-not-override-12jzuas).ant-switch.ant-switch-checked {
  background: #1fa2d4 !important;
}

.verified-um-padding {
  padding: 100px 25%;
  text-wrap: nowrap;
}

.relative-wrap input {
  padding: 10px 69px 10px 10px;
}

.dlt-mgs-txt-um a {
  color: #0195ce !important;
}

.pass-iconClsEye-um {
  top: 23px;
}

.pass-iconClsX-um {
  top: 20px;
}

.input-icon-um {
  display: flex;
  justify-content: space-between;
  width: 45px;
  position: absolute;
  right: 10px;
  top: 40%;
}

.sort-tile {
  display: flex;
  width: 80px;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.sorter-um {
  display: flex;
  flex-direction: column-reverse;
  gap: 2px;
}

.sorterImg {
  filter: saturate(0);
}

:where(.css-dev-only-do-not-override-12jzuas).ant-modal-root .ant-modal-wrap {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  overflow: auto;
  outline: 0;
  z-index: 10000;
  -webkit-overflow-scrolling: touch;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 6000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.activeTile-tab-wrap {
  column-gap: 20px;
  display: flex;
}

.activeTile-tab-wrap .activeTile {
  color: #b16300;
  background: #f6e3cc;
}

.comp-res .ui-List {
  overflow-y: auto;
}

.user-tweet.mt-2 {
  overflow-y: auto;
  max-height: 150px;
}

.tileWrap-incidentDetail-um {
  display: grid;
  row-gap: 35px;
}

.canvas {
  width: 1223px;
  height: 700px;
  cursor: default;
}

.map-Marker {
  background-color: #b70000;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border: 1px solid #f3bfbf;
}

.map-Marker-mid {
  background-color: #f28c28;
  width: 20px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border: 1px solid #f3bfbf;
}

.map-Marker-low {
  background-color: #ffd700;
  width: 20px;
  height: 25px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  border: 1px solid #f3bfbf;
}

.topSearch-list-style {
  overflow-y: scroll;
  max-height: 526px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

:where(.css-dev-only-do-not-override-12jzuas).ant-row {
  display: flex;
  flex-flow: row;
  min-width: 0;
}

.selecteditem-searchbar {
  display: flex;
  max-width: 100%;
  background-color: white;
  overflow-y: scroll;
  border-top-left-radius: 8px;
  height: 40px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: #e7e9f0;
}

.selecteditem-searchbar::-webkit-scrollbar {
  height: 0;
  width: 0;
  /* Set width of the scrollbar */
  background-color: transparent;
  /* Set background color of the scrollbar */
}

.selecteditem-searchbar::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Set color of the scrollbar thumb */
}

.selecteditem-searchbar::-webkit-scrollbar-track {
  background-color: transparent;
  /* Set color of the scrollbar track */
}

.selectedItemBox {
  display: flex;
  justify-content: space-between;
  height: 24px;
  padding: 4px;
  margin-left: 10px;
  background-color: #e5f7ff;
  color: #3d4353;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.selectedItemText {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.homeSearchBar {
  width: 100%;
}

.deleteSelectedItem {
  cursor: pointer;
  color: #b6bcc7;
}

.create-watch-list-img {
  height: 20px;
  width: 20px;
}

/* .col-sm-7 {
  overflow: scroll;
  height: 45vh;
} */
.md-incident-loc1 {
  /* overflow: scroll; */
  height: 500px;
}

.md-incident-loc1 {
  /* overflow: scroll; */
  height: 500px;
}

.table-box td.ant-table-cell.ant-table-cell-ellipsis {
  text-align: left !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: #d9d9d9;
}

.share {
  padding: 21px 21px !important;
}

.table-page-sec :where(.css-dev-only-do-not-override-12jzuas).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

p.login-link {
  background-color: #dc4040;
  padding: 10px 20px;
  color: white;
  margin-top: -14px;
  border-radius: 5px !important;
  margin-bottom: 10px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 10px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.88);
  line-height: 1.5714285714285714;
  text-align: start;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 100px;
  height: 32px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 73px;
  margin-inline-start: 0px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-picker-calendar .ant-picker-calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  align-items: baseline;
  align-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
}

.top-bar-margin-handle {
  margin-top: 90px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  /* height: 32px !important; */
  /*If you want to uncomment this line please add your specific class.*/
  padding: 0 11px;
}

.font12.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  font-size: 12px !important;
}

.font12.ant-select-single {
  height: 100%;
}

span.ant-select-selection-item,
.offsetSelectPage {
  padding-inline-end: 6px !important;
  display: flex;
  align-items: center;
}

.CustomPagination {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.offsetContainer {
  margin-top: 50px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/* .ant-checkbox-inner {
  display: none !important;
} */

.incidentTextHover:hover {
  color: #0096cd;
}

.datePickerButton {
  color: white;
  padding: 3;
  border-radius: 5;
  background-color: #0195ce;
  height: 32px;
  width: 132px;
  margin-top: 10;
  align-items: center;
  text-align: center;
}

.datePickerButton:hover {
  color: #fff !important;
}

.datePickerCancelButton {
  background-color: #fff;
  color: #000;
}

.datePickerCancelButton:hover {
  color: #000 !important;
}

.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch.css-dev-only-do-not-override-12jzuas {
  display: none !important;
}

.legend {
  display: flex;
  align-items: center;
}

.legendButton {
  background-color: "#fff";
  border-radius: 100px;
  border: 1px;
  height: 32;
  width: 109;
  color: #000;
  border: 1px solid #ced2e0;
  margin-right: 10px;
}

.legendButton.active {
  border: 1px solid #0195ce;
  background-color: #0195ce;
  color: #fff;
}

.legendButton.active:hover {
  color: #fff !important;
}

.legendCircle {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  box-sizing: border-box;
  margin-right: 5px;
}

.rdr-wrap-com {
  justify-content: center;
}

.comparisonModal .ant-modal-content {
  height: 500px !important;
  padding: 20px 0px !important;
}

.comparisonModal .ant-select-selector {
  border: 0px !important;
  border-bottom: 1px solid #9a9ca2 !important;
  border-top: 1px solid #9a9ca2 !important;
  border-radius: 0;
  box-shadow: none;
}

.graphPTag {
  height: 32px;
  width: auto;
  padding: 10px;
  border-radius: 23px;
  border: 1px solid #b6bcc7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.legendImg {
  height: 20px;
  width: 20px;
}

.search-div-hv {
  background-color: white;

  height: 42px;

  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.search-input-hv {
  flex-grow: 1;
}

.topheader-h1-hv {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 64px;
  height: 40px;
  background-color: white;
  border-left: 0.2px solid #c6c6c6;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.displayNone {
  display: none;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: 45px;
  margin: 0 4px;
  padding: 0px 8px 0;
  border: 0;
  border-top: 2px solid rgba(5, 5, 5, 0.06);
  border-radius: 0;
  transition: background 0.3s;
}

:where(.css-dev-only-do-not-override-19gw05y).ant-picker-calendar .ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: auto;
}

.search-modal-dgn-hv {
  width: 65%;
  background-color: white;
  z-index: 1000;
  position: absolute;
  height: 200px;
  overflow-y: scroll;
  box-shadow: 0px 0px 8px 0px;
  margin-top: 3px;
  border-radius: 8px;
}

.circle-image {
  height: 120px;
  width: 120px;
  border-radius: 60px;
  background-color: rgb(231, 233, 240);
  box-shadow: 0px 0px 12px 0px #426ea140;
  border: 3px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intialsText {
  font-family: "Inter";
  font-size: 42px;
  line-height: 56px;
  text-align: center;
  color: #0195ce;
}

.incidentTimeline-tabs-top-div {
  margin-top: 20px;
  width: 100%;
}

.adavnced-search-bar-hv {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  padding-right: 30px;
  justify-content: space-between;
}

.mapIncidentItem {
  font-family: "inter";
  color: "#2D303A";
}

span.ant-input-affix-wrapper.css-dev-only-do-not-override-12jzuas {
  flex-direction: row-reverse;
}

.SeveritySwitch:where(.css-dev-only-do-not-override-12jzuas).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #1fa2d4 !important;
}

.SeveritySwitch.ant-switch.ant-switch-checked {
  background: #0195ce !important;
}

.paddingTopBottom {
  padding: 20px 20px 20px 20px !important;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 20px 0px 20px 0px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-modal .ant-modal-footer {
  padding-right: 10px;
  margin-top: 0px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-menu-inline .ant-menu-item {
  height: 40px;
  width: 256px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-modal .ant-modal-footer {
  margin-top: 0px;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 0;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 0;
  padding-inline-start: 0;
  outline: none;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.googleMapFilterBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleMapFilter {
  width: 34px;
  height: 34px;
  margin-right: 0.8vw;
  border: 1px solid #ced2e0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterIcon {
  width: 55%;
  height: 55%;
}

.selectDrop {
  display: none !important;
}

.modalButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.delete-collection-popup .modalButtonContainer {
  justify-content: center;
}

.add-to-my-list-item {
  margin-right: 8px;
}

.ant-tooltip.ant-tooltip-placement-top {
  z-index: 9999999 !important;
}

.ant-modal .ant-modal-close {
  inset-inline-end: end;
  /* inset-inline-end */
}

/* .ant-modal .ant-modal-content {
  padding: 20 24;
} */

/* .ant-modal .ant-modal-footer {
  padding-right: 24px;
} */

.left-margin {
  margin-left: -22px;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-content {
  height: 5px;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  height: 150px;
}

.ant-picker-calendar-date-value {
  text-align: center;
}

.ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch.css-dev-only-do-not-override-pr0fja {
  display: flex;
}

label.ant-radio-button-wrapper.css-dev-only-do-not-override-pr0fja {
  display: flex;
  align-items: center;
  height: 40px;
}

/* .ant-radio-button-wrapper {
  padding-inline: 12px !important;
} */

/* modal css */
.cross-model {
  width: 100% !important;
  max-width: 752px !important;
}

.left-side-bg-ps {
  /* background-image: url("../Assets/Images/mesh.png");
  background-repeat: no-repeat;
  background-position: inherit;
  width: 100%;
  max-width: 268px;
  height: 600px;
  background-size: 100%; */
  border-radius: 24px 0px 0px 0px;
}

.user-content-modal-ps {
  display: flex;
}

/* .cross-model.ant-modal .ant-modal-content {
  padding: 0px 0px !important;
   border-radius: 24px; 
} */
/* .cross-model .ant-modal-footer {
  display: none !important;
} */

button.chnage-btn {
  color: #0195ce;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: none;
  padding: 0px;
}

.back-side-right-ps {
  padding: 40px 48px;
  width: 100%;
  max-width: 488px;
}

.supply-chain-ps h1 {
  color: #045b86;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.supply-chain-ps p {
  color: #676a73;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-top: 24px;
  width: 363px;
}

.bott-button-ps button.skip-btn-ps {
  color: #80838b;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: none;
}

button.continue-btn-ps {
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

button.continue-btn-ps {
  color: #fff;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  background: #0195ce;
  border: none;
  padding: 10px;
}

.bott-button-ps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 55%;
}

.left-side-bg-ps img {
  border-radius: 24px 0px 0px 0px;
}

.steps-ps .ant-steps.ant-steps-dot .ant-steps-item-icon,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 14px;
  height: 14px;
  margin-inline-start: 2px;
}

.steps-ps .ant-steps.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  width: 14px;
  height: 14px;
  margin-inline-start: 2px;
}

.steps-ps .ant-steps.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 36px);
  margin-inline-start: -45px;
}

.steps-ps .ant-steps.ant-steps-dot .ant-steps-item-content,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 60px;
}

.steps-ps {
  padding: 34px 0px 32px 0;
}

.steps-ps .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
  background-color: #1fa2d4;
}

.steps-ps .ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: linear-gradient(42deg, #21a0d4 0%, #6341d4 98.32%);
}

.steps-ps .ant-steps .ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
  background: linear-gradient(42deg, #21a0d4 0%, #6341d4 98.32%);
}

.steps-ps .ant-steps.ant-steps-dot .ant-steps-item-tail,
.ant-steps.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 4px;
}

.inner-sidebar-sec::-webkit-scrollbar {
  display: none;
}

.comp-prc-num:hover {
  background: #d00000;
}

.comp-prc-num:hover p {
  color: #fff;
}

.comp-full-name p:hover {
  color: #0195ce;
}

.colors p:hover {
  color: #0195ce;
}

.mainContainerView {
  max-width: 1530px;
  margin: auto;
}

.containerView {
  width: 100%;
  max-width: 1120px;
  margin: auto;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000 !important;
}

.ant-tabs-tab .ant-tabs-tab-active {
  background-color: red !important;
}

.ant-tabs .ant-tabs-tab {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d4353;
}

.ant-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #3d4353 !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: #3d4353 !important;
}

.dash-board {
  padding-top: 20px;
}

.dash-bord-heading h2 {
  color: #2d303a;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.dashboard-container {
  max-width: 1120px;
  margin: auto;
}

.search-bag-color {
  background-color: gray !important;
}

button.btn-search-top {
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.adv-search-bnt-ps button {
  width: 160px;
  height: 40px;
  border: none;
  padding: 8px 16px;
  height: 40px;
  border-radius: 4px;
  background: var(--gradient-syenah,
      linear-gradient(259deg, #04ddbc 1.11%, #0096cd 98.79%));
}

.search-example {
  width: 100%;
}

.dash-search {
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  gap: 5px;
}

/* .update-prof-ps {
  display: flex;
  gap: 20px;
} */
.update-prof-ps {
  margin-top: 32px;
  padding-bottom: 30px;
}

.up-prof-left-side {
  border-radius: 8px;
  border: 1px solid #e4e6ed;
  background: #fff;
  padding: 16px;
}

.up-date-text-ps h4 {
  color: #0d0d0e;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-bottom: 1px solid #e4e6ed;
  padding-bottom: 11px;
}

.sub-text-port p {
  color: #3d4353;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.search-example input[type="text"] {
  border-radius: 4px 0px 0px 4px !important;
  border: 1px solid #ced2e0;
  background: #fff;
}

.search-bag-color {
  background-color: #1fa2d4 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.sub-text-port {
  margin-top: 10px;
  border-radius: 6px;
  background: #f7f8fa;
  padding: 40px 0px;
}

.sub-text-port p {
  color: #3d4353;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 8px;
}

.port-folio-right-side {
  background-image: url("../Assets/Images/dashbord-bg-image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 20px;
  margin-left: 30px;
}

.port-details h5 {
  color: #05476f;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.port-rules ul li {
  color: #2d303a;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 23px;
  line-height: 24px;
  list-style: none;
  display: flex;
  gap: 8px;
}

.port-rules ul {
  padding: 15px 16px 0px 0px;
  margin: 0px;
}

.incidentCat:hover {
  color: #0195ce;
}

.InnerDiv {
  padding: 20px;
}

.InnerSpan {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #07132f;
}

.MainDivSocial {
  display: flex;
  flex-direction: column;
}

.mainContent p {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d303a;
}

.mainContent p span {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0d0d0e;
}

.headerStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #07132f;
}

.socialChart {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mainLegendDiv {
  display: flex;
  justify-content: center;
  /* align-items: flex-start; */
  /* align-content: flex-start; */
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 48px;
}

.mainUNDiv {
  display: flex;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  /* margin-top: 12px; */
  /*des change */
  align-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.legendSpan {
  color: #3d4353;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  /* 133.333% */
}

.reactionAnalyses {
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 0px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--primary-75-a-200, #b9e0f0);

  background: linear-gradient(0deg, #e6f7ff, #e6f7ff),
    linear-gradient(0deg, #b9e0f0, #b9e0f0);
}

.reactionAnalysesSpan {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #0195ce;
}

.sq104px {
  height: 104;
  width: 104;
}

.unSpan {
  padding-left: 16px;
  padding-top: 14px;
  text-align: start;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  color: #036e9e;
}

.UNclass {
  display: flex;
}

.unSpanCount {
  padding-top: 16px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 16px;
}

.unSpanPrinciple {
  /*des change */
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Grays-500, #4e515c);
}

/* .unSpanPrinciple {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
} */
.unSpanTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.color05476F {
  color: #05476f !important;
}

.companiesDataDiv {
  display: flex;
  margin-top: 4px;
  padding: 16px 16px 16px 24px;
  background: #f7f8fa;
  justify-content: space-between;
}

.companiesDataDiv .span .b {
  cursor: pointer;
}

.titleFont16 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.pl-16 {
  padding-left: 16px;
}

.principleBox {
  padding: 16px 16px 16px 24px;
  border-radius: 7px;
  background: #f7f8fa;
  margin-bottom: 16px;
}

.headerDiv {
  display: flex;
  justify-content: space-between;
}

.principleTitle {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #676a73;

  /* //styleName: Web/Body - selected;
font-family: Inter;
font-size: 13px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left; */
}

.principleSubTitle {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d4353;
}

.titleDiv {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #07132f;
}

.indexCircle {
  aspect-ratio: 1;
  display: flex;
  font-size: 12px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #e4e6ed;
}

.pt32 {
  padding-top: 32px;
}

.gridInput {
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e4e6ed;
  padding: 12px;
  width: 100%;
}

/* Shubham */
.width280 {
  width: 280px !important;
}

.width532 {
  width: 532px !important;
}

.width144 {
  width: 144px !important;
}

.width36 {
  width: 36px !important;
}

.gridInput :focus-visible {
  border: 1px solid rgba(1, 149, 206, 1) !important;
}

.width532_border_custom {
  background: #f7f8fa;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 40px;
}

.pt16 {
  padding-top: 16px;
}

.pt12 {
  padding-top: 12px;
}

.pt20 {
  padding-top: 20px;
}

.pt24 {
  padding-top: 24px;
}

.pt0 {
  padding-top: 0 !important;
}

.pb139 {
  padding-bottom: 139px;
}

.portfolio_container_1 {
  padding: 24px 24px;
  border: 0px, 0px, 1px, 0px;
}

.heading_portfolio {
  color: #0d0d0e;
  /* Web/H 4 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 140% */
  padding-left: 12px;
  padding-right: 8px;
}

.main_heading_container_portfolio {
  background: #f7f8fa;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
}

.ant-collapse.ant-collapse-icon-position-end.sub_collapse {
  width: 100%;
}

.sub_collapse.ant-collapse>.ant-collapse-item,
.ant-collapse.ant-collapse-item.ant-collapse-header {
  border-radius: 8px !important;
  width: 100%;
}

.ant-collapse.ant-collapse-icon-position-end.sub_collapse.greater_index_0 {
  padding-top: 8px;
}

.collapse-section-ps .sub_collapse.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}

.backgroundTransparent {
  background: transparent !important;
}

.sub_collapse.ant-collapse>.ant-collapse-item {
  padding: 16px 24px !important;
}

.piechart-portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
}

.piechart-portfolio_sub {
  height: 100%;
  width: 100%;
}

.pie-portfolio-chart-container {
  padding: 24px;
}

.echarts-for-react.pieChart_portfolio {
  height: 440px !important;
}

.progress_portfolio_first.ant-progress .ant-progress-success-bg,
.progress_portfolio_first.ant-progress .ant-progress-bg {
  background: linear-gradient(-90deg,
      #b70000 0%,
      #b70000 62.14%,
      #b70000 100%,
      #b70000 100%) !important;
}

/* .progress_portfolio_sec.ant-progress .ant-progress-success-bg,
.progress_portfolio_sec.ant-progress .ant-progress-bg {
  background: linear-gradient(
    90deg,
    #1a9c3e 0%,
    #ffc000 22.98%,
    #f28c28 48.22%,
    #d00000 100%
  ) !important;
} */

.progress_portfolio_first.ant-progress-line {
  margin-inline-end: 0 !important;
  margin-bottom: 15px !important;
}

.progress_portfolio_first.ant-progress .ant-progress-inner {
  background: #fad4ae !important;
}

.custom_container_portfolio_1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.risk_severty_container {
  height: 241px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.risk_severty_container svg {
  overflow: visible;
  scale: 0.6;
  width: 374px;
}

.chart-bottom-legend-New {
  padding: 0px 8%;
  justify-content: space-between;
  margin-bottom: 10px;
}

.autocomplete-input-search .ant-input-group>.ant-input:first-child {
  border-radius: 6px !important;
  border: 1px solid var(--grays-50, #e4e6ed) !important;
  background-color: transparent;
  width: 289px;
  padding-left: 29px;
}

.autocomplete-input-search .ant-input-group>.ant-input:first-child::placeholder {
  color: var(--grays-400, #676a73);

  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.serchmedia-icon {
  background-image: url("../../src/Assets/Images/search-table.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-position: 3% 50%;
}

.autocomplete-input-search span.ant-input-group-addon {
  display: none !important;
}

.ant-select-single.ant-select-lg .ant-select-selector {
  display: block !important;
}

.slider-container.triWidth {
  width: 320px;
}

.color-pop.ant-popover {
  min-width: 160px !important;
}

/* .margin72{
  margin-bottom: 72px;
} */

.success.go2072408551 {
  border-radius: 6px !important;
  border: 1px solid #168535 !important;
  background: #d1ebd8 !important;
  box-shadow: 0px 8px 25px 1px rgba(108, 128, 147, 0.25) !important;
  padding: 8px 50px 8px 10px !important;
}

.go2072408551 {
  border-radius: 6px !important;
  border: 1px solid #d00000 !important;
  background: #f8d9d9 !important;
  box-shadow: 0px 8px 25px 1px rgba(108, 128, 147, 0.25) !important;
  padding: 8px 50px 8px 10px !important;
}

.go685806154 {
  display: none !important;
}

.ant-radio-button-wrapper {
  padding-inline: 12px !important;
}

.fontColor.ant-select-single.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: #80838b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.optionFontStyle {
  color: #07132f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.fontColor.ant-select-single.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  color: #80838b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.custom-dropdown .ant-select-item-option {
  color: #07132f;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.fontColor.ant-select-single.ant-select-single .ant-select-selector .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.88);
}

.ant-popover {
  z-index: 1090 !important;
}

.companiesDataDivNum {
  border-radius: 100px;
  background: #eff1f6;
  margin-right: 8px;
  width: 20px;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
}

.companiesDataDivName {
  margin-right: 8px;
}

.white_parent .ant-breadcrumb-separator {
  color: #fff !important;
}

.parent_className .ant-breadcrumb-separator {
  color: #b6bcc7 !important;
}

label.ant-radio-wrapper.portfolio_radio {
  color: #0d0d0e;
  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

label.ant-checkbox-wrapper.portfolio_checkboxes {
  color: #0d0d0e;
  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.bubble-portfolio-chart-container {
  padding: 15px;
}

.port-bot-spacing {
  margin-bottom: 24px;
}

.port-page-bot-spacing {
  padding-bottom: 24px;
}

/*End of shubham block*/

.settingsDiv {
  display: flex;
  height: 32px;
  padding: 8px 0px 8px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.pt48 {
  padding-top: 48px;
}

/* New Company Page CSS Start (- By Shivam) */
.company-detail-collapse {
  border: none;
  margin-top: 0px;
}

.company-detail-collapse .ant-collapse-item {
  border: none;
  background: white;
}

.company-detail-collapse .ant-collapse-content {
  border: none;
}

/* D3 Charts CSS  */
.sun-chart-container p {
  padding: 17px;
}

.sun-chart-container-tooltip {
  position: absolute;
  font-size: 10px;
  background: #ffffff;
  border-radius: 11px;
  padding: 15px;
  pointer-events: none;
  box-shadow:
    0px 8px 12px 1px rgba(0, 0, 0, 0.1),
    0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #4e515c;
}

.sunchart-tooltip-head-green {
  color: #80838b;
  display: flex;
  align-items: center;
}

.sunchart-tooltip-head-green span:first-child {
  height: 13px;
  width: 12px;
  background-color: #6ebd20;
  margin-right: 10px;
}

.sunchart-tooltip-head-violet {
  color: #80838b;
  display: flex;
  align-items: center;
}

.sunchart-tooltip-head-violet span:first-child {
  height: 13px;
  width: 12px;
  background-color: #5632b4;
  margin-right: 10px;
}

.sunchart-tooltip-head-blue {
  color: #80838b;
  display: flex;
  align-items: center;
}

.sunchart-tooltip-head-blue span:first-child {
  height: 13px;
  width: 12px;
  background-color: #4385e7;
  margin-right: 10px;
}

.sunchart-tooltip-sub-heading {
  padding: 10px 0;
}

.sun-chart-container-tooltip ol {
  padding-left: 1rem;
}

.sun-chart-container-tooltip ol li {
  padding: 4px;
}

.sun-chart-container-tooltip .tooltip-item-label {
  color: #0d0d0e;
  text-align: right;

  font-style: normal;
  font-weight: 500;
}

.sunchart-tooltip-item {
  display: flex;
  justify-content: space-between;
}

.sunchart-tooltip-foot {
  background-color: #f3bfbf;
  border-radius: 4px;
  padding: 12px;
  color: #5e0000 !important;
}

.company-chart-circle-text {
  pointer-events: none;
}

.companyChartSvgContainer {
  zoom: 1.5;
  text-align: center;
}

.company-page-collable-label-text {
  color: #b6bcc7;
  padding-right: 15px;
}

.company-collapse-header .ant-collapse-header {
  align-items: center !important;
}

.info-icon-company-page {
  margin-left: 5px;
  vertical-align: sub;
}

.supply-chain-incident-column {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.theme-link-style {
  color: #0281b6;
  text-decoration: underline;
}

.theme-dark-text {
  color: #07132f;
  font-weight: 500;
  font-family: Inter;
}

.incident-table-tabs .ant-tabs-tab {
  background: #ffffff !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0 !important;
}

.incident-table-tabs .ant-tabs-tab-active {
  background: #eff1f6 !important;
  color: #07132f !important;
  font-weight: 600 !important;
}

.incident-table-tabs .ant-tabs-tab-btn:focus:not(:focus-visible) {
  color: #07132f !important;
}

.incident-table-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.Antdtable-component th.ant-table-cell {
  /*des change */
  padding: 5px 6px !important;
  background: #f5f6fa !important;
  color: var(--Grays-600, #3d4353) !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 16px;
  padding: 16px 11px !important;
}

/* .Antdtable-component th.ant-table-cell {
  padding: 5px 6px !important;
  background: #f5f6fa !important;
} */
.Antdtable-component tr:nth-child(even) {
  background: #f5f6fa;
}

.Antdtable-component tr:nth-child(odd) {
  background: #ffffff !important;
}

.incident-timeline-chart {
  padding: 12px;
}

.incident-timline-graph-year-legend-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  width: 100%;
  padding-top: 10px;
}

.incident-timline-graph-year-legend-container span {
  text-align: center;
  border-right: 1px solid #ced2e0;
  color: #676a73;
  font-weight: 500;
  cursor: pointer;
}

.incident-timline-graph-year-legend-container span.active {
  color: #0d0d0e;
  font-weight: 700;
}

.carousel-container-incident-timeline {
  width: 75%;
  padding-top: 10px;
}

.carousel-container-incident-timeline span.carousel-item {
  width: 100%;
  text-align: center;
  display: block;
  color: #0d0d0e;
  font-weight: 500;
}

.carousel-controls-custom {
  padding-top: 10px;
}

.carousel-controls-custom button {
  background-color: #b9e0f0;
  border: 1px solid #8dcfe9;
}

.carousel-controls-custom button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}

.carousel-controls-custom button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
}

.distribution-by-cat-severity-graph {
  padding-bottom: 30px;
}

.chart-card-head-subtitle {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d303a;
}

.peer-company-comparision-chart-container {
  padding: 16px;
}

.chart-cart-head-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #4e515c;
  margin: 0;
}

/* New Company Page CSS END (- By Shivam) */

/* css dashboardnew */

.box-flex-ps {
  display: flex;
}

.create-port-btn button {
  border-radius: 4px;
  background: #0195ce;
  color: #fff;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: none;
  padding: 8px 16px;
}

.das-right-graph img {
  width: 100%;
}

.supply-text-ps p {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.supply-ch-heading h5 {
  color: var(--turquoise-900, #136160);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 10px;
}

.take-look button {
  color: var(--turquoise-800, #1b8886);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  background: var(--turquoise-100, #b3eae9);
  border: none;
  padding: 8px 16px;
}

.take-look {
  margin-top: 17px;
}

.supply-chain-right-side {
  background-image: url("../Assets/Images/green-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 20px;
  margin-left: 30px;
  height: 188px;
}

.my-portifolios {
  margin-top: 42px;
}

.watchlist-right-side {
  background-image: url("../Assets/Images/watch-list-side.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 0px;
  margin-left: 30px;
  height: 253px;
}

.watch-list-heading-ps h5 {
  color: var(--violet-900, #331e6a);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.watchlist-create-ps button {
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  background: var(--violet-600, #5634b4);
  padding: 8px 16px;
  border: none;
}

.watch-list-heading-ps {
  padding: 18px;
}

.tab-section-ps .ant-tabs-tab {
  padding: 8px 7px !important;
}

.tab-section-ps .ant-tabs-tab-active {
  border-radius: 4px 4px 0px 0px !important;
  background: #f7f8fa !important;
}

.tab-section-ps .ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 16px !important;
}

.tab-section-ps .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0d0d0e !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.data-update-pro h4 {
  color: #0d0d0e;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.data-update-pro button {
  color: #0195ce;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border: none;
  background: transparent;
  outline: none;
}

.data-update-pro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6ed;
  padding-bottom: 12px;
}

.map-portfolio {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.over-view-heading h5 {
  color: #07132f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.portfolio-over-view-sec {
  margin-left: 30px;
}

.over-view-heading p {
  color: #4e515c;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.over-view-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tab-box h6 {
  color: #3c6812;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.tab-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #e4e6ed;
  background: #fff;
  padding: 10px 16px;
  margin-bottom: 8px;
  cursor: pointer;
}

.tab-box p {
  color: #3c6812;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.tabs-over-view {
  margin: 16px 0px 32px 0px;
  display: grid;
  grid-template-columns: auto auto;
  gap: 16px;
}

.tab-box h6 span img {
  margin-right: 5px;
}

.tab-box.social h6 {
  color: #145797;
}

.tab-box.social p {
  color: #145797;
}

.tab-box.governance h6 {
  color: #3b237b;
}

.tab-box.governance p {
  color: #3b237b;
}

.tab-box.medium h6 {
  color: #794614;
}

.tab-box.medium p {
  color: #794614;
}

.tab-box.severity h6 {
  color: #720000;
}

.tab-box.severity p {
  color: #720000;
}

.tab-box.low-severity h6 {
  color: #735600;
}

.tab-box.low-severity p {
  color: #735600;
}

.tab-box:hover {
  background: #f7f8fa;
}

.high-sev-top h5 {
  color: #07132f;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 8px;
}

.last-login p {
  color: #4e515c;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.last-login p span {
  color: #4e515c;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.last-login button {
  color: #0195ce;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  background: transparent;
  border: none;
  outline: none;
}

.high-sev-top {
  background: #f7f8fa;
  padding: 16px 24px;
  border-bottom: 1px solid #e4e6ed;
}

.last-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.high-severity-incidents {
  border-radius: 8px;
  border: 1px solid #e4e6ed;
}

.last-login button span {
  margin-left: 8px;
}

.side-sapacing-box {
  padding: 0px 16px;
}

.involved-companies {
  padding: 12px 0;
}

.message-timing {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.involved-companies {
  padding: 12px 0;
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #e4e6ed;
}

.message-timing p {
  color: #676a73;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  gap: 8px;
}

.message-timing p span {
  color: #2d303a;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.message-timing label {
  max-width: 18%;
  color: #80838b;
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

p.comp-dis-message {
  color: #0d0d0e;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 5px 0px;
  cursor: pointer;
}

p.comp-dis-message:hover {
  color: #0195ce;
}

.chart-user-ps {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.chart-user-ps p {
  color: #80838b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.watchlist-left-side-box {
  border-radius: 8px;
  background: #e5f7ff;
  padding: 12px 16px;
  margin-top: 15px;
}

.watchlist-left-side-box h4 {
  color: #05476f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-bottom: 8px;
}

.watchlist-left-side-box p {
  color: #05476f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.home-left-list ul li a span {
  padding-right: 7px;
  color: #b6bcc7;
}

.collapse-section-ps .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 32px 0px !important;
}

/* .collapse-section-ps .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 0px !important;
} */

.collapse-section-ps .ant-collapse {
  background-color: transparent !important;
  border: 0px !important;
  border-bottom: 0 !important;
  border-radius: px !important;
}

.collapse-section-ps .ant-collapse>.ant-collapse-item {
  border-bottom: none !important;
}

.collapse-section-ps span.ant-collapse-header-text {
  color: #0d0d0e;
  font-size: 20px;
  font-style: normal;
  font-weight: 600 !important;
  /*des change */
  line-height: 28px;
  font-family: Inter;
}

.collapse-section-ps {
  padding: 0px 0px;
}

/* .collapse-section-ps {
  padding: 24px 0px;
} */

.per-company p {
  color: #2d303a;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.risk-score {
  border-radius: 8px;
  border: 1px solid #e4e6ed;
}

.per-company {
  background: #f7f8fa;
  padding: 20px 24px;
  border-bottom: 1px solid #e4e6ed;
}

.collapse-section-ps .ant-collapse .ant-collapse-content {
  border-top: none;
}

.ten-cent-box-ps {
  display: flex;
  justify-content: space-between;
}

.comp-name-ps p span {
  color: #3d4353;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  padding: 2px 6px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border-radius: 100px;
  background: #eff1f6;
  margin-right: 5px;
}

.comp-name-ps p {
  color: #07132f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.prog-bar p {
  color: #b70000;
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.prog-bar {
  width: 271px;
  display: flex;
}

.prog-bar .ant-progress-bg {
  background: linear-gradient(87deg,
      #1a9c3e 0%,
      #ffc000 22.98%,
      #f28c28 48.22%,
      #d00000 100%);
}

.prog-box-ps {
  padding: 24px;
}

.risk-score .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px 0px !important;
}

/* .incidents-prog-bar .ant-progress-bg {
  background: linear-gradient(
    180deg,
    #b70000 0%,
    #bb0a03 62.14%,
    #f28c28 100%,
    #f28c28 100%
  );
} */

.incidents-prog-bar .ant-progress-bg {
  background: linear-gradient(
      /*des change */
      294deg,
      #b70000 0%,
      #bb0a03 62.14%,
      #f28c28 100%,
      #f28c28 100%);
}

.main-worning-div {
  display: flex;
  gap: 9px;
}

.collapse-section-ps span.anticon.anticon-right.ant-collapse-arrow svg {
  background-image: url("../Assets/Images/gray-errow-btn.svg");
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  transform: rotate(1deg) !important;
}

.collapse-section-ps span.anticon.anticon-right.ant-collapse-arrow svg path {
  display: none;
}

.collapse-section-ps .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon {
  height: 37px !important;
}

.collapse-section-ps .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 0px 0px !important;
}

/* .collapse-section-ps
  .ant-collapse
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 16px 0px !important;
} */

.risk-view-more button {
  border-radius: 4px;
  border: 1px solid #b9e0f0;
  background: #e5f7ff;
  width: 100%;
  padding: 8px 16px;
  color: #0195ce;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  margin-top: 32px;
}

.comp-name-ps p:hover {
  color: #0195ce;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.collapse-section-ps .ant-progress-line {
  margin-bottom: 26px;
  display: flex;
  gap: 14px;
  align-items: center;
  flex-direction: row-reverse;
}

.collapse-section-ps .ant-progress .ant-progress-text {
  /*des change */
  position: absolute;
  left: -6px;
  top: 5px;
  color: var(--Red-600, #b70000);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
}

.incidents-prog-bar .ant-progress-inner {
  background: #fad4ae;
}

.incidents-prog-bar p {
  color: #676a73;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.rotated {
  transform: rotate(180deg);
  /* Additional styles for the rotated state if needed */
}

.SearchInput {
  width: 304px;
  height: 40px;
  padding: 8px 157px 8px 12px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: linear-gradient(0deg, #e7e9f0, #e7e9f0),
    linear-gradient(0deg, rgba(247, 248, 250, 0.85), rgba(247, 248, 250, 0.85));
  border: 1px solid rgba(228, 230, 237, 1);
}

.containerMidWidth {
  width: 304px;
  max-width: 304px;
  min-width: 304px;
  overflow: auto;
}

.ml-32 {
  margin-left: 32px !important;
}

/* facebook chart css */
.total-reaction-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.over-time {
  width: 100%;
}

.carousel-controls-custom {
  padding-top: 10px;
  padding-right: 17px;
}

.line-chart-twitter p {
  color: var(--primary-900, #05476f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.sort-by .ant-popover-inner {
  background: #fff;
  padding: 0px;
}

.set-by-popover {
  padding: 12px 24px 12px 10px;
  width: 200px;
}

.set-title-ps p {
  color: var(--grays-300, #80838b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 0;
}

.set-by-list ul li {
  list-style: none;
  padding-left: 0px;
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 13px;
  cursor: pointer;
}

.set-by-list ul {
  padding: 0px;
}

.replies-sections img {
  cursor: pointer;
}

.inc-bad-link img {
  padding: 0px 0px 0px 6px;
}

/*Sahil*/

.Principle-date-color {
  color: blue;
}

.text-list .list .date-color {
  transition-duration: 0.5s;
  color: #2d303a;
}

.active-item p {
  font-weight: 600;
}

.main-sidebar-div li.ant-menu-item.selected {
  padding: 12px 8px !important;
}

.main-sidebar-div li.ant-menu-item {
  padding: 12px 8px !important;
}

p.name-of-the-comp {
  color: var(--grays-500, #4e515c);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

span.no-companies-add-ps {
  color: var(--grays-400, #676a73);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.w-add-com-link p {
  display: flex;
  justify-content: center;
  gap: 9px;
}

.empty-watch-list-ps {
  padding-right: 32px;
}

.witch-right-side-ps {
  padding-left: 30px;
}

.foot-sec p {
  color: var(--Primary-Primary-500, #0195ce);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.ant-switch.ant-switch-checked {
  background: #0195ce;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0195ce;
}

.rename-modal-ps .ant-modal-content {
  padding: 16px 0px !important;
}

/* .rename-modal-ps .ant-modal-header {
  padding: 0px 24px;
} */

.rename-modal-ps .ant-modal-title {
  padding: 0px 24px;
}

.rename-modal-ps .ant-modal-close {
  top: 16px;
  right: 24px;
}

.rename-modal-ps .ant-input-affix-wrapper {
  padding: 12px 12px;
}

.delete-watchlist-ps .ant-modal-content {
  padding: 20px 0px;
}

.delete-watchlist-ps .ant-modal-header {
  padding: 0px 24px;
}

.delete-watchlist-ps .ant-modal-close {
  top: 23px;
}

.delete-watchlist-ps .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.download-samp-file-ps p a {
  color: var(--Grays-300, #80838b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.download-samp-file-ps {
  padding-top: 12px;
}

.download-samp-file-ps p span img {
  margin-right: 8px;
}

.add-location-os-ps .ant-modal-header {
  padding: 16px 24px;
  margin-bottom: 0px;
}

.blue-name-of-the-comp {
  color: var(--Primary-800, #045b86);

  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.no-alert-data {
  color: #676a73;

  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.rename-modal-ps .ant-modal-footer {
  padding-right: 24px;
  padding-left: 24px;
}

/* .delete-incident-page-modal .ant-modal-footer{
  justify-content: center !important;
  display: flex;
  padding: 0px;
} */

.severity-collection-box {
  height: 25px !important;
  border-radius: 2px;
  background: var(--Red-600, #b70000);
  width: 40px;
  padding: 5px 0px;
  text-align: center;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.locations-name-col {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.date-discovery-time {
  color: var(--Grays-500, #4e515c);
  text-align: left;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.delete-incident-table-icon img {
  cursor: pointer;
}

.blue-name-of-the-comp img {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

.add-company-two-tabs {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: center;
  /* display: none; */
}

.add-company-two-tabs-show {
  display: block;
}

.watchlist-empty-text p {
  color: var(--Grays-400, #676a73);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.watchlist-empty-text p span {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.download-samp-file-ps p span img {
  cursor: pointer;
}

.watch-page-top-space {
  padding-top: 12px;
}

.in-put-popup {
  position: relative;
}

.in-put-popup span.ant-input-prefix {
  position: absolute;
  right: 8px;
  top: 13px;
  z-index: 5555;
  cursor: pointer;
}

.in-put-popup input.ant-input {
  background: transparent;
}

.edit-rename:hover {
  background: var(--Grays-25-A100, #eff1f6);
}

.delet-btn-in:hover {
  background: var(--Grays-25-A100, #eff1f6);
}

.mt-32 {
  margin-top: 32px !important;
}

.subtitle1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(78, 81, 92, 1);
}

.buttonM {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(1, 149, 206, 1);
}

.sliderContent {
  border-radius: 8px;
  display: flex;
  padding: 12px 16px;
  margin: 66px 16px 0px 16px;
  gap: 8px;
  background: #ffffff;
  max-width: 656px;
}

.sliderContent span {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d303a;
  margin-left: 16px;
}

.headerSubTitle {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pl-16 {
  padding-left: 16px;
}

.CaptionRegularFit {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #80838b;
}

.rlLayoutDiv {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #f7f8fa;
}

.srNoView {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #e4e6ed;
}

.ml-12 {
  margin-left: 12px;
}

.color0195CE {
  color: #0195ce;
}

.captionXl {
  width: 200px;
  height: 32px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #07132f;
}

.captionS {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #3d4353;
}

.noDataLayout {
  width: 100%;
  padding: 24px 224px 24px 225px;
  border-radius: 8px;
  background: #f7f8fa;
}

.noDataText {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #4e515c;
}

.header-add-button button {
  border-radius: 4px;
  background: var(--primary-400, #1fa2d4);
  border: none;
  outline: none;
  padding: 6px 12px 6px 12px;
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.add-header-btn {
  display: flex;
  justify-content: space-between;
  width: 60vw;
}

.SearchInput {
  width: 304px;
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: linear-gradient(0deg, #e7e9f0, #e7e9f0),
    linear-gradient(0deg, rgba(247, 248, 250, 0.85), rgba(247, 248, 250, 0.85));
  border: 1px solid rgba(228, 230, 237, 1);
}

.SearchInputNew {
  width: 304px;
  height: 40px;
  border-radius: 6px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  background-color: linear-gradient(0deg, #e7e9f0, #e7e9f0),
    linear-gradient(0deg, rgba(247, 248, 250, 0.85), rgba(247, 248, 250, 0.85));
  border: 1px solid rgba(228, 230, 237, 1);
}

.SearchInputNew.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
}

.SearchInputNew:where(.css-dev-only-do-not-override-pr0fja).ant-select-single .ant-select-selector {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.SearchInputNew:where(.css-dev-only-do-not-override-pr0fja).ant-select-single {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #07132f;
}

.SearchInputNew:where(.css-dev-only-do-not-override-19gw05y).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px !important;
  padding: 0 11px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.SearchInputNew.ant-select-selector {
  height: 40px !important;
}

.feedbackLayout {
  border-radius: 4px;
  background-color: #f7f8fa;
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.feedbackSubmitBtn {
  padding: 6px 16px;
  flex-shrink: 0;
  background-color: #0195ce;
  color: #fff;
  display: flex;
  align-items: center;
}

.feedbackSubmitBtn:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff !important;
}

.feedbackSubmitBtn:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default:disabled,
:where(.css-dev-only-do-not-override-2i2tap).ant-btn-default.ant-btn-disabled {
  background-color: #8dcfe9;
}

:where(.css-2i2tap).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
}

.feedbackBtn {
  color: #80838b;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px;
  /* 133.333% */
  cursor: pointer;
}

.feedbackInput {
  padding: 16px;
  display: flex;
  width: 100%;
  gap: 12px;
  flex-direction: column;
}

.feedbackBtn:hover {
  color: #0195ce !important;
}

.feedbackBtn:active {
  color: #036e9e !important;
}

/* Web/Body */
.feedbackBody {
  color: #676a73;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.disLikeImg img {
  transition:
    filter 0.3s ease,
    fill 0.3s ease;
  /* Add a smooth transition effect for both filter and fill */
}

.disLikeImg img:hover {
  filter: grayscale(0%) sepia(0%) invert(80%) saturate(0%) brightness(100%) !important;
}

.apple-pic img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: 46% 29%;
}

.CRR_layout {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;

  /* align-items: center; */
  gap: 16px;
  background: var(--White, #fff);
}

.CRR_LayoutSpan {
  color: #07132f;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  /* 184.615% */
}

.positionRelative {
  position: relative;
  z-index: 999;
}

.factLayout {
  padding: 24px;
  gap: 32px;
}

.innerLayout {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-radius: 2px;
  background-color: #f7f8fa;
}

.bodySelected {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #07132f;
}

.titleVal {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #07132f;
}

.top-main-keywords-ps {
  width: 100%;
  margin-top: 24px;
}

.top-keywords {
  border-radius: 12px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
  width: 100%;
}

.reactions-ps {
  border-bottom: 1px solid var(--grays-25-a-100, #eff1f6);
  background: var(--Screen-background, #f7f8fa);
  padding: 16px 24px 12px 24px;
  border-radius: 10px 10px 0 0;
}

.reactions-ps p {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.aiText {
  padding-left: 4px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #80838b !important;
}

.incident-medium-ps p {
  color: #4e515c;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

.incident-medium-ps h2 {
  font-family: Inter;
  font-size: 42px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: center;
  color: #0d0d0e;
}

.incident-medium-ps button {
  border: none;
  outline: none;
  padding: 6px 16px 6px 16px;
  border-radius: 4px;
  gap: 8px;
  background: #ff7518;
  color: #fff;
}

.incident-medium-ps {
  text-align: center;
}

.incident-severity-inner-sec {
  display: flex;
  align-items: center;
  justify-content: center;
}

.incident-severity-score-ps {
  padding: 32px 24px;
}

.incident-map-desc span {
  color: var(--grays-900, #0d0d0e);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.incident-map-desc p {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.incident-map-desc {
  padding: 32px 0;
}

.view-evaluation-btn-ps button {
  border-radius: 4px;
  border: 1px solid var(--primary-75-a-200, #b9e0f0);
  background: var(--primary-25-a-100, #e5f7ff);
  color: var(--primary-primary-500, #0195ce);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  padding: 8px 16px;
  width: 100%;
}

.esgTitle {
  color: var(--fresh-green-800, #3c6812);
  /* Web/Subtitle 3 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 171.429% */
}

.esgSubTitle {
  color: var(--grays-800, #07132f);
  /* Web/Subtitle 4 */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 171.429% */
}

.like-tag-box-ps {
  border-radius: 4px;
  background: var(--grays-25-a-100, #eff1f6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
}

.like-tag-box-ps h5 {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.like-des-icons {
  display: flex;
  gap: 10px;
}

.tag-deta-protection {
  margin: 24px 0px;
}

.security-ps p {
  color: var(--grays-700, #2d303a);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.security-ps {
  border-radius: 100px;
  background: var(--grays-25-a-100, #eff1f6);
  padding: 4px 12px;
}

.cybersecurity-box-ps {
  margin-top: 24px;
}

.cyber-secu-box {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
}

.associated-text-ps p {
  color: var(--grays-800, #07132f);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.associated-text-ps p span {
  color: var(--primary-primary-500, #0195ce);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.associated-text-ps {
  margin-top: 16px;
}

.supply-chain-container {
  width: 100%;
  max-width: 1320px;
  margin: auto;
}

.cross-modelNEW .anticon svg {
  padding: 3px;
}

.cross-modelNEW {
  width: 100%;
  /* max-width: 752px;  */
}

.cross-modelNEW .ant-modal .ant-modal-content {
  padding: 20px 24px;
}

.success.go2072408551 {
  border-radius: 6px !important;
  border: 1px solid #168535 !important;
  background: #d1ebd8 !important;
  box-shadow: 0px 8px 25px 1px rgba(108, 128, 147, 0.25) !important;
  padding: 8px 50px 8px 10px !important;
}

.go2072408551 {
  border-radius: 6px !important;
  border: 1px solid #d00000 !important;
  background: #f8d9d9 !important;
  box-shadow: 0px 8px 25px 1px rgba(108, 128, 147, 0.25) !important;
  padding: 8px 50px 8px 10px !important;
}

.go685806154 {
  display: none !important;
}

.comp-name-tex-new {
  width: 100%;
}

.comp-name-tex-new h5 {
  font-style: normal;
  font-weight: 600;

  font-size: 13px;
  color: #2d303a;
  margin: 0px;
  background: #e5f7ff;
  border-radius: 4px;
  padding: 10px;
}

.comp-name-tex-new h5 img {
  margin-right: 7px;
}

.comp-name-tex-new ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
}

.comp-name-tex-new ul {
  margin-top: 8px;
}

.comp-name-tex-new p {
  margin: 12px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
  text-align: start;
}

.comp-name-tex-new ul li img {
  margin-left: 5px;
}

.comp-name-tex-new ul li img:hover {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(195deg) brightness(100%) contrast(138%);
}

/* severity Score */

.severity-colors-ps {
  display: flex;
  gap: 8px;
  align-items: center;
}

.key-factors-severity h3 {
  color: var(--Grays-500, #4e515c);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.score-severity-ps h2 {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.score-severity-ps {
  margin-top: 16px;
}

.number-of-score-ps h1 {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}

.number-of-score-ps {
  margin-top: 32px;
}

.number-of-score-ps p {
  color: var(--Orange-Orange-500, #f28c28);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.number-of-score-ps {
  margin-top: 8px;
}

.severity-disp-text-ps p {
  color: var(--Grays-400, #676a73);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.severity-graph-sec {
  width: 70%;
}

.supply-chain-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section.supply-chain-top-ps {
  margin-top: 12px;
  margin-bottom: 36px;
}

/* source list*/

.noDataDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.noDataSourceList {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.titleDiv {
  display: flex;
  gap: 12px;
}

.titleDiv span {
  color: var(--Grays-900, #0d0d0e);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 150% */
}

.titleDiv h1 {
  width: 3px;
  height: 24px;
  background-color: #0195ce;
}

/* .sortComponent {
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  border: 1px;
  background-color:#E4E6ED;
} */

.sortComponent:where(.css-dev-only-do-not-override-2i2tap).ant-select .ant-select-arrow .anticon {
  /* margin-bottom: -8px; */
}

.sortComponent.ant-select .ant-select-selection-item {
  color: #80838b !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.apple-num.over-imp {
  background: #b70000;
  border-radius: 2px;
}

.apple-num.over-imp p {
  color: #fff;
  text-align: center;
}

.apple-num p {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin: 0px;
}

.apple-num {
  background: #d00000;
  border-radius: 4px;
  padding: 6px 15px;
}

.message-time img {
  margin-top: -1px;
}

.SourceList.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  color: #0281b6 !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.sortComponent.ant-select .ant-select-arrow {
  font-size: 12px;
  color: #9a9ca2;
  margin-top: -3px !important;
}

.autocomplete-input-search .ant-input-search .ant-input:focus {
  box-shadow: none;
}

.noZindex {
  position: relative;
  z-index: 500;
}

/* source list end*/
/* input  */
.energy-mang-ps span {
  color: var(--Grays-600, #3d4353);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tagViewDiv {
  display: flex;
  overflow-x: auto;
  padding: 6px 6px;
  margin-left: 52px;
  margin-right: 17px;
}

.tagViewDiv::-webkit-scrollbar {
  display: none;
}

.energy-mang-ps {
  border-radius: 4px;
  background: var(--Primary-25-A100, #e5f7ff);
  padding: 6px 8px 6px 8px;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.energy-mang-psimg {
  width: 16px;
  cursor: pointer;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: #c1cece87
}

.relat-type-ps .check-box {
  margin-bottom: 12px;
}

.relat-type-ps {
  padding: 7px 5px 0px 0px;
}

.load-more-ps p {
  color: var(--Grays-300, #80838b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.social-media-loader {
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.global-social-media-loader {
  width: 50px;
}

.select-loader p {
  color: var(--Grays-600, #3d4353);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

/* Loader.css */
.loader.postion {
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 199px;
}

.loader {
  position: relative;
  width: 46px;
  /* Adjust based on your design */
  height: 28px;
  left: -5px;
  /* Adjust based on your design */
}

.circle span {
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  /* Increased font size */
  transition: all 1s ease-in-out;
  /* Smooth transition for the animation */
}

.circle-gl {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.5em;
  /* Increased font size */
  transition: all 1s ease-in-out;
  /* Smooth transition for the animation */
}

#circleE {
  top: 0;
  left: 9px;
  background-color: #6ebd20;
  opacity: 0.8;
  /* Color for circle E */
}

#circleS {
  top: 0;
  left: 22px;
  background-color: #2191fb;
  opacity: 0.8;
  /* Color for circle S */
}

#circleG {
  top: 12px;
  left: 16px;
  background-color: #653dd4;
  opacity: 0.8;
  /* Color for circle G */
}

.modalButtonContainer1 {
  display: flex;
  justify-content: center;
}

.rename-modal-ps1 .ant-modal-header {
  padding: 0px 24px 0px 6px;
}

.rename-modal-ps1 .ant-modal-content {
  padding: 16px 0px 24px 0px !important;
}

.collection-info-text {
  color: var(--Grays-400, #676a73);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 16px 12px 0px 12px;
}

.sidebar-item-wrapper {
  display: flex;
  align-items: center;
}

/* Animation */
@keyframes moveCircles {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(13px, 0);
  }

  50% {
    transform: translate(8px, 12px);
  }

  75% {
    transform: translate(0, 0);
  }
}

@keyframes moveCirclesTwo {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-5px, 12px);
  }

  50% {
    transform: translate(-13px, 0);
  }

  75% {
    transform: translate(0px, 0);
  }
}

@keyframes moveCirclesThree {

  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-7px, -13px);
  }

  50% {
    transform: translate(6px, -12px);
  }

  75% {
    transform: translate(0px, 0);
  }
}

.loader #circleE {
  animation: moveCircles 4s infinite;
}

.loader #circleS {
  animation: moveCirclesTwo 4s infinite;
}

.loader #circleG {
  animation: moveCirclesThree 4s infinite;
}

.tab-top2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px;
  border: 0px, 0px, 1px, 0px;
  gap: 8px;
}

.select_li_sort {
  height: 25px;
  margin-right: 5px;
  opacity: 0;
}

.active_sort {
  opacity: 1;
}

/* sahil block */

.profile-style {
  display: flex;
}

.error-message {
  color: var(--Red-Red-500, #d00000);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0 0 4px;
}

/*  sahil block  */
/* .ant-model .ant-modal-content{
  padding: 0 !important;
  border-radius: 26px !important;
} */
.user-onboarding-modal .ant-modal-content {
  padding: 0 !important;
}

.cross-model1 .ant-modal-content {
  padding: 0px 0px !important;
  border-radius: 24px;
}

.invisible-text {
  display: none;
}

.reset-setting1 {
  width: 100%;
  border-top: 1px solid #e7e9f0;
  margin-top: 17px;
  padding: 16px 0 10px 0px;
}

.table-style.ant-table-wrapper .ant-table-thead>tr>th {
  border-bottom: 1px solid #d2d7e5;
}

.table-style th:nth-child(2) {
  text-align: -webkit-right !important;
}

.table-style th:nth-child(4) {
  text-align: -webkit-right !important;
}

.table-style tr>td:nth-child(2) {
  text-align: -webkit-right !important;
}

.table-style tr>td:nth-child(4) {
  text-align: -webkit-right !important;
}

.table-style.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
  border-bottom: 1px solid #d2d7e5;
}

.table-style.ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
  padding-left: 32px;
}

.offsetContainer span {
  color: #3d4353;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.supply-filter .ant-select-show-search:where(.css-dev-only-do-not-override-2i2tap).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer;
  max-height: 60px;
  min-height: 39px;
  overflow: auto;
}

.supply-filter .ant-select-show-search:where(.css-dev-only-do-not-override-2i2tap).ant-select:not(.ant-select-customize-input) .ant-select-selector::-webkit-scrollbar {
  display: none;
}

.subClass span.ant-select-selection-item {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  background-color: #e5f7ff !important;
}

.comp-name-text_new ul li {
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #2d303a;
}

.comp-name-text_new ul li span {
  cursor: pointer;
}

.check-box label {
  margin: 0px;
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}

.qual-comm span {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.paginateSupply span.anticon.anticon-down {
  border: none;
  border-radius: 100px;
  padding: 3px;
  color: #9a9ca2;
  font-size: 8px;
}

.paginateSupply .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.cstm_height .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
}

.tierClass .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 39px !important;
}

.downloadSample {
  cursor: pointer;
}

.uploadInput[type=file],
/* FF, IE7+, chrome (except button) */
.uploadInput[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

/* incidentsListModal page css */

.incidents-table-modal-ps {
  width: 100%;
  max-width: 1320px;
  margin: auto;
}

.inc-outer-padding {
  padding: 24px;
}

.table-downl-btn img {
  width: 29px;
}

.select-relation-ps .ant-select.ant-select-multiple.ant-select-show-arrow {
  width: 150px;
}

.extra-width-ps .ant-select.ant-select-multiple.ant-select-show-arrow.ant-select-show-search {
  width: 180px !important;
}

.select-relation-ps .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: pointer;
  max-height: 60px !important;
  overflow: auto !important;
  background: transparent;
}

.select-relation-ps .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector::-webkit-scrollbar {
  display: none;
}

.watchModal .ant-modal .ant-modal-content {
  padding: 0px 0px !important;
  /* border-radius: 24px;  */
}

.watchModal .ant-modal-title {
  padding: 0px 0px !important;
}

.advanced-search-modal .ant-modal-content {
  height: 715px !important;
  padding: 0px;
}

.watchModal .md-compy-tc {
  padding: 0 19px;
  height: 500px !important;
  /* watchlist change*/
  overflow-y: scroll;
}

.watchModal .ant-modal-footer {
  margin-top: 0px !important;
}

.searchModalDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 2px 0px 24px;
}

.select-relation-ps .ant-select .ant-select-arrow .anticon {
  width: 9px;
  color: #9a9ca2;
}

.severity-ps-table {
  width: 35px;
  border-radius: 2px;
  background: var(--Red-Red-500, #d00000);
  text-align: center;
  padding: 4px 5px;
}

.severity-ps-table span {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.esg-factors-text-ps p:nth-child(1) span {
  border-radius: 4px;
  background: var(--Fresh-Green-75-A200, #d6edc1);
  padding: 4px 8px;
  color: var(--Fresh-Green-800, #3c6812);
  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.esg-factors-text-ps p:nth-child(2) span {
  border-radius: 4px;
  background: var(--Fresh-Green-75-A200, #c7e3fe);
  padding: 4px 8px;
  color: var(--Blue-800, #145797);

  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.esg-factors-text-ps p:nth-child(3) span {
  border-radius: 4px;
  background: var(--Fresh-Green-75-A200, #e0d8f6);
  padding: 4px 8px;
  color: var(--Violet-800, #3b237b);

  /* Web/Caption XL */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.esg-factors-text-ps p {
  /* color: var(--Fresh-Green-800, #3C6812) !important; */
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
}

.esg-factors-text-ps p:nth-child(2) {
  color: var(--Blue-800, #145797) !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
}

.esg-factors-text-ps p:nth-child(3) {
  color: var(--Violet-800, #3b237b) !important;
  font-family: Inter;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 16px;
}

.companies-name-ps p:hover {
  color: #0195ce !important;
}

.table-txt-sz a span img:hover {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

.table-txt-sz p:hover {
  color: #0281b6 !important;
}

.sir-number-ps h5 {
  color: var(--Grays-200, #9a9ca2);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.select-relation-ps .ant-select-multiple .ant-select-selection-item {
  background: #c7e3fe !important;
}

.select-relation-ps .ant-select-multiple .ant-select-selection-item-content {
  color: #80838b !important;
}

.align-property-ps {
  display: flex;
  align-items: center;
  position: relative;
}

.popover-text-ps {
  border-radius: 6px;
  background: var(--White, #fff);
  box-shadow:
    0px 8px 12px 1px rgba(0, 0, 0, 0.1),
    0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  width: 136px;
  padding: 12px;
}

.place-holder-title-ps {
  display: flex;
  flex-direction: column;
}

.place-holder-title-ps label {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.place-holder-title-ps span {
  color: var(--Grays-300, #80838b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.popover-text-ps img {
  margin-bottom: 8px;
}

.portfolios-popover-ps {
  display: none;
  position: absolute;
  top: 30px;
}

.hover-trigger {
  padding-left: 7px;
}

.hover-trigger:hover+.portfolios-popover-ps {
  display: block;
  z-index: 66;
}

.hover-trigger img {
  transition: filter 0.3s ease-in-out;
  /* Smooth transition on hover */
}

.hover-trigger img:hover {
  filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg) brightness(100%) contrast(76%);
}

.table-box {
  overflow-y: scroll;
  /* height: 70vh; */
}

.select-relation-ps .ant-select-selection-placeholder {
  color: var(--Grays-600, #3d4353) !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.esg-factors-text-ps p {
  margin-bottom: 5px !important;
}

.select-relation-ps {
  position: relative;
}

.col-box-ps img {
  position: absolute;
  top: 6px;
  left: 12px;
  z-index: 1;
}

.add-icons-select-box-ps span.ant-select-selection-placeholder {
  padding-left: 27px;
}

.incident-page-table-modal-ps .ant-modal-content {
  background-color: transparent !important;
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
  pointer-events: auto;
  padding: 0px !important;
}

.incident-page-table-modal-ps {
  width: 100% !important;
  max-width: 1320px !important;
}

tr.table-header-section-ps {
  top: 0;
  position: sticky;
  z-index: 9999;
}

hr.incident-modal-columns-select-divider {
  margin: 3px;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(228, 230, 237, 1);
  padding: 0;
  border-radius: 0;
  opacity: 1;
}

.incident-modal-columns-select .ant-select-item.ant-select-item-option.ant-select-item-option-grouped {
  padding: 0px 12px;
}

.ant-select-item.ant-select-item-group:has(hr.incident-modal-columns-select-divider) {
  display: block;
  min-height: 15px;
  padding: 0;
}

/*maninder css  17  jan */
.top-margin-comp-Scroll {
  margin-top: 15px;
}

.home_progress_portfolio_sec.ant-progress .ant-progress-success-bg,
.home_progress_portfolio_sec.ant-progress .ant-progress-bg {
  background: linear-gradient(90deg,
      #1a9c3e 0%,
      #ffc000 22.98%,
      #f28c28 48.22%,
      #d00000 100%) !important;
}

.riskScoreOverflow {
  max-height: 545px;
  overflow-x: auto;
}

.pie-chart-new {
  width: 100%;
  margin: auto;
  height: 330px;
}

/* dashboard page */

.over-time-sec {
  border-bottom: 1px solid var(--Grays-50, #e4e6ed);
  background: var(--Screen-background, #f7f8fa);
  padding: 20px 24px;
}

.ellips-section-ps div p {
  display: flex;
}

.dasboared-adv-search input {
  border-radius: 4px;
  border: 1px solid var(--Grays-75-A200, #d2d7e5);
  background: var(--White, #fff);
  height: 40px;
}

.dasboared-adv-search .ant-btn-primary {
  color: #fff;
  height: 40px !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  background: var(--Primary-400, #1fa2d4);
}

.adv-search-btn button {
  border-radius: 4px;
  background: var(--Gradient-Syenah,
      linear-gradient(259deg, #04ddbc 1.11%, #0096cd 98.79%));
  border: none;
  outline: none;
  padding: 11px 16px;
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.dasboared-adv-search {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 40px;
}

.des-search-width {
  width: 84%;
}

.dasboared-adv-search .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: var(--Primary-400, #1fa2d4);
}

.new-das-bread {
  margin: 20px 0px;
}

.das-top-header-fixed {
  width: 1192px;
  top: -9px;
  position: fixed;
  z-index: 999;
  background: #fff;
}

.new-dashboared-mg-top {
  margin-top: 137px;
}

.map-text-high-ps {
  color: var(--Grays-600, #3d4353);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

span.map-severity-text-ps {
  color: var(--Grays-800, #07132f);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.tab-number-us-ps span {
  border-radius: 100px;
  background: var(--Grays-25-A100, #eff1f6);
  padding: 4px 8px;
  margin-right: 5px;
  color: var(--Grays-600, #3d4353);
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.tab-number-us-ps {
  display: flex;
  gap: 6px;
  align-items: center;
}

.tab-number-us-ps p {
  color: var(--Grays-500, #4e515c);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.headerDiv p {
  color: var(--Grays-600, #3d4353);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.watchlistModalContent {
  height: 206px;
  overflow-y: auto;
}

.hometilesInput {
  border-radius: 4px;
  border: 1px solid var(--Grays-75-A200, #d2d7e5);
  background: var(--White, #fff);
}

.message-timing {
  width: 100%;
}

.global-loader-dv {
  padding: 20px;
  display: flex;
  margin-top: 30vh;
  justify-content: center;
}

.outer-table-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.gl-p {
  color: #fff;
  font-size: 10px;
}

/* graph */

.top-severity-section {
  border-radius: 12px;
  background: var(--Screen-background, #f7f8fa);
  padding: 32px 24px 35px 24px;
  width: 100%;
  text-align: center;
}

.top-severity-colors-ps {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.top-severity-colors-ps span img {
  width: 12px;
}

.moderate-btn button {
  border-radius: 4px;
  background: var(--Severity-Moderate, #ff7518);
  border: none;
  padding: 6px 16px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.severity-disp-text-ps {
  margin-top: 23px;
}

.moderate-btn {
  margin-top: 10px;
}

.severity-socre-page {
  margin-top: 30px;
  background: var(--Screen-background, #f7f8fa);
}

.number-of-score-ps h2 {
  color: var(--Grays-700, #2d303a);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  margin: 10px 0px;
}

.key-factors-severity {
  margin-top: 21px;
}

.minor-btn button {
  border-radius: 4px;
  background: var(--Severity-Minor, #ffbf00);
  border: none;
  padding: 6px 16px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.esg-disp-text-ps {
  margin-top: 23px;
  display: flex;
  gap: 6px;
}

.esg-disp-text-ps p {
  color: var(--Grays-400, #676a73);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  max-width: 302px;
}

.medium-severity-section {
  border-radius: 12px;
  padding: 33px 24px 80px 24px;
  width: 100%;
  max-width: 362px;
}

.high-btn-ps button {
  border-radius: 4px;
  background: var(--Severity-High, #f00);
  border: none;
  padding: 6px 16px;
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.main-severity-socre-div {
  display: flex;
  justify-content: space-between;
}

.ai-severity-graph-sec {
  width: 70%;
  padding: 50px 20px;
}

.al-avidence {
  border-radius: 12px;
  background: var(--White, #fff);
  padding: 32px;
}

.al-reason h5 {
  color: var(--Grays-500, #4e515c);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
}

.ai-text-disp-ps p {
  color: var(--Grays-900, #0d0d0e);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.index-box-ps span {
  border-radius: 4px;
  background: var(--Primary-50, #d7f0fa);
  padding: 4px 7px;
  color: var(--Primary-900, #05476f);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.number-of-reason {
  display: flex;
  gap: 9px;
  margin-bottom: 15px;
  align-items: flex-start;
}

.errow-image-ps {
  text-align: center;
  margin: 19px 0px 0px 0px;
}

.potfolio-img {
  height: 100px;
  width: 100px;
  margin: 15px;
}

.potfolio-modal {
  height: 550px !important;
  padding: 0px;
  width: 500px !important;
}

.create-potfolio-btn {
  display: flex;
  justify-content: flex-end;
}

.plus-btn-text-ps {
  display: flex;
  align-items: center;
  gap: 6px;
}

.can-btn-goto {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  color: #0195ce;
  border: 0px solid #e7e9f0;
  border-radius: 4px;
  background-color: white;
  padding: 5px 13px;
}

/*  */

.complete-pro-logo {
  margin-top: -173px !important;
}

.inner-supply-box {
  border: 1px solid #e4e6ed;
  padding: 24px;
  border-radius: 12px;
}

.supply-image-xp img {
  width: 42px;
  margin-bottom: 13px;
}

.all-text-box-supply p {
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  color: #676a73;
  font-family: "Inter";
  margin-bottom: 13px;
}

.all-text-box-supply h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 56px;
  font-family: "Inter";
  color: #07132f;
  margin-bottom: 13px;
}

.partners-grid-box {
  display: grid;
  grid-template-columns: auto auto auto;
}

.customers-text-box-ps span {
  font-size: 16px;
  font-weight: 600;
  color: #80838b;
  line-height: 24px;
  font-family: "Inter";
}

.customers-text-box-ps p {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #07132f;
  font-family: "Inter";
}

.customers-text-box-ps {
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-left: 2px solid #eff1f6;
  padding-left: 13px;
}

.companies-button-box-ps {
  padding: 8px 16px 8px 16px;
  background: #e5f7ff;
  border: 1px solid #b9e0f0;
  border-radius: 4px;
  width: 100%;
}

.supply-companies-lest-ps {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 27px;
}

.companies-button-box-ps p {
  color: #0195ce;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.incident-sup-box-ps {
  display: grid;
  grid-template-columns: auto auto;
  gap: 28px;
}

.first-sup-liers-box {
  border: 1px solid #e4e6ed;
  padding: 16px;
  border-radius: 12px;
}

.sup-con-tent-ps {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #2d303a;
  font-family: "Inter";
}

.sup-con-tent-ps p {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #07132f;
  font-family: "Inter";
}

.sup-con-tent-ps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.incident-sup-liers-ps {
  background: #fceded;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}

.star-icon-text-ps p {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #5e0000;
  font-family: "Inter";
  display: flex;
  gap: 8px;
}

.incident-num-text-ps p {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #5e0000;
  font-family: "Inter";
}

.sup-liers-grid-ps {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 16px;
}

.supply-number-side-ps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.supply-chain-act-px .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0195ce !important;
}

.violations-ps h2 {
  font-size: 23px;
  font-weight: 600;
  line-height: 32px;
  font-family: "Inter";
  color: #07132f;
  padding-bottom: 15px;
}

.line-flex-ps {
  width: 2px;
  background-color: #0195ce;
  margin-right: 8px;
}

.Incident_on_ESG {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: right;
}

/* hover ungc  */

.pia-bott-hover p {
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--Grays-500, #4e515c);
  text-align: center;
  cursor: pointer;
  width: "100%";
}

.pia-bott-hover {
  margin-top: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  transition: 0.5s;
}

.hover-content-ps {
  border-radius: 6px;
  background: var(--White, #fff);
  box-shadow:
    0px 8px 12px 1px rgba(0, 0, 0, 0.1),
    0px -2px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 12px;
  width: 56%;
  margin: auto;
  position: absolute;
  left: 25%;
  right: 25%;
  bottom: 34px;
  display: none;
}

.adverse-heading-ps h5 {
  color: var(--Grays-900, #0d0d0e);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 6px;
}

.number-of-impact-ps p {
  color: var(--Grays-500, #4e515c);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  padding: 3px 0;
}

.pia-bott-hover:hover .hover-content-ps {
  display: block;
}

.pia-bott-hover p:hover {
  color: var(--Primary-Primary-500, #0195ce);

  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 145.455% */
}

/* hover ungc  end */

.scrolling-corporate-page {
  /* height: calc(100vh - 320px);
  min-height: 100%;
  overflow: auto;
  position: relative;
  padding: 0px; */
}

.scrolling-corporate-page::-webkit-scrollbar {
  display: none;
}

.viewMoreContainer {
  display: flex;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #b9e0f0;
  background: #e5f7ff;
  margin-top: 32px;
  width: 100%;
  cursor: pointer;
}

.leftDataContainer {
  display: flex;
  align-items: center;
}

.leftDataContainer ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 34px !important;
}

.body {
  color: var(--grays-800, #07132f);
  /* Web/Body */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 184.615% */
}

.BodySelected1 {
  color: #fff;
  /* Web/Body - selected */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  /* 184.615% */
}

.CaptionXLOriginal {
  font-size: 12px;
  line-height: 16px;
  color: #4e515c;
}

.risk_levels_cmpny_table_container {
  display: flex;
  justify-content: end;
  margin: 15px 0;
  align-items: center;
}

.risk_values_container {
  display: flex;
}

.compny_table_child_1 {
  background: #00a36c;
  display: flex;
  justify-content: center;
  width: 56px;
  color: #ffffff;
  font-size: 11px;
  padding: 5px;
}

.compny_table_child_2 {
  background: #ffbf00;
  display: flex;
  justify-content: center;
  width: 56px;
  color: #ffffff;
  font-size: 11px;
  padding: 5px;
}

.compny_table_child_3 {
  background: #ff7518;
  display: flex;
  justify-content: center;
  width: 56px;
  color: #ffffff;
  font-size: 11px;
  padding: 5px;
}

.compny_table_child_4 {
  background: #ff0000;
  display: flex;
  justify-content: center;
  width: 56px;
  color: #ffffff;
  font-size: 11px;
  padding: 5px;
}

.risk-change-ten-per {
  text-align: right !important;
}

label.uplode-button-file {
  border-radius: 4px;
  background: var(--primary-primary-500, #0195ce) !important;
  padding: 10px 16px;
  outline: none;
  border: none;
  color: var(--White, #fff) !important;
  text-align: right;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.download-the-file-ps a:hover {
  color: var(--primary-primary-500, #0195ce) !important;
}

/* popup error */

.invalid-error-ps {
  border: 1px solid #d00000;
  border-radius: 6px;
  background: #fceded;
  padding: 10px 12px;
  margin-top: 21px;
}

.error-massage-ps p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #b70000;
  font-family: "Inter";
}

.error-box-ps {
  display: flex;
  gap: 9px;
  align-items: center;
}

.successfull-ps {
  border: 1px solid #0195ce;
  border-radius: 6px;
  background: #e5f7ff;
  padding: 10px 12px;
  margin-top: 21px;
}

.successfull-massage-ps p {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #0281b6;
  font-family: "Inter";
}

.successfull-box-ps {
  display: flex;
  gap: 9px;
  align-items: center;
}

.loaderCorporate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

/* profile autoComplete */

.profile-page-auto-complete .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  /* width: 100%; */
  height: 40px !important;
  width: 100%;
  border: 1px solid #ced2e0;
  border-radius: 6px;
  padding: 10px !important;
  outline: none;
  font-size: 12px;
  margin-top: 8px;
}

.profile-page-auto-complete .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  display: none;
}

.profile-page-auto-complete .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  display: none;
}

.profile-page-auto-complete .ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #ced2e0;
}

.profile-page-auto-complete .ant-input:focus,
.ant-input:focus-within {
  border-color: #ced2e0;
  box-shadow: none;
  outline: 0;
}

.profile-page-auto-complete .ant-input-group>.ant-input:first-child,
.ant-input-group .ant-input-group-addon:first-child {
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
}

.profile-page-auto-complete .ant-select-single.ant-select-lg {
  width: 100% !important;
}

.own-supply-chain-ps {
  width: 100%;
  margin-bottom: 36px;
}

.supply-cahin-message-ps p {
  background: #e5f7ff;
  padding: 13px;
  border-radius: 8px;
  width: 447px;
  font-size: 14px;
  font-weight: 500;
  color: #4e515c;
  font-family: Inter;
  line-height: 24px;
}

.supply-cahin-message-ps p span {
  font-size: 14px;
  font-weight: 600;
  color: #0195ce;
  font-family: Inter;
  line-height: 16px;
}

.text-input-ps .ant-select-single {
  font-size: 14px;
  height: 32px;
  width: 100%;
}

.incident-list-modal {
  position: fixed;
  inset: 0;
  z-index: 1000 !important;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

/* Initially hide the slider label text */
/* p.slider-Lable-Text {
  display: none;
}

.example-thumb-0:before,
.example-thumb-1:before,
.example-thumb-2:before,
.example-thumb-3:before,
.example-thumb-4:before
{
  cursor: pointer;
}

.example-thumb-4:hover p.slider-Lable-Text ,
.example-thumb-3:hover p.slider-Lable-Text ,
.example-thumb-2:hover p.slider-Lable-Text ,
.example-thumb-1:hover p.slider-Lable-Text ,
.example-thumb-0:hover p.slider-Lable-Text 
{
  display: block;
} */

.map-change-buttons-ps {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.numbers-of-button button {
  border: 1px solid #0195ce;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #0195ce;
  background-color: transparent;
  padding: 4px 22px;
}

.numbers-of-button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.back-and-next div {
  width: 10px;
  height: 10px;
  background: grey;
  display: flex;
  align-items: center;
  border-radius: 5px;
  aspect-ratio: 1;
}

.back-and-next {
  display: flex;
  align-items: center;
  gap: 13px;
  justify-content: center;
}

.back-and-next p {
  font-size: 14px;
  font-weight: 600;
  color: #2f2f2f;
  margin: 0px;
}

.custom-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.custom-buttons button {
  background: #fff;
  border: 1px solid #e7e9f0;
  border-radius: 3px;
  color: #4e515c;
  font-size: 13px;
  padding: 5px 14px;
  outline: none;
  cursor: pointer;
}

button.active-map-button {
  border: 1px solid #0195ce;
  background: transparent;
  color: #0195ce;
}

.top-checkbox {
  display: flex;
  padding: 15px 31px 0px 31px;
  align-items: center;
}

.top-checkboxTitle {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.intervalView {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.countryPicker {
  display: flex;
  align-items: center;
  gap: 5px;

}

.countrySelect {
  height: 40px !important;
  margin-top: 8px;
  width: 70px;
}




.holdings-heading {
  width: 752px;
  background: #F7F8FA;
  border-top: 1px solid #D8DAE0;
  border-bottom: 1px solid #D8DAE0;
}

.nav-div p {
  color: #676A73;
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
}

.grid-holding-system {
  display: flex;
  align-items: center;
}

.first-div {
  width: 5%;
  padding: 15px;
}

.percentage-sc p {
  color: #676A73;
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
}

.nav-div {
  width: 35%;
  padding: 15px;
}

.empty-section {
  width: 23%;
  padding: 15px;
}

.second-empty-div {
  width: 23%;
  padding: 15px;
}

.percentage-sc {
  width: 18.7%;
  padding: 15px;
  display: flex;
  justify-content: center;
  border-left: 1px solid #D8DAE0;
}

.borderClass.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background: inherit;
}

.borderClass.ant-select:not(.ant-select-customize-input) .ant-select-selector:hover {
  background: inherit;
}

.borderClass.ant-select:not(.ant-select-customize-input) .ant-select-selector:active {
  background: inherit;
}

.borderClassInput.ant-input {
  background: inherit !important;
}

.mainClass:hover input.ant-input {
  background: inherit;
}

.mainClass:active input.ant-input {
  background: inherit;
}

.mainClass {
  width: 100%;
}

.mainClass:active {
  background: var(--Color-Surface-surface-3, rgba(239, 241, 246, 1));
}

.mainDivMouse {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.plusBtnNew {
  position: absolute;
  left: -38px;
}

.deleteBtnNew {
  position: absolute;
  right: -38px;
}

.borderClass.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #000 !important
}

.borderClassInput.ant-input-disabled,
.ant-input[disabled] {
  color: #000 !important
}



.showMoreButton {
  color: #4295ce;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin: 8px 0px 8px 0px;
}


.grid-Container {
  display: grid;
  grid-template-columns: 0.2fr 1.8fr 1fr 1fr;
  padding: 10px;
}


.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent !important;
}

.searchDashboardPageSearch .ant-select-selector {
  height: 37px !important;
  border-radius: 0px !important;
}

.searchDashboardPageSearch .ant-select-arrow {
  display: none !important;
}

.map-advanced-search-modal .ant-modal-content {
  padding: 8px 0px !important;
}

.map-advanced-search-modal .ant-modal-header {
  margin-bottom: 13px !important;
}