/* html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  font-family: 'Inter', BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8fa;
  overflow: hidden;
  height: 100%;
}
html {
  height: 100%;
}

#__next {
  height: 100%;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: #3913b8;
  font-weight: 600;
  text-decoration: none;
} */
.g6-minimap-2 {
  position: absolute;
  top: 40px;
  right: 40px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.g6-minimap-viewport {
  border-width: 0px;
}

.g6-component-contextmenu {
  display: none !important;
}

.g6-component-tooltip {
  display: none !important;
}

/* * {
  scrollbar-color: #95afd8 #ced2e0;
  scrollbar-width: thin;
} */

.show-more-less-clickable {
  cursor: pointer;
  color: #1a0dab;
  &:hover {
    text-decoration: underline;
  }
}

.show-more-text {
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  color: #07132f;
  max-height: 48px; /* Set the max height to initially show only one line */
  overflow: hidden; /* Ensure the overflow is hidden */
  transition: max-height 0.3s ease-in-out; /* Add a transition effect to max-height change */
}

.show-more-text.expanded {
  max-height: 1500px; /* Set max-height to none to show all content when expanded */
}
