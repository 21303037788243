.settingActionBox {
  display: flex;
  align-items: center;
}
.settingIcon {
  width: 1vw;
  height: 1vw;
  margin-right: .6vw;
}
.settingText {
  color: #0195ce;
}
