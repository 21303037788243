.chartContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  height: 340px;
  width: 100%;
  overflow: auto;
}

.chartBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}



.total-reaction-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.over-time {
  width: 100%;
}

.carousel-controls-custom {
  padding-top: 10px;
  padding-right: 17px;
}

.ChartDiv{
  width: 100%;
}
