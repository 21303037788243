.container {
  padding: 24px;
  margin: 24px 0px;
  border-radius: 8px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
}
.imgCircle {
  margin-left: 10px;
}

.containerSet {
  padding: 24px;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid var(--grays-50, #e4e6ed);
  background: var(--White, #fff);
}