.checkBoxIconBox {
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.checkBoxIcon {
    width: 100%;
    height: 100%;
}
