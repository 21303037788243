.chartContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  margin-bottom: 5px;
  /* height: 33%; */
  width: 100%;

}

.chartBox {
  width:25%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
