.otp_input {
    width: 40px !important;
    margin-right: 8px !important;
    border-radius: 13px !important;
    height: 50px !important;
    width: 50px !important;
    text-align: center !important;
    font-size: 24px !important;
    border: 0px !important;
}
.resend{
    color: #5FB343;
    text-decoration: underline;
    word-spacing: -3px;
}
.timer{
    margin: 20px 0;
}

.confirmation_text_container {
    padding-top: 24px;
    padding-bottom: 32px;
}

.confirmation_text {
    color: #07132F;
    text-align: center;
    /* Web/Subtitle 4 */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
}


@media (max-width:400px) {
    .otp_input {
        width: 40px !important;
        margin-right: 4px !important;
        border-radius: 13px !important;
        height: 44px !important;
        width: 44px !important;
        text-align: center !important;
        font-size: 16px !important;
        border: 0px !important;
    }
}