.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
}

.social {
    width: 100%;
    display: flex;
    justify-content: center;
}

.innerContainer {
    flex: 1 1 100%;
    border: 2px solid #0195CE;
    border-radius: 10px;
    justify-content: center;
    padding: 10px;
    margin: 2px;
    max-width: 700px;
    width: 100%;

}

.innerContainerENV {
    max-width: 1100px;
}

.button {
    background-color: #E7E9F0;
    border-radius: 40px;
    font-size: 12;
    margin: 0 5px;
    justify-content: center;
    color: #4E515C;
    text-align: center;
    border: 1px solid #DEDEDE;

}

.button:hover {
    background-color: #0195CE;
}