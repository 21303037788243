.advancedSearch1 .ant-modal-content {
    width: 108%;
    padding: 20px 0px;
    height: 750px;
    /* overflow: scroll; */
}

.custom-modal1 {
    width: 100% !important;
    max-width: 800px !important;
}

.custom-modal1.advanced1 div#\:r3\: {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #3d4353;
    padding-left: 18px;
}

.custom-modal1.advanced1:where(.css-dev-only-do-not-override-yp8pcc).ant-modal
  .ant-modal-content {
  padding: 20px 0px !important;
  overflow: scroll;
}

.custom-modal1 {
    top: 30px !important;
}

.advanced-search-modal1 .ant-modal-content {
    /* max-height: 63vh !important;
    padding: 10px;
    overflow-y: scroll; */
    height: 700px;
}

.advanced-search-modal1 .ant-modal-content {
    height: 720px;
    padding: 0px;
}

.cross-model1 {
    width: 100% !important;
    max-width: 752px !important;
  }

.cross-model1 .anticon svg {
    padding: 3px !important;
}

.foot-sec-md1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e7e9f0;
    position: absolute;
    width: 98%;
    /* bottom: -34px; */
    padding: 10px 19px 10px 19px;
    flex-wrap: nowrap;
}

.md-toggle-btn1
  :where(.css-dev-only-do-not-override-12jzuas).ant-switch.ant-switch-checked {
  background: #ced2e0 !important;
}

.md-toggle-btn1
  :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked {
  background: #0195ce;
}

.md-toggle-btn1
  :where(.css-dev-only-do-not-override-yp8pcc).ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: #0195ce;
}

.toggle-emil1
  :where(.css-dev-only-do-not-override-12jzuas).ant-switch.ant-switch-checked {
  background: #1fa2d4 !important;
  border-radius: 100px;
}

button.rest-btn1 {
    background: #0195ce;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
    border: none;
    padding: 5px 17px;
    margin-left: 10px;
}

button.rest-btn1.del-red {
    background: #dc4040;
}

button.rest-btn1.del-red.center-btn {
    margin-right: 150px;
}

.mod-search-bar1 input {
    width: 100%;
    padding: 10px 50px;
    border: none;
    outline: none;
    border-top: 1px solid #e7e9f0;
    border-bottom: 1px solid #e7e9f0;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
}

.search-mad-icon1 {
    background-image: url("../../Assets/Images/search-md.svg");
    background-repeat: no-repeat;
    background-position: 20px 50%;
}

.selecteditem-searchbar1 {
    display: flex;
    max-width: 100%;
    background-color: white;
    overflow-y: scroll;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height: 40px;
}

.selecteditem-searchbar1::-webkit-scrollbar {
    height: 0;
    width: 0;
    /* Set width of the scrollbar */
    background-color: transparent;
    /* Set background color of the scrollbar */
}
  
.selecteditem-searchbar1::-webkit-scrollbar-thumb {
background-color: transparent;
/* Set color of the scrollbar thumb */
}
  
.selecteditem-searchbar1::-webkit-scrollbar-track {
background-color: transparent;
/* Set color of the scrollbar track */
}

.selectedItemBox1 {
    display: flex;
    justify-content: space-between;
    height: 24px;
    padding: 4px;
    margin-left: 10px;
    background-color: #e5f7ff;
    color: #3d4353;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
}

.selectedItemText1 {
    width: 83%;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.deleteSelectedItem1 {
    cursor: pointer;
    color: #b6bcc7;
}

.model-tabs1 .ant-tabs-nav {
    background: #e6f7ff;
    margin-bottom: 0px;
    padding-left: 19px;
}

.model-tabs1
  :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3d4353;
}

.model-tabs1
  :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-ink-bar {
  position: absolute;
  background: #0195ce;
  pointer-events: none;
}

.import-csv1 p {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #0195ce;
    gap: 7px;
}

.import-csv1 {
    margin-right: 15px;
    position: relative;
}

.import-csv1 p span img {
    cursor: pointer;
}

.import-csv1 p span img:hover ~ .hover-format1 {
    display: block;
}

.hover-format1 {
    position: absolute;
    background: #f7f8fa;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1),
      0px 8px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 9px 9px;
    width: 150px;
    right: 5px;
    z-index: 2;
    top: 24px;
    display: none;
}

.hover-format1 span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #2d303a;
}

.md-icons1 {
    display: flex;
    align-items: center;
    gap: 3px;
    font-family: "inter";
}

.filter-img1 {
    filter: invert(12%) sepia(63%) saturate(6234%) hue-rotate(173deg)
      brightness(100%) contrast(76%);
}

.topSearch-list-style1 {
    overflow-y: scroll;
    max-height: 526px;
}

.model-comp1 {
    background: #f7f8fa;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #e7e9f0;
    padding: 7px 18px;
}
  
.model-comp-name1 {
padding-left: 19px;
}

.model-comp-name1:hover {
background-color: #f7f8fa;
}
  
.model-comp1 p {
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #676a73;
}

.custom-checkbox-top1 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.custom-checkbox-top1:hover {
    background-color: #f7f8fa;
}

.custom-checkbox-top1.incident-md-active {
    background: #f7f8fa;
    border-width: 1px 1px;
    border-style: solid;
    border-color: #e7e9f0;
    padding: 10px 20px;
}
  
.custom-checkbox-top1.incident-md {
    background: #fff;
    border-width: 0px 0px 1px 0px;
    width: 100%;
    border-style: solid;
    border-color: #e7e9f0;
    padding: 10px 20px;
}

.custom-checkbox-top1.incident-md-active label {
    font-weight: 600;
    font-size: 13px !important;
    color: #3d4353;
}

.custom-checkbox-top1 input {
    accent-color: #0195ce;
}

.md-compy-tc1 {
    padding: 0 19px;
    height: 500px;
    overflow-y: scroll;
}

.md-flex-div1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.md-flex-div1:hover {
background-color: #f7f8fa;
}

.md-ads1 p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #676a73;
}

.border-right-md1 {
    border-right: 1px solid #e7e9f0;
}


.heightStyle1 {
    height: 540px;
    overflow: auto;
}