.custom-tabs .ant-tabs-tab {
  margin-right: 0px;
}

.vertical-list {
  display: flex;
  flex-direction: column;
}

.custom-tree-select .ant-tree-select-selector {
  box-shadow: none !important;
}

.custom-modal {
  margin-top: -45px;
}

.main-custom-drop {
  width: 100%;
  /* display: flex;
    justify-content: center;
    align-items: center; */
}

.custom-drop {
  position: relative;
  width: 94%;
  line-height: 16px;
  display: flex;
  padding: 8px;
  background-color: #fff;
  border: 1px solid #e7e9f0;
  border-radius: 6px;
  margin-top: 12px;
}

.custom-drop span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #80838b;
}

/* .custom-modal-drop-down .ant-modal-content {
    position: absolute;
    top: 252px;
    left: -321px;
    width: 71%;
} */

.custom-modal-drop-down
  span.ant-input-affix-wrapper.css-dev-only-do-not-override-yp8pcc {
  background: #ffffff;
  border: 1px solid #ced2e0;
  border-radius: 6px;
}

.custom-modal-drop-down input.ant-input.css-dev-only-do-not-override-yp8pcc {
  font-weight: 500;
  font-size: 11px;
  color: #000;
}

.custom-modal-drop-down .anticon svg {
  color: #9a9ca2;
}

.custom-modal-drop-down
  :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #0195ce;
}

.custom-modal-drop-down
  :where(.css-dev-only-do-not-override-yp8pcc).ant-tabs
  .ant-tabs-tab-btn {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #676a73;
}

.model-checkbox-tab {
  display: flex;
  align-items: center;
  gap: 7px;
  border: none !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #2d303a;
}

.title-check-box {
  background: #f7f8fa;
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0;
  border: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #2d303a;
}
